"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("./constants");
const version_1 = require("./version");
const deep_diff_1 = require("deep-diff");
class DeviceController {
    constructor(options = {}) {
        this.name = 'DeviceController';
        this.key = 'device-controller';
        this.version = version_1.VERSION;
        /** Minimum Poll Interval */
        this.minPollInterval = 30000;
        /** Maximum Poll Interval */
        this.maxPollInterval = 45000;
        if (options.minPollInterval) {
            this.minPollInterval = options.minPollInterval;
        }
        if (options.maxPollInterval) {
            this.maxPollInterval = options.maxPollInterval;
        }
    }
    /**
     * Initialize to Device
     */
    async init() {
        return {
            error: new Error(`init method is not implemented in ${this.name} device controller!`),
            status: 'error',
        };
    }
    /**
     * kill from Device
     */
    async kill() {
        return {
            error: new Error(`kill method is not implemented in ${this.name} device controller!`),
            status: 'error',
        };
    }
    /**
     * Checks if a device is connected in the given mode.
     */
    async isConnected() {
        return {
            error: new Error(`isConnected method is not implemented in ${this.name} device controller!`),
            status: 'error',
        };
    }
    /**
     * Fetches the device's state
     */
    async getState(_options) {
        return {
            error: new Error(`getState method is not implemented in ${this.name} device controller!`),
            status: 'error',
        };
    }
    /**
     * Tells the device to apply new state
     *
     * @param options
     */
    async setState(_options) {
        return {
            error: new Error(`setState method is not implemented in ${this.name} device controller!`),
            status: 'error',
        };
    }
    /**
     * Sends a command to a device
     *
     * @param _options
     */
    async sendCommand(_options) {
        return {
            error: new Error(`sendCommand method is not implemented in ${this.name} device controller!`),
            status: 'error',
        };
    }
    /**
     * Sends a configuration to a device
     *
     * @param options
     */
    async setConfigs(_options) {
        return {
            error: new Error(`setConfigs method is not implemented in ${this.name} device controller!`),
            status: 'error',
        };
    }
    /**
     * Subscribe to live updates of this device.
     */
    async subscribe(callback, options) {
        return this.startPolling(callback, options);
    }
    /**
     * Unsubscribe from live updates of this device
     */
    async unsubscribe() {
        return this.stopPolling();
    }
    /**
     * Start polling for change in device state. Rather than using a fixed time
     * interval, we randomize between 2 intervals, so that in an environment
     * when there are multiple devices, network doesn't get choked.
     */
    async startPolling(callback, _options) {
        // Generate a random poll interval
        // We do this because we don't want to put load on the network
        // when there a multiple devices, on a constant interval.
        // So, we randomize each call.
        const interval = Math.random() * (this.maxPollInterval - this.minPollInterval) + this.minPollInterval;
        // Start timeout, with this approach, first poll will start at the
        // end of the interval, not the beginning.
        this.pollTimeoutId = setTimeout(() => {
            // We create a copy of the current state, as it will be overwritten
            // by getState
            const currentState = Object.assign({}, this.state);
            // Fetch new state from device
            this.getState().then(response => {
                if (response.status === constants_1.RESPONSE_STATUS_SUCCESS) {
                    this.state = response.data;
                    // If subscription was cancelled in the meanwhile, stop here
                    if (!this.pollTimeoutId) {
                        return;
                    }
                    // Create a copy of the now current state
                    // const newState = { ...this.state };
                    // Take a diff of the 2 states
                    const changes = deep_diff_1.diff(currentState, response.data);
                    // If there are changes between the 2 objects, execute to callback
                    // function to notify the subscriber of a state update event
                    if (changes && changes.length > 0) {
                        callback(response.data);
                    }
                }
                // Continue poll
                this.startPolling(callback);
            });
        }, interval);
        return { status: 'success' };
    }
    /**
     * Stop polling for device state changes
     */
    async stopPolling() {
        if (this.pollTimeoutId) {
            clearTimeout(this.pollTimeoutId);
            this.pollTimeoutId = undefined;
        }
        return { status: 'success' };
    }
}
exports.DeviceController = DeviceController;
