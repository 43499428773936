"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.settings = {
    'bluebase.plugin.setting-app.pages': [
        {
            key: 'bluebase-settings-main-app-management',
            priority: 20,
            value: (pages) => [
                {
                    name: 'AccountSettingsPage',
                    path: 'account',
                    navigationOptions: {
                        drawerIcon: { type: 'icon', name: 'account' },
                        title: 'Account',
                    },
                    items: [
                        {
                            component: 'ProfileNameSettings',
                            description: 'User Profile Information',
                            name: 'profile-settings',
                            title: 'Profile Settings',
                        },
                        {
                            component: 'ProfileAvatarSetting',
                            description: 'Change the profile picture',
                            name: 'ProfileAvatarSetting',
                            title: 'Profile picture',
                        },
                        {
                            component: 'SocialAccountsList',
                            description: 'Link your account with other social accounts',
                            name: 'linked-accounts',
                            title: 'Social Accounts',
                        },
                        {
                            component: 'LogoutSetting',
                            description: 'Logout from here',
                            name: 'logout',
                            title: 'Logout',
                        },
                    ],
                },
                ...pages,
            ],
        },
    ],
};
