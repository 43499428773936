"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const routes_1 = require("../routes");
// Note: This implementation is for "React Router" library
exports.navigation = {
    'bluebase.navigator.root': [
        {
            key: 'bluebase-navigator-root-user-management',
            priority: 20,
            value: async (inputNavigator) => {
                return Object.assign(Object.assign({}, inputNavigator), { routes: [...routes_1.routes(), ...inputNavigator.routes] });
            },
        },
    ],
};
