"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Colors
const yellow200 = '#FFF59D';
const deepOrange600 = '#F4511E';
const lime300 = '#DCE775';
const lightGreen500 = '#8BC34A';
const teal700 = '#00796B';
const cyan900 = '#006064';
const colors = [deepOrange600, yellow200, lime300, lightGreen500, teal700, cyan900];
const blueGrey50 = '#ECEFF1';
const blueGrey300 = '#90A4AE';
const blueGrey700 = '#455A64';
const grey900 = '#212121';
function getVictoryTheme(theme) {
    // Typography
    const sansSerif = theme.typography.body1.fontFamily;
    const letterSpacing = 'normal';
    const fontSize = theme.typography.body2.fontSize;
    // Layout
    const padding = theme.spacing.unit;
    const baseProps = {
        height: 350,
        padding: 50,
        width: 350,
    };
    // * Labels
    const baseLabelStyles = {
        fontFamily: sansSerif,
        fontSize,
        letterSpacing,
        padding,
        fill: blueGrey700,
        stroke: 'transparent',
        strokeWidth: 0,
    };
    const centeredLabelStyles = Object.assign({ textAnchor: 'middle' }, baseLabelStyles);
    // Strokes
    const strokeDasharray = '10, 5';
    const strokeLinecap = 'round';
    const strokeLinejoin = 'round';
    return {
        area: Object.assign({ style: {
                data: {
                    fill: grey900,
                },
                labels: centeredLabelStyles,
            } }, baseProps),
        axis: Object.assign({ style: {
                axis: {
                    fill: 'transparent',
                    stroke: blueGrey300,
                    strokeWidth: 2,
                    strokeLinecap,
                    strokeLinejoin,
                },
                axisLabel: Object.assign(Object.assign({}, centeredLabelStyles), { padding, stroke: 'transparent' }),
                grid: {
                    fill: 'none',
                    stroke: 'none',
                    strokeDasharray,
                    strokeLinecap,
                    strokeLinejoin,
                    pointerEvents: 'painted',
                },
                ticks: {
                    fill: 'transparent',
                    size: 5,
                    stroke: blueGrey300,
                    strokeWidth: 1,
                    strokeLinecap,
                    strokeLinejoin,
                },
                tickLabels: Object.assign(Object.assign({}, baseLabelStyles), { fill: blueGrey700 }),
            } }, baseProps),
        bar: Object.assign({ style: {
                data: {
                    fill: blueGrey700,
                    padding,
                    strokeWidth: 0,
                },
                labels: baseLabelStyles,
            }, cornerRadius: theme.shape.borderRadius }, baseProps),
        boxplot: Object.assign({ style: {
                max: {
                    padding,
                    stroke: blueGrey700,
                    strokeWidth: 1,
                },
                maxLabels: baseLabelStyles,
                median: {
                    padding,
                    stroke: blueGrey700,
                    strokeWidth: 1,
                },
                medianLabels: baseLabelStyles,
                min: {
                    padding,
                    stroke: blueGrey700,
                    strokeWidth: 1,
                },
                minLabels: baseLabelStyles,
                q1: {
                    padding,
                    fill: blueGrey700,
                },
                q1Labels: baseLabelStyles,
                q3: {
                    padding,
                    fill: blueGrey700,
                },
                q3Labels: baseLabelStyles,
            }, boxWidth: 20 }, baseProps),
        candlestick: Object.assign({ style: {
                data: {
                    stroke: blueGrey700,
                },
                labels: centeredLabelStyles,
            }, candleColors: {
                positive: '#ffffff',
                negative: blueGrey700,
            } }, baseProps),
        chart: baseProps,
        errorbar: Object.assign({ borderWidth: 8, style: {
                data: {
                    fill: 'transparent',
                    opacity: 1,
                    stroke: blueGrey700,
                    strokeWidth: 2,
                },
                labels: centeredLabelStyles,
            } }, baseProps),
        group: Object.assign({ colorScale: colors }, baseProps),
        legend: {
            colorScale: colors,
            gutter: 10,
            orientation: 'vertical',
            titleOrientation: 'top',
            style: {
                data: {
                    type: 'circle',
                },
                labels: baseLabelStyles,
                title: Object.assign(Object.assign({}, baseLabelStyles), { padding: 5 }),
            },
        },
        line: Object.assign({ style: {
                data: {
                    fill: 'transparent',
                    opacity: 1,
                    stroke: blueGrey700,
                    strokeWidth: 2,
                },
                labels: centeredLabelStyles,
            } }, baseProps),
        pie: Object.assign({ colorScale: colors, style: {
                data: {
                    padding,
                    stroke: blueGrey50,
                    strokeWidth: 1,
                },
                labels: Object.assign(Object.assign({}, baseLabelStyles), { padding: 20 }),
            } }, baseProps),
        scatter: Object.assign({ style: {
                data: {
                    fill: blueGrey700,
                    opacity: 1,
                    stroke: 'transparent',
                    strokeWidth: 0,
                },
                labels: centeredLabelStyles,
            } }, baseProps),
        stack: Object.assign({ colorScale: colors }, baseProps),
        tooltip: {
            style: Object.assign(Object.assign({}, centeredLabelStyles), { padding: 5, pointerEvents: 'none' }),
            flyoutStyle: {
                fill: theme.palette.background.card,
                stroke: theme.palette.divider,
                strokeWidth: 1,
                pointerEvents: 'none',
            },
            cornerRadius: theme.shape.borderRadius,
            pointerLength: 10,
        },
        voronoi: Object.assign({ style: {
                data: {
                    fill: 'transparent',
                    stroke: 'transparent',
                    strokeWidth: 0,
                },
                labels: Object.assign(Object.assign({}, centeredLabelStyles), { padding: 5, pointerEvents: 'none' }),
                flyout: {
                    fill: theme.palette.background.card,
                    stroke: theme.palette.divider,
                    strokeWidth: 1,
                    pointerEvents: 'none',
                },
            } }, baseProps),
    };
}
exports.getVictoryTheme = getVictoryTheme;
