"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var victory_1 = require("victory");
exports.VictoryArea = victory_1.VictoryArea;
exports.VictoryBar = victory_1.VictoryBar;
exports.VictoryGroup = victory_1.VictoryGroup;
exports.VictoryLine = victory_1.VictoryLine;
exports.VictoryTooltip = victory_1.VictoryTooltip;
exports.VictoryVoronoiContainer = victory_1.VictoryVoronoiContainer;
exports.VictoryTheme = victory_1.VictoryTheme;
exports.VictoryChart = victory_1.VictoryChart;
exports.VictoryAxis = victory_1.VictoryAxis;
exports.VictoryLabel = victory_1.VictoryLabel;
