"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const react_1 = tslib_1.__importStar(require("react"));
const core_1 = require("@bluebase/core");
const values = [
    {
        description: 'Text Direction will automatically changed based on selected language.',
        label: 'Auto',
        value: 'auto',
    },
    {
        description: 'Text will be displayed from Left to Right',
        label: 'Left to Right',
        value: 'ltr',
    },
    {
        description: 'Text will be displayed from Right to Left',
        label: 'Right to Left',
        value: 'rtl',
    },
];
exports.TextDirectionSetting = () => {
    const { __, direction, rtl, changeDirection } = core_1.useIntl();
    const [visible, setVisible] = react_1.useState(false);
    const toggle = () => setVisible(!visible);
    const current = values.find(v => v.value === direction) || values[0];
    const select = (value) => () => {
        changeDirection(value);
        toggle();
    };
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(components_1.List.Item, { left: react_1.default.createElement(components_1.List.Icon, { name: !!rtl ? 'format-textdirection-r-to-l' : 'format-textdirection-l-to-r' }), title: __('Text Direction'), description: __(current.description), onPress: toggle }),
        react_1.default.createElement(components_1.Dialog, { visible: visible, onDismiss: toggle },
            react_1.default.createElement(components_1.List.Subheader, null, __('Text Direction')),
            values.map(item => {
                return (react_1.default.createElement(components_1.View, { testID: "test-listItem", key: item.value },
                    react_1.default.createElement(components_1.Divider, null),
                    react_1.default.createElement(components_1.List.Item, { title: __(item.label), description: __(item.description), onPress: select(item.value), selected: direction === item.value })));
            }))));
};
