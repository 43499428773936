"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const react_1 = tslib_1.__importStar(require("react"));
const core_1 = require("@bluebase/core");
const PrivacyPolicy = core_1.getComponent('PrivacyPolicy', 'EmptyState');
exports.PrivacyPolicySetting = () => {
    const { __ } = core_1.useIntl();
    const { theme } = core_1.useTheme();
    const [visible, setVisible] = react_1.useState(false);
    const toggle = () => setVisible(!visible);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(components_1.List.Item, { left: react_1.default.createElement(components_1.List.Icon, { name: "shield-check" }), title: __('Privacy Policy'), onPress: toggle }),
        react_1.default.createElement(components_1.Dialog, { visible: visible, onDismiss: toggle },
            react_1.default.createElement(components_1.View, { testID: "test-listItem" },
                react_1.default.createElement(components_1.List.Subheader, null, __('Privacy Policy')),
                react_1.default.createElement(components_1.Divider, null),
                react_1.default.createElement(components_1.View, { style: { padding: theme.spacing.unit * 2 } },
                    react_1.default.createElement(PrivacyPolicy, null))))));
};
