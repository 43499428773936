"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntegerPercent = {
    key: 'IntegerPercent',
    dataType: 'NUMBER',
    /**
     * Transforms raw state value into percentage
     *
     * Example: 26 => 26%
     */
    template: '{{ value }}%',
    precision: 0,
};
