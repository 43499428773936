"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const components_1 = require("./components");
const version_1 = require("./version");
const core_1 = require("@bluebase/core");
exports.default = core_1.createPlugin({
    description: 'A plugin that replaces HomeScreen to show Apps just like any mobile OS',
    key: 'plugin-launcher',
    name: 'BlueBase App Launcher',
    version: version_1.VERSION,
    components: {
        HomeScreen: components_1.LauncherScreen,
        LauncherDefaultIcon: components_1.DefaultIcon,
        LauncherScreen: components_1.LauncherScreen,
    },
});
