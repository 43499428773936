"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * If an input object is an ES module, returns object.default, otherwise returns the object as is.
 * @param object Input object
 */
function getDefiniteModule(object) {
    return isEsModule(object) ? object.default : object;
}
exports.getDefiniteModule = getDefiniteModule;
/**
 * Checks if an object is an ES module.
 * @param object Input object
 */
function isEsModule(object) {
    return object && object.default !== undefined;
}
exports.isEsModule = isEsModule;
