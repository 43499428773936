"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const BaseNavigator_1 = require("../BaseNavigator");
const core_1 = require("@bluebase/core");
const DrawerView = core_1.getComponent('DrawerView');
/**
 */
class DrawerNavigator extends BaseNavigator_1.BaseNavigator {
}
DrawerNavigator.defaultProps = {
    RouteView: DrawerView
};
exports.DrawerNavigator = DrawerNavigator;
