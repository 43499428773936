Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/UrlPreview.js';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactNative = require('react-native');

var _styles = require('../styles');

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var UrlPreview = function (_React$Component) {
  _inherits(UrlPreview, _React$Component);

  function UrlPreview() {
    _classCallCheck(this, UrlPreview);

    return _possibleConstructorReturn(this, (UrlPreview.__proto__ || Object.getPrototypeOf(UrlPreview)).apply(this, arguments));
  }

  _createClass(UrlPreview, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var styles = (0, _styles.buildStylesheet)('urlPreview', this.props.styles);

      return _react2.default.createElement(
        _reactNative.View,
        { style: styles.wrapper, __source: {
            fileName: _jsxFileName,
            lineNumber: 23
          }
        },
        _react2.default.createElement(
          _reactNative.View,
          { style: [styles.leftColumn], __source: {
              fileName: _jsxFileName,
              lineNumber: 24
            }
          },
          this.props.og && this.props.og.images ? _react2.default.createElement(_reactNative.Image, {
            source: this.props.og.images[0].image ? {
              uri: this.props.og.images[0].image
            } : require('../images/placeholder.png'),
            style: [styles.image],
            __source: {
              fileName: _jsxFileName,
              lineNumber: 26
            }
          }) : null
        ),
        _react2.default.createElement(
          _reactNative.View,
          { style: [styles.rightColumn], __source: {
              fileName: _jsxFileName,
              lineNumber: 38
            }
          },
          _react2.default.createElement(
            _reactNative.Text,
            { style: styles.textStyle, __source: {
                fileName: _jsxFileName,
                lineNumber: 39
              }
            },
            _lodash2.default.truncate(this.props.og.title, { length: 75 })
          )
        ),
        _react2.default.createElement(
          _reactNative.TouchableOpacity,
          {
            onPress: function onPress() {
              return _this2.props.onPressDismiss(_this2.props.og.url);
            },
            __source: {
              fileName: _jsxFileName,
              lineNumber: 43
            }
          },
          _react2.default.createElement(_reactNative.Image, {
            source: require('../images/icons/close-black.png'),
            style: [styles.closeButton],
            __source: {
              fileName: _jsxFileName,
              lineNumber: 46
            }
          })
        )
      );
    }
  }]);

  return UrlPreview;
}(_react2.default.Component);

exports.default = UrlPreview;