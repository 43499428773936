"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function getMonday(d) {
    d = new Date(d);
    const day = d.getDay(), diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
}
exports.DateRangePresets = {
    today: {
        label: 'Today',
        fn: () => {
            const to = new Date();
            const from = new Date(to.getFullYear(), to.getMonth(), to.getDate());
            return { to, from };
        },
    },
    yesterday: {
        label: 'Yesterday',
        fn: () => {
            const to = new Date();
            to.setDate(to.getDate() - 1);
            const from = new Date(to.getFullYear(), to.getMonth(), to.getDate());
            return { to, from };
        },
    },
    thisWeek: {
        label: 'This Week',
        fn: () => {
            const to = new Date();
            const from = getMonday(to);
            return { to, from };
        },
    },
    thisMonth: {
        label: 'This Month',
        fn: () => {
            const to = new Date();
            const from = new Date(to.getFullYear(), to.getMonth());
            return { to, from };
        },
    },
    thisYear: {
        label: 'This Year',
        fn: () => {
            const to = new Date();
            const from = new Date(to.getFullYear(), 0, 1, 0, 0, 0);
            return { to, from };
        },
    },
    lastSevenDays: {
        label: 'Last 7 Days',
        fn: () => {
            const to = new Date();
            const from = new Date(to.getFullYear(), to.getMonth(), to.getDate());
            from.setDate(to.getDate() - 7);
            return { to, from };
        },
    },
    lastThirtyDays: {
        label: 'Last 30 Days',
        fn: () => {
            const to = new Date();
            const from = new Date(to.getFullYear(), to.getMonth(), to.getDate());
            from.setDate(to.getDate() - 30);
            return { to, from };
        },
    },
    lastWeek: {
        label: 'Last Week',
        fn: () => {
            const to = getMonday(new Date());
            to.setDate(to.getDate() - 1);
            const from = getMonday(new Date());
            from.setDate(from.getDate() - 7);
            return { to, from };
        },
    },
    lastMonth: {
        label: 'Last Month',
        fn: () => {
            const today = new Date();
            // firstDayInCurrentMonth
            const to = new Date(today.getFullYear(), today.getMonth(), 1);
            to.setDate(to.getDate() - 1);
            // firstDayInPreviousMonth
            const from = new Date(today.getFullYear(), today.getMonth() - 1, 1);
            return { to, from };
        },
    },
    lastYear: {
        label: 'Last Year',
        fn: () => {
            const today = new Date();
            const to = new Date(today.getFullYear(), 0, 1);
            to.setDate(to.getDate() - 1);
            const from = new Date(today.getFullYear() - 1, 0);
            return { to, from };
        },
    },
};
