Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setAndroidTranslucentStatusBar = exports.androidTranslucentStatusBar = exports.registerNativeHandlers = exports.pickImage = undefined;

var _reactNative = require('react-native');

var _reactNativeSafeAreaView = require('react-native-safe-area-view');

var _reactNativeSafeAreaView2 = _interopRequireDefault(_reactNativeSafeAreaView);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var pickImage = exports.pickImage = function pickImage() {
  throw Error('Native handler was not registered, you should import expo-activity-feed or react-native-activity-feed');
};

var registerNativeHandlers = exports.registerNativeHandlers = function registerNativeHandlers(handlers) {
  if (handlers.pickImage) {
    exports.pickImage = pickImage = handlers.pickImage;
  }
};

var androidTranslucentStatusBar = exports.androidTranslucentStatusBar = false;

var setAndroidTranslucentStatusBar = exports.setAndroidTranslucentStatusBar = function setAndroidTranslucentStatusBar() {
  var translucent = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

  if (_reactNative.Platform.OS === 'android') {
    exports.androidTranslucentStatusBar = androidTranslucentStatusBar = translucent;
    if (translucent) {
      _reactNativeSafeAreaView2.default.setStatusBarHeight(24);
    } else {
      _reactNativeSafeAreaView2.default.setStatusBarHeight(0);
    }
  }
};