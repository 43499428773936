"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const react_1 = tslib_1.__importStar(require("react"));
const core_1 = require("@bluebase/core");
exports.LanguageSetting = () => {
    const { __, locale, changeLocale } = core_1.useIntl();
    const [localeOptions] = core_1.useConfig('locale.options');
    const [visible, setVisible] = react_1.useState(false);
    const toggle = () => setVisible(!visible);
    const select = (value) => () => {
        changeLocale(value);
        toggle();
    };
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(components_1.List.Item, { left: react_1.default.createElement(components_1.List.Icon, { name: "translate" }), title: __('Language'), description: localeOptions[locale], onPress: toggle }),
        react_1.default.createElement(components_1.Dialog, { visible: visible, onDismiss: toggle },
            react_1.default.createElement(components_1.List.Subheader, null, __('Language')),
            Object.keys(localeOptions).map(localeKey => (react_1.default.createElement(components_1.View, { testID: "test-listItem", key: localeKey },
                react_1.default.createElement(components_1.Divider, null),
                react_1.default.createElement(components_1.List.Item, { title: localeOptions[localeKey], onPress: select(localeKey), key: localeKey, selected: locale === localeKey })))))));
};
