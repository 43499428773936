Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _jsxFileName = 'src/components/ReactionToggleIcon.js';
exports.default = ReactionToggleIcon;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _ReactionIcon = require('./ReactionIcon');

var _ReactionIcon2 = _interopRequireDefault(_ReactionIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function ReactionToggleIcon(_ref) {
  var activeIcon = _ref.activeIcon,
      inactiveIcon = _ref.inactiveIcon,
      own_reactions = _ref.own_reactions,
      _ref$kind = _ref.kind,
      kind = _ref$kind === undefined ? 'like' : _ref$kind,
      props = _objectWithoutProperties(_ref, ['activeIcon', 'inactiveIcon', 'own_reactions', 'kind']);

  var icon = inactiveIcon;
  if (own_reactions && own_reactions[kind] && own_reactions[kind].length) {
    icon = activeIcon;
  }
  return _react2.default.createElement(_ReactionIcon2.default, _extends({ icon: icon, kind: kind }, props, {
    __source: {
      fileName: _jsxFileName,
      lineNumber: 58
    }
  }));
}