"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@bluebase/core");
exports.MenuItemDefaultProps = {};
/**
 */
// tslint:disable: jsdoc-format
/**
 * # 📋 MenuItem
 *
 * A component to show a single list item inside a Menu.
 */
exports.MenuItem = core_1.getComponent('MenuItem');
exports.MenuItem.defaultProps = exports.MenuItemDefaultProps;
