"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
require("./typings");
const core_1 = require("@bluebase/core");
const DeviceControllerRegistry_1 = require("./registries/DeviceControllerRegistry");
const DeviceRegistry_1 = require("./registries/DeviceRegistry");
const version_1 = require("./version");
const components_1 = require("./components");
const controls_items_1 = require("./controls-items");
tslib_1.__exportStar(require("./exports"), exports);
exports.default = core_1.createPlugin({
    description: 'Makes it possible to install & use Device Controllers in BlueBase framework.',
    key: 'client-plugin-device-controllers',
    name: 'BlueBase Device Controllers',
    version: version_1.VERSION,
    components: Object.assign(Object.assign({}, components_1.components), controls_items_1.controlItems),
    filters: {
        'bluebase.boot.end': {
            priority: 0,
            value: async (bootOptions, _ctx, BB) => {
                BB.Devices = new DeviceRegistry_1.DeviceRegistry(BB);
                BB.DeviceControllers = new DeviceControllerRegistry_1.DeviceControllerRegistry(BB);
                // Register controllers from boot options
                const controllers = bootOptions.deviceControllers;
                await BB.DeviceControllers.registerCollection(controllers);
                // Register controllers from plugins
                const enabledPlugins = BB.Plugins.filter((_value, key) => BB.Plugins.isEnabled(key));
                for (const pluginItem of Object.values(enabledPlugins)) {
                    const plugin = await BB.Plugins.resolve(pluginItem.key);
                    await BB.DeviceControllers.registerCollection(plugin.deviceControllers);
                }
                await BB.Filters.run('mevris.device-controllers.init', {});
                return bootOptions;
            },
        },
    },
});
