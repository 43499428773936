"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const helpers_1 = require("../../helpers");
const useDevice_1 = require("../useDevice");
const useMevrisUISchema_1 = require("../useMevrisUISchema");
function useMevrisUI(id, ui, options) {
    const _a = useDevice_1.useDevice(), { device, state, error: errorDevice, loading: loadingDevice } = _a, rest = tslib_1.__rest(_a, ["device", "state", "error", "loading"]);
    const { uiSchema, error: errorQuery, loading: loadingQuery } = useMevrisUISchema_1.useMevrisUISchema(id, ui, options);
    const error = errorDevice || errorQuery;
    const loading = loadingDevice || loadingQuery;
    const items = helpers_1.mapUISchemaToJsonComponentNodes(uiSchema, state, options);
    return Object.assign({ uiSchema, items, device, state, error, loading }, rest);
}
exports.useMevrisUI = useMevrisUI;
