"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Converts a string to pascal case
 * @param str
 */
function toPascalCase(str) {
    return `${str}`
        .replace(new RegExp(/[-_]+/, 'g'), ' ')
        .replace(new RegExp(/[^\w\s]/, 'g'), '')
        .replace(new RegExp(/\s+(.)(\w+)/, 'g'), (_$1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`)
        .replace(new RegExp(/\s/, 'g'), '')
        .replace(new RegExp(/\w/), s => s.toUpperCase());
}
exports.toPascalCase = toPascalCase;
