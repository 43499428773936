"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.components = {
    ChartCard: Promise.resolve().then(() => __importStar(require('./ChartCard'))),
    InlineChart: Promise.resolve().then(() => __importStar(require('./InlineChart'))),
    QuickCharts: Promise.resolve().then(() => __importStar(require('./QuickCharts'))),
    ReportChart: Promise.resolve().then(() => __importStar(require('./ReportChart'))),
    ReportList: Promise.resolve().then(() => __importStar(require('./ReportList'))),
    ReportTable: Promise.resolve().then(() => __importStar(require('./ReportTable'))),
    ReportToolbar: Promise.resolve().then(() => __importStar(require('./ReportToolbar'))),
    ReportView: Promise.resolve().then(() => __importStar(require('./ReportView'))),
    StateChartCard: Promise.resolve().then(() => __importStar(require('./StateChartCard'))),
    ThingProfileQuickCharts: Promise.resolve().then(() => __importStar(require('./ThingProfileQuickCharts'))),
};
