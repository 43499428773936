"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@bluebase/core");
const DefaultInputs = ['FormTextInput', 'TextInput', 'Noop'];
const DefaultButtons = ['Button', 'Noop'];
const FieldResolutionMap = {
    checkbox: ['FormCheckboxInput', 'Noop'],
    color: ['FormColorInput', ...DefaultInputs],
    component: ['JsonLayout', 'Noop'],
    date: ['FormDateInput', ...DefaultInputs],
    email: ['FormEmailInput', ...DefaultInputs],
    inline: ['InlineFields', 'Noop'],
    'inline-fields': ['InlineFields', 'Noop'],
    number: ['FormNumberInput', ...DefaultInputs],
    password: ['FormPasswordInput', ...DefaultInputs],
    picker: ['FormPickerInput', 'Picker', 'Noop'],
    'radio-group': ['FormRadioGroupInput', 'Noop'],
    range: ['FormRangeInput', ...DefaultInputs],
    reset: ['FormResetButton', ...DefaultButtons],
    search: ['FormSearchInput', ...DefaultInputs],
    status: ['FormStatus'],
    submit: ['FormSubmitButton', ...DefaultButtons],
    switch: ['FormSwitchInput', 'FormCheckboxInput', 'Noop'],
    tel: ['FormTelInput', ...DefaultInputs],
    time: ['FormTimeInput', ...DefaultInputs],
    url: ['FormUrlInput', ...DefaultInputs],
};
exports.getFormField = (type) => type && FieldResolutionMap[type]
    ? core_1.getComponent(...FieldResolutionMap[type])
    : core_1.getComponent(...DefaultInputs);
