"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// tslint:disable-next-line: variable-name
exports.Number = {
    key: 'Number',
    dataType: 'NUMBER',
    /**
     * Returns value as it is
     *
     * Example: 50 => 50
     */
    template: '{{ value }}',
};
