"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const components_1 = require("@bluebase/components");
exports.CircularSlider = components_1.getComponentWithFallback({
    moduleName: '@blueeast/client-plugin-ui',
    name: 'CircularSlider',
})('CircularSlider');
exports.JsonLayout = components_1.getComponentWithFallback({
    moduleName: '@bluebase/plugin-json-schema-components',
    name: 'JsonLayout',
})('JsonLayout');
exports.JsonLayout.displayName = 'JsonLayout';
exports.Stepper = components_1.getComponentWithFallback({
    moduleName: '@blueeast/client-plugin-ui',
    name: 'Stepper',
})('Stepper');
exports.Stepper.displayName = 'Stepper';
exports.Widget = components_1.getComponentWithFallback({
    moduleName: '@blueeast/client-plugin-ui',
    name: 'Widget',
})('Widget', 'View');
exports.Widget.displayName = 'Widget';
