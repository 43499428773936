"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const rn_placeholder_1 = require("rn-placeholder");
const components_1 = require("@bluebase/components");
const react_1 = tslib_1.__importStar(require("react"));
const react_native_1 = require("react-native");
const core_1 = require("@bluebase/core");
const client_plugin_ui_1 = require("@blueeast/client-plugin-ui");
const InlineChart = core_1.getComponent('InlineChart');
function generateSampleData(threshold = 30) {
    const data = [];
    for (let x = 0; x < threshold; x++) {
        const min = Math.ceil(1);
        const max = Math.floor(10);
        const y = Math.floor(Math.random() * (max - min + 1)) + min;
        data.push({ x, y });
    }
    return data;
}
exports.ChartCard = (props) => {
    const { color, overline, loading, title, threshold, data: inputData } = props, rest = tslib_1.__rest(props, ["color", "overline", "loading", "title", "threshold", "data"]);
    const { theme } = core_1.useTheme();
    const data = core_1.getDefiniteArray(inputData);
    const dataTheme = core_1.buildTheme('dark')();
    const styles = core_1.useStyles('ChartCard', props, {
        root: Object.assign({ backgroundColor: loading ? theme.palette.background.card : props.color || '#CB4FD8', borderColor: theme.palette.divider, borderRadius: theme.shape.borderRadius, flexGrow: 1 }, theme.elevation(1)),
        content: {
            flexDirection: 'column',
            padding: theme.spacing.unit * 2,
        },
        overline: {
            color: dataTheme.palette.text.primary,
            display: 'flex',
        },
        title: {
            color: dataTheme.palette.text.primary,
            display: 'flex',
        },
        overlinePlaceholder: {
            backgroundColor: theme.palette.divider,
            borderRadius: theme.shape.borderRadius,
            height: theme.typography.overline.fontSize,
        },
        titlePlaceholder: {
            backgroundColor: theme.palette.divider,
            borderRadius: theme.shape.borderRadius,
            height: theme.typography.h4.fontSize,
        },
        chartBorderColor: loading
            ? props.type === 'line'
                ? theme.palette.divider
                : 'transparent'
            : 'rgba(255,255,255,1)',
        chartFillColor: loading ? theme.palette.divider : 'rgba(255,255,255,.3)',
    });
    // Calculate chart dimensions based on parent layout
    const [dimensions, setDimensions] = react_1.useState();
    const { layout: layoutRoot, onLayout: onLayoutRoot } = client_plugin_ui_1.useComponentLayout();
    const { layout: layoutText, onLayout: onLayoutText } = client_plugin_ui_1.useComponentLayout();
    react_1.useEffect(() => {
        if (!!dimensions || layoutRoot.width === 0 || layoutText.width === 0) {
            return;
        }
        setDimensions({
            height: layoutRoot.height - layoutText.height,
            width: layoutRoot.width,
        });
    });
    // End dimension calculation
    return (react_1.default.createElement(react_native_1.View, { style: styles.root, onLayout: onLayoutRoot },
        react_1.default.createElement(react_native_1.View, { style: styles.content, onLayout: onLayoutText }, !loading ? (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(components_1.Overline, { style: styles.overline }, overline),
            react_1.default.createElement(components_1.H4, { style: styles.title }, title))) : (react_1.default.createElement(rn_placeholder_1.Placeholder, { Animation: rn_placeholder_1.Fade },
            react_1.default.createElement(react_native_1.View, { style: { paddingVertical: 1 } },
                react_1.default.createElement(rn_placeholder_1.PlaceholderLine, { noMargin: true, width: 40, style: styles.overlinePlaceholder })),
            react_1.default.createElement(react_native_1.View, { style: { paddingVertical: 2.5 } },
                react_1.default.createElement(rn_placeholder_1.PlaceholderLine, { noMargin: true, width: 30, style: styles.titlePlaceholder }))))),
        !!dimensions ? (react_1.default.createElement(InlineChart, Object.assign({ borderColor: styles.chartBorderColor, fillColor: styles.chartFillColor }, rest, { victoryGroupProps: Object.assign(Object.assign({}, dimensions), props.victoryGroupProps), data: loading ? generateSampleData(threshold) : data }))) : null));
};
exports.ChartCard.displayName = 'ChartCard';
