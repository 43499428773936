"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@bluebase/core");
exports.Switch = core_1.getComponent('Switch');
/**
 * Default props for Switch component
 */
exports.SwitchDefaultProps = {
    checked: false,
    color: 'secondary',
    disabled: false,
    labelPlacement: 'start',
};
