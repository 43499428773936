"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const colorManipulator_1 = require("@material-ui/core/styles/colorManipulator");
exports.styles = (props, muiTheme, theme) => {
    const { color, disabled } = props;
    // debugger;
    if (!color || (color === 'default' && !disabled) || disabled) {
        return;
    }
    const colors = getButtonColors(props, muiTheme, theme);
    return {
        contained: {},
        outlined: {},
        // text: {},
        root: {
            '&$contained': {
                backgroundColor: colors.main,
                color: colors.text,
                '&:hover': {
                    backgroundColor: colors.hover,
                },
            },
            /* Styles applied to the root element if `variant="outlined"` and `color="primary"`. */
            '&$outlined': {
                '&:hover': {
                    '@media (hover: none)': {
                        backgroundColor: 'transparent',
                    },
                    backgroundColor: colorManipulator_1.fade(colors.text, theme.palette.action.hoverOpacity),
                    border: `1px solid ${colors.text}`,
                },
                border: `1px solid ${colorManipulator_1.fade(colors.text, 0.5)}`,
                color: colors.text,
            },
            '&$text': {
                '&:hover': {
                    '@media (hover: none)': {
                        backgroundColor: 'transparent',
                    },
                    backgroundColor: colorManipulator_1.fade(colors.text, theme.palette.action.hoverOpacity),
                },
                color: colors.text,
            },
        },
        /* Styles applied to the root element if `variant="text"` */
        text: {},
    };
};
function getButtonColors({ color, disabled, variant }, muiTheme, theme) {
    const colors = {
    // hover: theme.palette.primary.main,
    // main: theme.palette.primary.main,
    // text: theme.palette.text.primary,
    };
    if (disabled) {
        colors.hover = theme.palette.action.disabledBackground;
        colors.main = theme.palette.action.disabledBackground;
        colors.text = theme.palette.action.disabled;
        return colors;
    }
    if (!color || (color === 'default' && !disabled)) {
        return colors;
    }
    // If color is NOT primary, secondary or default then create custom styles
    if (color === 'primary' ||
        color === 'secondary' ||
        color === 'success' ||
        color === 'error' ||
        color === 'warning') {
        if (variant === 'contained') {
            colors.hover = theme.palette[color].dark;
            colors.main = theme.palette[color].main;
            colors.text = theme.palette[color].contrastText;
        }
        else {
            colors.hover = undefined;
            colors.main = undefined;
            colors.text = theme.palette[color].main;
        }
    }
    else {
        colors.hover = color;
        colors.main = color;
        colors.text = muiTheme.palette.getContrastText(color);
    }
    return colors;
}
exports.getButtonColors = getButtonColors;
