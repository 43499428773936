"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_router_dom_1 = require("react-router-dom");
exports.Router = react_router_dom_1.BrowserRouter;
exports.MemoryRouter = react_router_dom_1.MemoryRouter;
exports.StaticRouter = react_router_dom_1.StaticRouter;
exports.Switch = react_router_dom_1.Switch;
exports.Stack = react_router_dom_1.Switch;
exports.Route = react_router_dom_1.Route;
exports.Link = react_router_dom_1.Link;
exports.withRouter = react_router_dom_1.withRouter;
// export { default as Stack } from 'react-router-native-stack';
