"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const components_1 = require("@bluebase/components");
exports.ListCarousel = components_1.getComponentWithFallback({
    moduleName: '@blueeast/client-plugin-ui',
    name: 'ListCarousel',
})('ListCarousel');
exports.ProgressiveImage = components_1.getComponentWithFallback({
    moduleName: '@blueeast/client-plugin-ui',
    name: 'ProgressiveImage',
})('ProgressiveImage');
exports.RelativeTime = components_1.getComponentWithFallback({
    moduleName: '@blueeast/client-plugin-ui',
    name: 'RelativeTime',
})('RelativeTime');
exports.StatusIcon = components_1.getComponentWithFallback({
    moduleName: '@blueeast/client-plugin-ui',
    name: 'StatusIcon',
})('StatusIcon');
exports.SkeletonCard = components_1.getComponentWithFallback({
    moduleName: '@blueeast/client-plugin-ui',
    name: 'SkeletonCard',
})('SkeletonCard');
exports.SkeletonListItem = components_1.getComponentWithFallback({
    moduleName: '@blueeast/client-plugin-ui',
    name: 'SkeletonListItem',
})('SkeletonListItem');
