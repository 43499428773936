"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@bluebase/core");
exports.TablePaginationDefaultProps = {};
// tslint:disable: jsdoc-format
/**
 * # 🏓 TablePagination
 *
 * Data tables allow displaying sets of data.
 *
 */
exports.TablePagination = core_1.getComponent('TablePagination');
exports.TablePagination.defaultProps = exports.TablePaginationDefaultProps;
