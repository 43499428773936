"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@bluebase/core");
exports.DialogContentDefaultProps = {};
// tslint:disable: jsdoc-format
/**
 * # 🔲 DialogContent
 *
 * A component to show content in a Dialog.
 *
 * ## Usage
 ```jsx
import * as React from 'react';
import { Body1, Dialog } from '@bluebase/components';

export default class MyComponent extends React.Component {
  state = {
    visible: false,
  };

  _hideDialog = () => this.setState({ visible: false });

  render() {
    return (
      <Dialog
        visible={this.state.visible}
        onDismiss={this._hideDialog}>
        <Dialog.Content>
          <Body1>This is simple dialog</Body1>
        </Dialog.Content>
      </Dialog>
    );
  }
}

export default MyComponent;
```
 */
exports.DialogContent = core_1.getComponent('DialogContent');
exports.DialogContent.defaultProps = exports.DialogContentDefaultProps;
