"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const react_1 = tslib_1.__importStar(require("react"));
const Context_1 = require("../../Context");
const getComponent_1 = require("../../getComponent");
const utils_1 = require("../../utils");
const hooks_1 = require("../../hooks");
// import { useTheme } from '../../hooks';
// tslint:disable: jsdoc-format
/**
 * # 🖼 BlueBaseImage
 *
 * A drop in replacement for React Native's Image component.
 * Adds ability to display image stored in BlueBase Asset Registry.
 *
 * ## Usage
 * ```jsx
<BlueBaseImage resolve="Logo" />
<BlueBaseImage resolve={['LogoSquare', 'Logo']} />
```
 */
//
exports.BlueBaseImage = (props) => {
    const { resolve, source: _source } = props, rest = tslib_1.__rest(props, ["resolve", "source"]);
    const BB = react_1.useContext(Context_1.BlueBaseContext);
    const Theme = hooks_1.useTheme();
    const source = exports.resolveImageSource(props, BB, Theme);
    if (!source) {
        return null;
    }
    return react_1.default.createElement(getComponent_1.Image, Object.assign({}, rest, { source }));
};
const getAssetVersions = (source, Theme) => {
    if (typeof source !== 'string') {
        return source;
    }
    const { theme } = Theme;
    // desktop or mobile
    const screen = utils_1.isMobile() ? 'mobile' : 'desktop';
    // dark or light mode
    const mode = theme.mode;
    return [`${source}_${screen}_${mode}`, `${source}_${screen}`, `${source}_${mode}`, source];
};
exports.resolveImageSource = (props, BB, Theme) => {
    const { resolve } = props;
    const _source = getAssetVersions(props.source, Theme);
    let Asset;
    if (typeof _source === 'string') {
        Asset = BB.Assets.resolve(_source);
    }
    else if (Array.isArray(_source)) {
        const keys = _source.filter(s => typeof s === 'string');
        if (keys.length > 0) {
            Asset = BB.Assets.resolve(...keys);
        }
    }
    else if (resolve) {
        BB.Logger.warn('resolve prop in BlueBaseImage is deprecated. Use "source" instead');
        Asset = Array.isArray(resolve) ? BB.Assets.resolve(...resolve) : BB.Assets.get(resolve);
    }
    if ((typeof _source === 'string' || Array.isArray(_source) || resolve) && !Asset) {
        return null;
    }
    const source = Asset ? Asset.value : _source;
    return source;
};
