"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@bluebase/core");
exports.BadgeDefaultProps = {
    variant: 'standard',
    visible: true,
};
// tslint:disable: jsdoc-format
/**
 * # 🔴 Badge
 *
 * Badges are small status descriptors for UI elements. A badge consists of a
 * small circle, typically containing a number or other short set of characters,
 * that appears in proximity to another object.
 *
 * ## Usage
 ```jsx
import * as React from 'react';
import { Badge } from '@bluebase/components';

const MyComponent = () => (
  <Badge>3</Badge>
);

export default MyComponent;
```
 */
exports.Badge = core_1.getComponent('Badge');
exports.Badge.defaultProps = exports.BadgeDefaultProps;
