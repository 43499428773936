"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.components = {
    ApolloError: Promise.resolve().then(() => __importStar(require('./ApolloError'))),
    AvatarSetting: Promise.resolve().then(() => __importStar(require('./AvatarSetting'))),
    CircularSlider: Promise.resolve().then(() => __importStar(require('./CircularSlider'))),
    Collapsible: Promise.resolve().then(() => __importStar(require('./Collapsible'))),
    DangerSettingAction: Promise.resolve().then(() => __importStar(require('./DangerSettingAction'))),
    ForbiddenError: Promise.resolve().then(() => __importStar(require('./ForbiddenError'))),
    GraphqlList: Promise.resolve().then(() => __importStar(require('./GraphqlList'))),
    HueCircularSlider: Promise.resolve().then(() => __importStar(require('./HueCircularSlider'))),
    ImagePicker: Promise.resolve().then(() => __importStar(require('./ImagePicker'))),
    ListCarousel: Promise.resolve().then(() => __importStar(require('./ListCarousel'))),
    MetaListItem: Promise.resolve().then(() => __importStar(require('./MetaListItem'))),
    NetworkError: Promise.resolve().then(() => __importStar(require('./NetworkError'))),
    NotFoundError: Promise.resolve().then(() => __importStar(require('./NotFoundError'))),
    ProgressiveImage: Promise.resolve().then(() => __importStar(require('./ProgressiveImage'))),
    ProgressiveImageBackground: Promise.resolve().then(() => __importStar(require('./ProgressiveImageBackground'))),
    RelativeTime: Promise.resolve().then(() => __importStar(require('./RelativeTime'))),
    ResponsiveListSizing: Promise.resolve().then(() => __importStar(require('./ResponsiveListSizing'))),
    SidebarLayout: Promise.resolve().then(() => __importStar(require('./SidebarLayout'))),
    SkeletonCard: Promise.resolve().then(() => __importStar(require('./SkeletonCard'))),
    SkeletonListItem: Promise.resolve().then(() => __importStar(require('./SkeletonListItem'))),
    Slider: Promise.resolve().then(() => __importStar(require('./Slider'))),
    StatusIcon: Promise.resolve().then(() => __importStar(require('./StatusIcon'))),
    Stepper: Promise.resolve().then(() => __importStar(require('./Stepper'))),
    Widget: Promise.resolve().then(() => __importStar(require('./Widget'))),
    Wizard: Promise.resolve().then(() => __importStar(require('./Wizard'))),
    WizardFooter: Promise.resolve().then(() => __importStar(require('./Wizard/WizardFooter'))),
};
