"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@bluebase/core");
exports.TouchableItemDefaultProps = {
    borderless: false,
};
/**
 * # 👆 TouchableItem
 * TouchableItem renders a touchable that looks native on both iOS and Android.
 *
 * It provides an abstraction on top of TouchableNativeFeedback and
 * TouchableOpacity.
 *
 * On iOS you can pass the props of TouchableOpacity, on Android pass the props
 * of TouchableNativeFeedback.
 */
exports.TouchableItem = core_1.getComponent('TouchableItem');
exports.TouchableItem.defaultProps = exports.TouchableItemDefaultProps;
