"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@bluebase/core");
exports.TableHeadDefaultProps = {};
// tslint:disable: jsdoc-format
/**
 * # 🏓 TableHead
 *
 * Data tables allow displaying sets of data.
 *
 */
exports.TableHead = core_1.getComponent('TableHead');
exports.TableHead.defaultProps = exports.TableHeadDefaultProps;
