"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Converts an array of items to grouped data required by react-native's
 * SectionList component
 * @param items
 */
function convertArrayToSectionListData(items) {
    const sections = Object.values(items.reduce((result, item) => {
        const group = item.group || 'Other';
        // Create new group
        if (!result[group]) {
            result[group] = {
                data: [],
                title: group,
            };
        }
        // Append to group
        result[group].data.push(item);
        return result;
    }, {}));
    return sections;
}
exports.convertArrayToSectionListData = convertArrayToSectionListData;
