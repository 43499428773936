Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/ReactionIconBar.js';
exports.default = ReactionIconBar;

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _reactNative = require('react-native');

var _styles = require('../styles');

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function ReactionIconBar(props) {
  var styles = (0, _styles.buildStylesheet)('reactionIconBar', props.styles);

  return React.createElement(
    _reactNative.View,
    { style: styles.container, __source: {
        fileName: _jsxFileName,
        lineNumber: 15
      }
    },
    props.children
  );
}