"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const version_1 = require("./version");
const components_1 = require("./components");
const core_1 = require("@bluebase/core");
const filters_1 = require("./filters");
const lang_1 = require("./lang");
const screens_1 = require("./screens");
exports.default = core_1.createPlugin({
    description: 'A plugin to show reports and charts to users',
    key: '@mevris/client-plugin-reports',
    name: 'Mevris Reports Plugin',
    version: version_1.VERSION,
    components: Object.assign(Object.assign({}, components_1.components), screens_1.screens),
    filters: Object.assign(Object.assign({}, lang_1.lang), filters_1.filters),
});
