"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const apollo_link_1 = require("apollo-link");
const apollo_link_context_1 = require("apollo-link-context");
exports.authorization = {
    // HTTP Auth
    'plugin.apollo.link': {
        key: 'apollo-authorization',
        value: async (link, _ctx, BB) => {
            const authLink = apollo_link_context_1.setContext((_, { headers }) => {
                // get the authentication token from local storage if it exists
                const token = BB.Configs.getValue('user.accessToken');
                // return the headers to the context so httpLink can read them
                return {
                    headers: Object.assign(Object.assign({}, headers), { authorization: token ? token : '' }),
                };
            });
            return apollo_link_1.ApolloLink.from([authLink, link]);
        },
    },
    // WS Auth
    'plugin.apollo.wsLinkOptions': {
        key: 'apollo-ws-authorization',
        value: async (configs, _ctx, BB) => {
            return Object.assign(Object.assign({}, configs), { options: Object.assign(Object.assign({}, configs.options), { 
                    // By doing lazy, we make sure ws is initialized for logged in users
                    // As only loggedin users will be able to see components that can
                    // call subscription
                    lazy: true, connectionParams: async () => ({
                        authorization: BB.Configs.getValue('user.accessToken'),
                    }) }) });
        },
    },
};
