"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@bluebase/core");
exports.ProgressBarDefaultProps = {
    animating: true,
    indeterminate: true,
    styleAttr: 'Normal',
};
// tslint:disable: jsdoc-format
/**
 * # 💈 ProgressBar
 *
 * Used to indicate that the app is loading or there is some activity in the app.
 *
 * ## Usage
 ```jsx
import * as React from 'react';
import { ProgressBar } from '@bluebase/components';

const MyComponent = () => (
  <ProgressBar indeterminate={false} progress={0.5} />
);

export default MyComponent;
```
 */
exports.ProgressBar = core_1.getComponent('ProgressBar');
exports.ProgressBar.defaultProps = exports.ProgressBarDefaultProps;
