Object.defineProperty(exports, "__esModule", {
  value: true
});

var _reactNativeActivityFeedCore = require('react-native-activity-feed-core');

Object.keys(_reactNativeActivityFeedCore).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _reactNativeActivityFeedCore[key];
    }
  });
});

var _reactNativeImagePicker = require('react-native-image-picker');

var _reactNativeImagePicker2 = _interopRequireDefault(_reactNativeImagePicker);

var _reactNative = require('react-native');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

(0, _reactNativeActivityFeedCore.registerNativeHandlers)({
  pickImage: function pickImage() {
    return new Promise(function (resolve, reject) {
      _reactNativeImagePicker2.default.showImagePicker(null, function (response) {
        if (response.error) {
          reject(Error(response.error));
        }
        var uri = response.uri;

        if (_reactNative.Platform.OS === 'android') {
          uri = 'file://' + response.path;
        }

        resolve({
          cancelled: response.didCancel,
          uri: uri
        });
      });
    });
  }
});