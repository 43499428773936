"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.components = {
    AirConditionerControlUI: Promise.resolve().then(() => __importStar(require('./AirConditionerControlUI'))),
    DataItemView: Promise.resolve().then(() => __importStar(require('./DataItemView'))),
    DynamicControlUI: Promise.resolve().then(() => __importStar(require('./DynamicControlUI'))),
    ListControlUI: Promise.resolve().then(() => __importStar(require('./ListControlUI'))),
    MevrisUIView: Promise.resolve().then(() => __importStar(require('./MevrisUIView'))),
};
