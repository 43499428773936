"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@bluebase/core");
exports.TableBodyDefaultProps = {};
// tslint:disable: jsdoc-format
/**
 * # 🏓 TableBody
 *
 * Data tables allow displaying sets of data.
 *
 */
exports.TableBody = core_1.getComponent('TableBody');
exports.TableBody.defaultProps = exports.TableBodyDefaultProps;
