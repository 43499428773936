"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Validates a value (post format) against a data type
 */
exports.format = {
    /**
     * Format "BOOLEAN" value
     */
    BOOLEAN: (value, _schema) => {
        if (value === true || value === 'true' || value === '1' || value === 1) {
            return true;
        }
        if (value === false || value === 'false' || value === '0' || value === 0) {
            return false;
        }
        // We return value as is, expecting it to fail in validation step
        return value;
    },
    /**
     * Format "COLOR MAP" value
     */
    COLOR_MAP: (value, _schema) => {
        return value;
    },
    /**
     * Format "DATE" value
     */
    DATE: (value, _schema) => {
        return new Date(value);
    },
    /**
     * Format "ENUM" value
     */
    ENUM: (value, _schema) => {
        return value;
    },
    /**
     * Format "GEO LOCATION" value
     */
    GEO_LOCATION: (value, _schema) => {
        if (typeof value === 'object') {
            if ('lat' in value && 'lng' in value) {
                if (typeof value.lat === 'string') {
                    value.lat = parseFloat(value.lat);
                }
                if (typeof value.lng === 'string') {
                    value.lng = parseFloat(value.lng);
                }
                return { longitude: value.lng, latitude: value.lat };
            }
            if ('latitude' in value && 'longitude' in value) {
                if (typeof value.latitude === 'string') {
                    value.latitude = parseFloat(value.latitude);
                }
                if (typeof value.longitude === 'string') {
                    value.longitude = parseFloat(value.longitude);
                }
                return { longitude: value.longitude, latitude: value.latitude };
            }
            if (Array.isArray(value) && value.length === 2) {
                return {
                    latitude: parseFloat(value[0]),
                    longitude: parseFloat(value[1]),
                };
            }
        }
        return value;
    },
    /**
     * Format "NUMBER" value
     */
    NUMBER: (value, _schema) => {
        // If a number is sent as string, would parse it into float or int type
        if (typeof value === 'string') {
            /**
             * Checking if string is in float representation
             * Would pass if string contains only numbers
             */
            if (/^-?[0-9]\d*(\.\d+)?$/.test(value)) {
                return parseFloat(value);
            }
        }
        // We return value as is, expecting it to fail in validation step
        return value;
    },
    /**
     * Format "OBJECT" value
     */
    OBJECT: (value, _schema) => {
        if (typeof value === 'string') {
            try {
                return JSON.parse(value);
            }
            catch (e) {
                return null;
            }
        }
        return value;
    },
    /**
     * Format "STRING" value
     */
    STRING: (value, _schema) => {
        return String(value);
    },
    /**
     * Format "VECTOR 3" value
     */
    VECTOR3: (value, _schema) => {
        if (Array.isArray(value) && value.length === 3) {
            return {
                x: parseFloat(value[0]),
                y: parseFloat(value[1]),
                z: parseFloat(value[2]),
            };
        }
        return value;
    },
};
