"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JsonObject = {
    key: 'JsonObject',
    dataType: 'OBJECT',
    /**
     * Transforms JSON Object to a valid JSON String
     *
     * Example {key: "value"} => "{"key":"value"}"
     */
    template: '{{ JSON.stringify(value) }}',
};
