"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useBlueBase_1 = require("./useBlueBase");
function useConfig(key, callback, deps = []) {
    const BB = useBlueBase_1.useBlueBase();
    const [value, setConfig] = react_1.useState(BB.Configs.getValue(key));
    const setValue = (v) => BB.Configs.setValue(key, v);
    react_1.useEffect(() => {
        let subscriptionId;
        let cancelled = false;
        // Subscribe
        subscriptionId = BB.Configs.subscribe(key, (v, item) => {
            if (cancelled) {
                BB.Configs.unsubscribe(key, subscriptionId);
                return;
            }
            setConfig(v);
            if (callback) {
                callback(v, item, cancelled);
            }
        });
        // Unsubscribe from config updates
        return () => {
            cancelled = true;
            BB.Configs.unsubscribe(key, subscriptionId);
        };
    }, [value, ...deps]);
    return [value, setValue];
}
exports.useConfig = useConfig;
