"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const version_1 = require("./version");
const components_1 = require("./components");
const core_1 = require("@bluebase/core");
const configs_1 = require("./configs");
const filters_1 = require("./filters");
const lang_1 = require("./lang");
exports.default = core_1.createPlugin({
    description: 'A plugin to show main taskbar navigation in the app',
    key: '@mevris/client-plugin-taskbar',
    name: 'Mevris Taskbar Plugin',
    version: version_1.VERSION,
    defaultConfigs: configs_1.defaultConfigs,
    components: components_1.components,
    filters: Object.assign({}, lang_1.lang, filters_1.filters),
});
