Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StreamApp = exports.StreamContext = undefined;
var _jsxFileName = 'src/Context/StreamApp.js';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _getstream = require('getstream');

var _getstream2 = _interopRequireDefault(_getstream);

var _streamAnalytics = require('stream-analytics');

var _streamAnalytics2 = _interopRequireDefault(_streamAnalytics);

var _Feed = require('./Feed');

var _errors = require('../errors');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var StreamContext = exports.StreamContext = React.createContext({
  changedUserData: function changedUserData() {},
  sharedFeedManagers: {}
});

var StreamApp = exports.StreamApp = function (_React$Component) {
  _inherits(StreamApp, _React$Component);

  function StreamApp(props) {
    _classCallCheck(this, StreamApp);

    var _this = _possibleConstructorReturn(this, (StreamApp.__proto__ || Object.getPrototypeOf(StreamApp)).call(this, props));

    var client = _getstream2.default.connect(_this.props.apiKey, _this.props.token, _this.props.appId, _this.props.options || {});

    var analyticsClient = void 0;
    if (_this.props.analyticsToken) {
      analyticsClient = new _streamAnalytics2.default({
        apiKey: _this.props.apiKey,
        token: _this.props.analyticsToken
      });
      analyticsClient.setUser(client.userId);
    }
    _this.state = {
      client: client,
      user: client.currentUser,
      userData: client.currentUser.data,
      changedUserData: function changedUserData() {
        _this.setState({ userData: _this.state.user.data });
      },
      analyticsClient: analyticsClient,
      sharedFeedManagers: {},
      errorHandler: _this.props.errorHandler
    };
    for (var _iterator = _this.props.sharedFeeds, _isArray = Array.isArray(_iterator), _i = 0, _iterator = _isArray ? _iterator : _iterator[typeof Symbol === 'function' ? Symbol.iterator : '@@iterator']();;) {
      var _ref;

      if (_isArray) {
        if (_i >= _iterator.length) break;
        _ref = _iterator[_i++];
      } else {
        _i = _iterator.next();
        if (_i.done) break;
        _ref = _i.value;
      }

      var feedProps = _ref;

      var manager = new _Feed.FeedManager(_extends({}, feedProps, _this.state));
      _this.state.sharedFeedManagers[manager.feed().id] = manager;
    }
    return _this;
  }

  _createClass(StreamApp, [{
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps) {
      var appIdDifferent = this.props.appId !== prevProps.appId;
      if (appIdDifferent) {}
    }
  }, {
    key: 'componentDidMount',
    value: function componentDidMount() {
      return regeneratorRuntime.async(function componentDidMount$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.prev = 0;
              _context.next = 3;
              return regeneratorRuntime.awrap(this.state.user.getOrCreate(this.props.defaultUserData));

            case 3:
              _context.next = 9;
              break;

            case 5:
              _context.prev = 5;
              _context.t0 = _context['catch'](0);

              this.props.errorHandler(_context.t0, 'get-user-info', {
                userId: this.state.user.id
              });
              return _context.abrupt('return');

            case 9:
              this.state.changedUserData();

            case 10:
            case 'end':
              return _context.stop();
          }
        }
      }, null, this, [[0, 5]]);
    }
  }, {
    key: 'render',
    value: function render() {
      return React.createElement(
        StreamContext.Provider,
        { value: _extends({}, this.state), __source: {
            fileName: _jsxFileName,
            lineNumber: 167
          }
        },
        this.props.children
      );
    }
  }]);

  return StreamApp;
}(React.Component);

StreamApp.defaultProps = {
  sharedFeeds: [{
    feedGroup: 'notification',
    notify: true,
    options: { mark_seen: true }
  }],
  defaultUserData: { name: 'Unknown' },
  errorHandler: _errors.handleError
};

StreamApp.Consumer = function StreamAppConsumer(props) {
  return React.createElement(
    StreamContext.Consumer,
    {
      __source: {
        fileName: _jsxFileName,
        lineNumber: 91
      }
    },
    function (appCtx) {
      if (!props.children || !props.children.length) {
        return null;
      }
      if (!appCtx.client || !appCtx.user) {
        throw new Error('This component should be a child of a StreamApp component');
      }
      var Child = props.children;
      return Child(appCtx);
    }
  );
};