"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const DataType = tslib_1.__importStar(require("../DataType"));
const resolve_1 = require("./resolve");
function validate(value, schema) {
    const resolvedSchema = resolve_1.resolve(schema);
    const dataType = resolvedSchema.dataType;
    if (!dataType) {
        throw new Error('Invalid data type');
    }
    let isValid = false;
    // Validate against data type
    const dataTypeValidater = DataType.validate[dataType];
    if (dataTypeValidater) {
        isValid = dataTypeValidater(value, resolvedSchema);
    }
    if (!isValid) {
        return isValid;
    }
    // Validate against schema
    if (resolvedSchema.validate) {
        isValid = resolvedSchema.validate(value, resolvedSchema);
    }
    return isValid;
}
exports.validate = validate;
