"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.layouts = {
    SettingsPageDesktop: Promise.resolve().then(() => __importStar(require('./SettingsPage/SettingsPageDesktop'))),
    SettingsPageItemDesktop: Promise.resolve().then(() => __importStar(require('./SettingsPageItem/SettingsPageItemDesktop'))),
    SettingsPageItemMobile: Promise.resolve().then(() => __importStar(require('./SettingsPageItem/SettingsPageItemMobile'))),
    SettingsPageList: Promise.resolve().then(() => __importStar(require('./SettingsPageList/SettingsPageList'))),
    SettingsPageMobile: Promise.resolve().then(() => __importStar(require('./SettingsPage/SettingsPageMobile'))),
};
