"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const core_1 = require("@bluebase/core");
const react_1 = tslib_1.__importStar(require("react"));
const InternalNavigator_1 = require("../InternalNavigator");
const preparePaths_1 = require("./preparePaths");
exports.MainNavigatorContext = react_1.createContext({ routes: [] });
function createNavigation(InputRouter) {
    var _a;
    /**
     * Navigation
     * This serves as an entry point where BlueBase passes routes and navigation
     * configs to this component.
     */
    return _a = class Navigation extends react_1.default.Component {
            render() {
                const _a = this.props, { navigator, screenProps } = _a, rest = tslib_1.__rest(_a, ["navigator", "screenProps"]);
                const BB = this.context;
                // Make sure paths are in correct format.
                const navigatorObject = preparePaths_1.preparePaths(navigator);
                const routerProps = BB.Configs.getValue('plugin.react-router.router-props');
                return (react_1.default.createElement(exports.MainNavigatorContext.Provider, { value: navigatorObject },
                    react_1.default.createElement(InputRouter, Object.assign({}, rest, routerProps),
                        react_1.default.createElement(InternalNavigator_1.InternalNavigator, { navigator: navigatorObject }))));
            }
        },
        _a.contextType = core_1.BlueBaseContext,
        _a;
}
exports.createNavigation = createNavigation;
