"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const react_1 = tslib_1.__importStar(require("react"));
const helpers_1 = require("../../helpers");
exports.DateRangePresetMenu = ({ value = 'lastSevenDays', onValueChange, }) => {
    const [visible, setVisible] = react_1.useState(false);
    const toggle = () => setVisible(!visible);
    const setDateRange = (inputRange) => () => {
        onValueChange(inputRange);
        toggle();
    };
    return (react_1.default.createElement(components_1.Menu, { visible: visible, onDismiss: toggle, anchor: react_1.default.createElement(components_1.Button, { onPress: toggle, variant: "outlined", size: "small", title: helpers_1.DateRangePresets[value].label }) }, Object.keys(helpers_1.DateRangePresets).map(key => (react_1.default.createElement(components_1.Menu.Item, { key: key, onPress: setDateRange(key), title: helpers_1.DateRangePresets[key].label })))));
};
