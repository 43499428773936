"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Percent = {
    key: 'Percent',
    dataType: 'NUMBER',
    /**
     * Transform raw state value into a percentaage
     *
     * Example: 0.15 => 15%
     */
    template: '{{ value*100 }}%',
    precision: 2,
};
