"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const version_1 = require("./version");
const components_1 = require("./components");
const core_1 = require("@bluebase/core");
const lang_1 = require("./lang");
tslib_1.__exportStar(require("./components/exports"), exports);
exports.default = core_1.createPlugin({
    description: 'The plugin provides common permissions UI across Mevris Apps.',
    key: 'client-plugin-permissions-ui',
    name: 'Mevris UI',
    version: version_1.VERSION,
    assets: {
        CameraAskPermissionViewImage: require('../assets/common/location-permission.png'),
        CameraPermissionDeniedViewImage: require('../assets/common/location-permission.png'),
        LocationAskPermissionViewImage: require('../assets/common/location-permission.png'),
        LocationPermissionDeniedViewImage: require('../assets/common/location-permission.png'),
    },
    components: components_1.components,
    filters: Object.assign({}, lang_1.lang),
});
