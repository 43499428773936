"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const react_1 = tslib_1.__importStar(require("react"));
const core_1 = require("@bluebase/core");
const imports_1 = require("../../imports");
const PlaceTypeList = core_1.getComponent('PlaceTypeList');
exports.PlaceTypePicker = (props) => {
    const { __ } = core_1.useIntl();
    const [dialogVisible, setDialogVisible] = react_1.useState(false);
    const { name, mutating, onValueChange, loading } = props;
    const toggleDialog = () => setDialogVisible(!dialogVisible);
    const onPlaceTypeListPress = (item) => {
        if (onValueChange) {
            onValueChange(item);
        }
        toggleDialog();
    };
    return (react_1.default.createElement(react_1.default.Fragment, null,
        loading ? (react_1.default.createElement(imports_1.SkeletonListItem, { avatar: true, description: true, variant: "icon" })) : (react_1.default.createElement(components_1.List.Item, { left: react_1.default.createElement(components_1.List.Icon, { name: "city" }), right: mutating && react_1.default.createElement(components_1.ActivityIndicator, null), title: __('Place Type'), description: name || __('Select a place type'), onPress: toggleDialog })),
        react_1.default.createElement(components_1.Dialog, { visible: dialogVisible, dismissable: true, onDismiss: toggleDialog },
            react_1.default.createElement(components_1.List.Subheader, null, __('Select a Place Type')),
            react_1.default.createElement(components_1.Divider, null),
            react_1.default.createElement(PlaceTypeList, { onPress: onPlaceTypeListPress, style: { width: core_1.isMobile() ? undefined : 400 } }))));
};
exports.PlaceTypePicker.displayName = 'PlaceTypePicker';
