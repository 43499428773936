Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.smartRender = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _jsxFileName = 'src/utils.js';
exports.humanizeTimestamp = humanizeTimestamp;
exports.sleep = sleep;
exports.generateRandomId = generateRandomId;

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function humanizeTimestamp(timestamp) {
  var time = _moment2.default.utc(timestamp);
  var now = (0, _moment2.default)();

  return _moment2.default.min(time, now).from(now);
}

var smartRender = exports.smartRender = function smartRender(ElementOrComponentOrLiteral, props, fallback) {
  if (ElementOrComponentOrLiteral === undefined) {
    ElementOrComponentOrLiteral = fallback;
  }
  if (React.isValidElement(ElementOrComponentOrLiteral)) {
    var element = ElementOrComponentOrLiteral;
    return element;
  }

  var ComponentOrLiteral = ElementOrComponentOrLiteral;
  if (typeof ComponentOrLiteral === 'string' || typeof ComponentOrLiteral === 'number' || typeof ComponentOrLiteral === 'boolean' || ComponentOrLiteral == null) {
    return ComponentOrLiteral;
  }
  return React.createElement(ComponentOrLiteral, _extends({}, props, {
    __source: {
      fileName: _jsxFileName,
      lineNumber: 37
    }
  }));
};

function sleep(ms) {
  return new Promise(function (resolve) {
    return setTimeout(resolve, ms);
  });
}

function generateRandomId() {
  return S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4();
}

function S4() {
  return ((1 + Math.random()) * 0x10000 | 0).toString(16).substring(1);
}