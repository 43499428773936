"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const react_1 = tslib_1.__importStar(require("react"));
const hooks_1 = require("../hooks");
const useLocale_1 = require("../hooks/useLocale");
/**
 * This is the context where BlueBase theme is stored.
 */
exports.IntlContext = react_1.createContext(undefined);
/**
 * BlueBase theme consumer.
 */
exports.IntlConsumer = exports.IntlContext.Consumer;
/**
 * 🈯️ IntlProvider
 */
exports.IntlProvider = (props) => {
    const [locale, setLocale] = useLocale_1.useLocale();
    const { rtl, direction, setDirection } = hooks_1.useRtl();
    // Example: "bluebase.intl.messages.en", "bluebase.intl.messages.ur"
    const { value: messages } = hooks_1.useFilter(`bluebase.intl.messages.${locale}`, {});
    const __ = (message) => (messages[message] ? messages[message] : message);
    const value = {
        __,
        changeDirection: setDirection,
        changeLocale: setLocale,
        direction,
        locale,
        messages,
        rtl,
    };
    return react_1.default.createElement(exports.IntlContext.Provider, { value: value, children: props.children });
};
exports.IntlProvider.displayName = 'IntlProvider';
