"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@bluebase/core");
exports.BottomNavigationDefaultProps = {
    showLabels: false,
};
// tslint:disable: jsdoc-format
/**
 * # 🗂 BottomNavigation
 * A simple view that renders tabs.
 *
 * ## Usage
 *
 * ```jsx
<BottomNavigation
    value={value}
    onChange={this.handleChange}
    showLabels
>
    <BottomNavigationAction label="Recents" />
    <BottomNavigationAction label="Favorites" />
    <BottomNavigationAction label="Nearby" />
</BottomNavigation>```
 */
exports.BottomNavigation = core_1.getComponent('BottomNavigation');
exports.BottomNavigation.defaultProps = exports.BottomNavigationDefaultProps;
