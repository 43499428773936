"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const react_1 = tslib_1.__importStar(require("react"));
// tslint:disable: object-literal-sort-keys
const GranularityOptions = {
    hour: 'Hours',
    day: 'Days',
    week: 'Weeks',
    month: 'Months',
    year: 'Years',
};
exports.GranularityMenu = ({ value = 'day', onValueChange }) => {
    const [visible, setVisible] = react_1.useState(false);
    const toggle = () => setVisible(!visible);
    const setDateRange = (input) => () => {
        onValueChange(input);
        toggle();
    };
    return (react_1.default.createElement(components_1.Menu, { visible: visible, onDismiss: toggle, anchor: react_1.default.createElement(components_1.Button, { onPress: toggle, variant: "outlined", size: "small", title: GranularityOptions[value] }) }, Object.keys(GranularityOptions).map(key => (react_1.default.createElement(components_1.Menu.Item, { key: key, onPress: setDateRange(key), title: GranularityOptions[key] })))));
};
