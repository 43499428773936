"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const version_1 = require("./version");
const components_1 = require("./components");
const core_1 = require("@bluebase/core");
const lang_1 = require("./lang");
tslib_1.__exportStar(require("./components/exports"), exports);
exports.default = core_1.createPlugin({
    description: 'The plugin provides common UI across Mevris Apps.',
    key: 'client-plugin-location-ui',
    name: 'Mevris UI',
    version: version_1.VERSION,
    assets: {
        CameraAskPermissionViewImage: require('../assets/common/site-not-found.png'),
        CameraPermissionDeniedViewImage: require('../assets/common/site-not-found.png'),
        LocationAskPermissionViewImage: require('../assets/common/site-not-found.png'),
        LocationPermissionDeniedViewImage: require('../assets/common/site-not-found.png'),
        // Placeholders
        PersonPlaceholder: require('../assets/common/person-placeholder.png'),
        PersonPlaceholder_Dark: require('../assets/common/person-placeholder-dark.png'),
        PlacePlaceholder: require('../assets/common/place-placeholder.png'),
        PlacePlaceholder_Dark: require('../assets/common/place-placeholder-dark.png'),
        ProductPlaceholder: require('../assets/common/product-placeholder.png'),
        ProductPlaceholder_Dark: require('../assets/common/product-placeholder-dark.png'),
        ThingPlaceholder: require('../assets/common/thing-placeholder.png'),
        ThingPlaceholder_Dark: require('../assets/common/thing-placeholder-dark.png'),
    },
    components: components_1.components,
    filters: Object.assign({}, lang_1.lang),
});
