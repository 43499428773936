"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.settings = {
    CallSupportSetting: Promise.resolve().then(() => __importStar(require('./CallSupportSetting'))),
    DarkModeSetting: Promise.resolve().then(() => __importStar(require('./DarkModeSetting'))),
    DeveloperSetting: Promise.resolve().then(() => __importStar(require('./DeveloperSetting'))),
    EmailSupportSetting: Promise.resolve().then(() => __importStar(require('./EmailSupportSetting'))),
    LanguageSetting: Promise.resolve().then(() => __importStar(require('./LanguageSetting'))),
    PrivacyPolicySetting: Promise.resolve().then(() => __importStar(require('./PrivacyPolicySetting'))),
    TermsOfServiceSetting: Promise.resolve().then(() => __importStar(require('./TermsOfServiceSetting'))),
    TextDirectionSetting: Promise.resolve().then(() => __importStar(require('./TextDirectionSetting'))),
    ThemeSelectionSetting: Promise.resolve().then(() => __importStar(require('./ThemeSelectionSetting'))),
    VersionSetting: Promise.resolve().then(() => __importStar(require('./VersionSetting'))),
};
