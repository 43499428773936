"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@bluebase/core");
exports.routes = () => [
    {
        exact: true,
        name: 'Login',
        path: 'login',
        screen: 'LoginScreen',
        navigationOptions: {
            header: null,
        },
    },
    {
        exact: true,
        name: 'Logout',
        path: 'logout',
        screen: 'LogoutScreen',
        navigationOptions: {
            header: null,
        },
    },
    {
        exact: true,
        name: 'Signup',
        path: 'signup',
        screen: 'SignupScreen',
        navigationOptions: {
            // header should be visible on mobile
            header: core_1.isMobile() ? undefined : null,
            title: 'Signup',
        },
    },
    {
        exact: true,
        name: 'ResetPassword',
        path: 'reset-password',
        screen: 'ResetPasswordScreen',
        navigationOptions: {
            header: core_1.isMobile() ? undefined : null,
            title: 'Reset Password',
        },
    },
    {
        exact: true,
        name: 'VerifyEmail',
        path: 'verify-email',
        screen: 'VerifyEmailScreen',
        navigationOptions: {
            header: core_1.isMobile() ? undefined : null,
            title: 'Verify Email',
        },
    },
    {
        exact: true,
        name: 'ResetPasswordCode',
        path: 'reset-password-code',
        screen: 'ResetPasswordCodeScreen',
        navigationOptions: {
            header: core_1.isMobile() ? undefined : null,
            title: 'Reset Password Code',
        },
    },
    {
        exact: true,
        name: 'ChangePassword',
        path: 'change-password',
        screen: 'ChangePasswordScreen',
        navigationOptions: {
            header: core_1.isMobile() ? undefined : null,
            title: 'Change Password',
        },
    },
    {
        exact: true,
        name: 'Support',
        path: 'support',
        screen: 'Support',
        navigationOptions: {
            // header should be visible on mobile
            header: core_1.isMobile() ? undefined : null,
            title: 'Support',
        },
    },
    {
        exact: true,
        name: 'Privacy',
        path: 'privacy',
        screen: 'Privacy',
        navigationOptions: {
            // header should be visible on mobile
            header: core_1.isMobile() ? undefined : null,
            title: 'Privacy',
        },
    },
    {
        exact: true,
        name: 'Terms',
        path: 'terms',
        screen: 'Terms',
        navigationOptions: {
            // header should be visible on mobile
            header: core_1.isMobile() ? undefined : null,
            title: 'Terms',
        },
    },
];
exports.navigator = {
    name: 'Auth',
    path: '/(login|signup|reset-password|verify-email|signout)',
    type: 'stack',
    routes: exports.routes(),
};
