"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@bluebase/core");
exports.SnackbarDefaultProps = {};
// tslint:disable: jsdoc-format
/**
 * # 🍿 Snackbar
 *
 * Snackbars provide brief feedback about an operation through a message at the bottom of the screen.
 *
 * ## Static Properties:
 *
 * These properties can be accessed on Snackbar by using the dot notation, e.g. Snackbar.DURATION_SHORT.
 *
 * - DURATION_SHORT: Show the Snackbar for a short duration.
 * - DURATION_MEDIUM: Show the Snackbar for a medium duration.
 * - DURATION_LONG: Show the Snackbar for a long duration.
 *
 * ## Usage
 ```jsx
import * as React from 'react';
import { StyleSheet } from 'react-native';
import { Snackbar } from '@bluebase/components';

export default class MyComponent extends React.Component {
  state = {
    visible: false,
  };

  render() {
    const { visible } = this.state;
    return (
      <View style={styles.container}>
        <Button
          onPress={() => this.setState(state => ({ visible: !state.visible }))}
        >
          {this.state.visible ? 'Hide' : 'Show'}
        </Button>
        <Snackbar
          visible={this.state.visible}
          onDismiss={() => this.setState({ visible: false })}
          action={{
            label: 'Undo',
            onPress: () => {
              // Do something
            },
          }}
        >
          Hey there! I'm a Snackbar.
        </Snackbar>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
  },
});
```
 */
exports.Snackbar = core_1.getComponent('Snackbar');
exports.Snackbar.defaultProps = exports.SnackbarDefaultProps;
exports.Snackbar.DURATION_SHORT = 4000;
exports.Snackbar.DURATION_MEDIUM = 7000;
exports.Snackbar.DURATION_LONG = 10000;
