"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const rn_placeholder_1 = require("rn-placeholder");
const components_1 = require("@bluebase/components");
const react_1 = tslib_1.__importStar(require("react"));
const helpers_1 = require("../../helpers");
exports.Collapsible = (props) => {
    const { open: initialOpen, title, TitleComponent: titleComponent, onChangeState, loading, children, style, styles, } = props;
    const TitleComponent = helpers_1.resolveComponent(titleComponent);
    const [isOpen, collapse] = react_1.useState(initialOpen);
    if (loading === true) {
        return (react_1.default.createElement(components_1.View, { style: Object.assign(Object.assign({}, styles.root), style), testID: "collapsible-skeleton" },
            react_1.default.createElement(rn_placeholder_1.Placeholder, { Animation: rn_placeholder_1.Fade },
                react_1.default.createElement(components_1.View, { style: Object.assign(Object.assign({}, styles.titleWrapper), { justifyContent: 'space-between' }) },
                    react_1.default.createElement(rn_placeholder_1.PlaceholderLine, { width: 16, height: styles.titleLineHeight, noMargin: true, testID: "skeleton-title" }),
                    react_1.default.createElement(rn_placeholder_1.PlaceholderMedia, { isRound: false, size: 23 }))),
            isOpen && children));
    }
    const toggle = () => {
        collapse(!isOpen);
        if (onChangeState) {
            onChangeState(!isOpen);
        }
    };
    return (react_1.default.createElement(components_1.View, { style: Object.assign(Object.assign({}, styles.root), style) },
        react_1.default.createElement(components_1.TouchableItem, { onPress: toggle },
            react_1.default.createElement(components_1.View, { style: styles.titleWrapper },
                react_1.default.createElement(TitleComponent, { style: styles.title }, title),
                react_1.default.createElement(components_1.Icon, { size: 20, name: !isOpen ? 'chevron-down' : 'chevron-up' }))),
        isOpen && children));
};
const defaultProps = {
    TitleComponent: components_1.Overline,
    open: true,
};
const defaultStyles = (theme) => ({
    root: {},
    titleWrapper: {
        alignItems: 'center',
        flexDirection: 'row',
        paddingHorizontal: theme.spacing.unit * 2,
        paddingVertical: theme.spacing.unit,
    },
    title: {
        flex: 1,
        fontWeight: 'bold',
    },
    titleLineHeight: theme.typography.overline.fontSize + 2,
});
exports.Collapsible.defaultProps = defaultProps;
exports.Collapsible.defaultStyles = defaultStyles;
