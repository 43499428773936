"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Validates a value (post format) against a data type
 */
exports.validate = {
    /**
     * Validate a value against "BOOLEAN" DataSchema
     */
    BOOLEAN: (value, _schema) => {
        return typeof value === 'boolean';
    },
    /**
     * Validate a value against "COLOR_MAP" DataSchema
     */
    COLOR_MAP: (value, _schema) => {
        const keys = Object.keys(value);
        if (keys.indexOf('hue') !== -1 &&
            typeof value.hue === 'number' &&
            (keys.indexOf('saturation') !== -1 &&
                typeof value.saturation === 'number')) {
            return true;
        }
        return false;
    },
    /**
     * Validate a value against "DATE" DataSchema
     *
     * TODO: Handle min, max
     */
    DATE: (value, _schema) => {
        const date = new Date(value);
        if (isNaN(date.getTime())) {
            return false;
        }
        else {
            return true;
        }
    },
    /**
     * Validate a value against "ENUM" DataSchema
     */
    ENUM: (value, schema) => {
        const values = (schema && schema.values) || [];
        return values.findIndex(schemaValue => schemaValue.value === value) > -1;
    },
    /**
     * Validate a value against "GEO_LOCATION" DataSchema
     */
    GEO_LOCATION: (value, _schema) => {
        if (typeof value === 'object') {
            return ('longitude' in value &&
                typeof value.longitude === 'number' &&
                ('latitude' in value && typeof value.latitude === 'number'));
        }
        else {
            return false;
        }
    },
    /**
     * Validate a value against "NUMBER" DataSchema
     */
    NUMBER: (value, schema) => {
        if (typeof value !== 'number') {
            return false;
        }
        if (schema.max && value > schema.max) {
            return false;
        }
        if (schema.min && value < schema.min) {
            return false;
        }
        return true;
    },
    /**
     * Validate a value against "OBJECT" DataSchema
     */
    OBJECT: (value, _schema) => {
        return typeof value === 'object' && value !== null;
    },
    /**
     * Validate a value against "STRING" DataSchema
     */
    STRING: (value, _schema) => {
        return typeof value === 'string';
    },
    /**
     * Validate a value against "VECTOR3" DataSchema
     */
    VECTOR3: (value, _schema) => {
        const keys = Object.keys(value);
        return (keys.indexOf('x') !== -1 &&
            typeof value.x === 'number' &&
            (keys.indexOf('y') !== -1 && typeof value.y === 'number') &&
            (keys.indexOf('z') !== -1 && typeof value.z === 'number'));
    },
};
