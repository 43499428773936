"use strict";
// import * as Permissions from 'expo-permissions';
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkCameraRollPermissionAsync = async () => {
    // const { status } = await Permissions.Permissions.getAsync(Permissions.CAMERA_ROLL);
    return { status: 'granted' };
};
/**
 * expo manages requesting permission for both android and ios
 */
exports.requestCameraRollPermissionsAsync = async () => {
    return { status: 'granted' };
};
