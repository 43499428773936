"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@bluebase/core");
/**
 * A foundational component for inputting text into the app via a keyboard.
 * Props provide configurability for several features, such as auto-correction,
 * auto-capitalization, placeholder text, and different keyboard types, such
 * as a numeric keypad.
 */
exports.TextInput = core_1.getComponent('TextInput');
/**
 * Default props for TextInput component
 */
exports.TextInputDefaultProps = {
    autoCapitalize: 'sentences',
    autoComplete: 'on',
    autoCorrect: true,
    editable: true,
    keyboardType: 'default',
    multiline: false,
    numberOfLines: 1,
    secureTextEntry: false,
    style: {},
};
