"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Date = {
    key: 'Date',
    dataType: 'DATE',
    /**
     * Transforms raw state value into an ISO Valid Date String
     *
     * Example: Mon Jul 08 2019 11:45:40 GMT+0500 (Pakistan Standard Time) => 08/07/2019
     */
    template: `{{ new Intl.DateTimeFormat('en-GB').format(new Date()) }}`,
};
