"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const React = tslib_1.__importStar(require("react"));
const components_1 = require("@bluebase/components");
const react_native_1 = require("react-native");
const list = [
    {
        boldText: 'Compliance with Laws: ',
        normalText: 'You may use the software product only for lawful purposes.',
        number: 'I. ',
    },
    {
        boldText: 'Limitations on Reverse Engineering: ',
        normalText: 'You may not reverse engineer, decompile, or disassemble the software product, ' +
            'except and only to the extent that such activity is expressly permitted by ' +
            'applicable law notwithstanding this limitation.',
        number: 'II. ',
    },
    {
        boldText: 'Separation of Components: ',
        normalText: 'The software product is licensed as a single product. ' +
            'Its component parts may not be separated for use on more than one computer.',
        number: 'III. ',
    },
    {
        boldText: 'Trademarks: ',
        normalText: 'This EULA does not grant you any rights in connection with any trademarks or service marks of BLUEEAST Pvt. Ltd.',
        number: 'IV. ',
    },
    {
        boldText: 'Rental: ',
        normalText: 'You may not sell, rent, lease, sublicense, lend or otherwise transfer' +
            ' your rights to the Software Product.',
        number: 'V. ',
    },
    {
        boldText: 'Support Services: ',
        normalText: 'BLUEEAST Pvt. Ltd. may provide you with support services related to the ' +
            'Software Product. Any supplemental software ' +
            'code provided to you as part of the support services shall be considered part ' +
            'of the software product and subject to the terms ' +
            'and conditions of this EULA. With respect to technical information you provide to ' +
            'BLUEEAST Pvt. Ltd. as part of the support services,' +
            ' BLUEEAST Pvt. Ltd. may use such information for its business purposes, including for' +
            ' product support and development. BLUEEAST Pvt.' +
            ' Ltd. will not utilize such technical information in a form that personally identifies you.',
        number: 'VI. ',
    },
    {
        boldText: 'Software Transfer: ',
        normalText: 'The initial licensee of the software product may make a one-time permanent transfer' +
            ' of this EULA and software product only' +
            ' directly to an end user. This transfer must include the entire software product' +
            ' (including all component parts, any upgrades, this EULA).' +
            ' The transferee of such one-time transfer must agree to comply with the terms of this EULA.',
        number: 'VII. ',
    },
    {
        boldText: 'Termination: ',
        normalText: 'Without prejudice to any other rights, BLUEEAST Pvt. Ltd. may terminate this EULA if you fail to ' +
            'comply with the terms and' +
            ' conditions of this EULA. In such event, you must destroy all copies of the ' +
            'software product and all of its component parts.',
        number: 'VIII. ',
    },
];
exports.Terms = (props) => {
    const styles = props.styles;
    const width = react_native_1.Dimensions.get('window').width;
    return (React.createElement(components_1.ScrollView, { style: { width } },
        React.createElement(components_1.Card, null,
            React.createElement(components_1.Container, { style: styles.container },
                React.createElement(components_1.View, { style: styles.pageContainer },
                    React.createElement(components_1.H5, null, "End User License Agreement"),
                    React.createElement(components_1.Body1, { style: styles.p1 }, "This copy of MEVRIS App(\u201Cthe Software Product\u201D) and accompanying documentation is licensed and not sold. This Software Product is protected by copy right laws and treaties, as well as laws and treaties related to other forms of intellectual property. BLUEEAST Pvt. Ltd. having its registered office at 26K.M., Multan Road, Lahore through its duly authorized officers. or its subsidiaries, affiliates, and suppliers (collectively \u201CLicensee\u201D) own intellectual property rights in the Software Product. The Licensee\u2019s (\u201Cyou\u201D or \u201CYour\u201D license to download, use, copy, or change the Software Product is subject to these rights and to all the terms and conditions of this End User License Agreement (\u201CAgreement\u201D)."),
                    React.createElement(components_1.H5, { style: styles.H5 }, "Acceptance"),
                    React.createElement(components_1.Body1, { style: styles.body }, "You accept and agree to be bound by the terms of this agreement by selecting the \u201Caccept\u201D option and downloading the software product or by installing, using, or copying the software product. You must agree to all of the terms of this agreement before you will be allowed to download the software product. If you do not agree to all of the terms of this agreement, you must select \u201Cdecline\u201D and you must not install, use or copy the software product."),
                    React.createElement(components_1.H5, { style: styles.H5 }, "License Grant"),
                    React.createElement(components_1.Body1, { style: styles.body }, "This Agreement entitles you to install and use one copy of the Software Product. In addition, you may make one archival copy of the Software Product. The archival copy must be on a storage medium other than a hard drive, and may only be used for the reinstallation of the Software Product. This Agreement does not permit the installation or use of multiple copies of the Software Product, or the installation of the Software Product on more than one computer at any given time, on a system that allows shared use of application, on a multi-users network, or on any configuration or system of computers that allows multiple users. Multiple copy use or installation is only allowed if you obtain an appropriate licensing agreement for each user and each copy of the Software Product."),
                    React.createElement(components_1.H5, { style: styles.H5 }, "Description of other Rights and Limitation"),
                    list.map((item, i) => (React.createElement(exports.List, Object.assign({ key: i }, item)))),
                    React.createElement(components_1.H5, { style: styles.H5 }, "Restrictions on Transfer"),
                    React.createElement(components_1.Body1, { style: styles.body }, "Without first obtaining the express written consent of BLUEEAST Pvt. Ltd. , you may not assign your rights and obligations under this Agreement, or redistribute, encumber, sell, rent, lease, sublicense, or otherwise transfer your rights to the Software Product."),
                    React.createElement(components_1.H5, { style: styles.H5 }, "Limitation of Liability"),
                    React.createElement(components_1.Body1, { style: styles.body }, "To the maximum extent permitted by applicable law, in no event shall BLUEEAST be liable for any special, incidental, indirect, or consequential damages whatsoever (including, without limitation, damages for loss of business profits, business interruption, loss of business information, or any other pecuniary loss) arising out of the use of or inability to use the software product or the provision of or failure to provide support services. No liability shall be carried by BLUEEAST Pvt. Ltd. related to accuracy of the data or any damage to end user occurring due to use of this data by end user. Furthermore, no liability shall be carried by BLUEEAST Pvt. Ltd. related to the unavailability of cloud server and/or app due to maintenance or any other reason."))))));
};
// tslint:disable-next-line: variable-name
exports.List = ({ number, boldText, normalText }, props) => {
    const styles = props.styles || {};
    return (React.createElement(components_1.View, { style: styles.listStyle },
        React.createElement(components_1.View, { style: styles.widthStyle },
            React.createElement(components_1.View, null,
                React.createElement(components_1.Body1, { style: styles.columnDirection },
                    React.createElement(components_1.H6, null, number),
                    React.createElement(components_1.Body1, { style: styles.boldFont },
                        boldText,
                        React.createElement(components_1.Text, null, normalText)))))));
};
exports.Terms.defaultStyles = (_theme) => ({
    H5: {
        paddingBottom: 10,
        paddingTop: 11,
    },
    boldFont: {
        display: 'flex',
        flexWrap: 'wrap',
        fontWeight: 'bold',
    },
    closeButton: {
        alignSelf: 'center',
        display: 'flex',
        maxWidth: 0,
    },
    columnDirection: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
    },
    container: {
        padding: 10,
    },
    headerStyles: {
        display: 'flex',
        flex: 1,
    },
    listStyle: {
        flexDirection: 'row',
        paddingTop: 4,
    },
    p1: {
        paddingTop: 20,
    },
    pageContainer: {
        marginBottom: 50,
    },
    rowStyles: {
        display: 'flex',
        flexDirection: 'row',
    },
    widthStyle: {
        width: 25,
    },
});
