"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
// tslint:disable: jsdoc-format
/**
 *
 * ```tsx
const Component = () => {
  const { layout, onLayout } = useComponentLayout();
  return <View onLayout={onLayout} />;
};
```
 */
exports.useComponentLayout = () => {
    const [layout, setLayout] = react_1.useState({ width: 0, height: 0, x: 0, y: 0 });
    const onLayout = react_1.useCallback(event => {
        setLayout(event.nativeEvent.layout);
    }, []);
    return { layout, onLayout };
};
