"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Url = {
    key: 'Url',
    dataType: 'STRING',
    template: '{{ value }}',
    validate: (value, _schema) => {
        const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port
            '(\\?[;&amp;a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i');
        const valid = pattern.test(value);
        return !!valid;
    },
};
