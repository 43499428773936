"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const version_1 = require("./version");
const components_1 = require("./components");
const core_1 = require("@bluebase/core");
const configs_1 = require("./configs");
const lang_1 = require("./lang");
const layouts_1 = require("./layouts");
const routes_1 = require("./routes");
const settings_1 = require("./settings");
var lib_1 = require("./lib");
exports.createSettingsRoutes = lib_1.createSettingsRoutes;
exports.default = core_1.createPlugin({
    description: 'A settings app for the BlueBase framework!',
    key: 'settings',
    name: 'Settings',
    version: version_1.VERSION,
    icon: {
        component: 'SettingsAppIcon',
        type: 'component',
    },
    defaultConfigs: configs_1.defaultConfigs,
    components: Object.assign(Object.assign(Object.assign({}, components_1.components), settings_1.settings), layouts_1.layouts),
    indexRoute: 'SettingsApp',
    routes: routes_1.routes,
    filters: Object.assign({}, lang_1.lang),
});
