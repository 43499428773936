Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/CommentsContainer.js';

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactNative = require('react-native');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var CommentsContainer = function CommentsContainer(_ref) {
  var data = _ref.data,
      renderComment = _ref.renderComment,
      renderMoreLink = _ref.renderMoreLink,
      maxComments = _ref.maxComments;
  return _react2.default.createElement(
    _reactNative.View,
    {
      __source: {
        fileName: _jsxFileName,
        lineNumber: 11
      }
    },
    data.slice(0, maxComments).map(renderComment),
    data.length > 0 && data.length > maxComments ? renderMoreLink() : null
  );
};

exports.default = CommentsContainer;