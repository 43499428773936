"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const Animatable = tslib_1.__importStar(require("react-native-animatable"));
const components_1 = require("@bluebase/components");
const react_1 = tslib_1.__importStar(require("react"));
const core_1 = require("@bluebase/core");
const AvatarMarker = core_1.getComponent('AvatarMarker');
const LocationInputDialog = core_1.getComponent('LocationInputDialog');
const Pulse = core_1.getComponent('Pulse');
const MapView = core_1.getComponent('MapView');
const LocateMeButton = core_1.getComponent('LocateMeButton');
// https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
const mapOptions = {
    controlSize: 24,
    streetViewControl: false,
    mapTypeControlOptions: {
        style: 2,
    },
};
exports.LocationInputView = (props) => {
    const { avatarMarkerProps, title, description, onSubmit, onCancel } = props;
    const styles = core_1.useStyles('LocationInputView', props, defaultStyles);
    const { __ } = core_1.useIntl();
    const [location, setLocation] = react_1.useState({
        latitude: props.latitude || 0,
        latitudeDelta: 0.0056198344733822125,
        longitude: props.longitude || 0,
        longitudeDelta: 0.004053488373756409,
    });
    const [dialogVisible, setDialogVisible] = react_1.useState(false);
    const [isSubmitting, setSubmitting] = react_1.useState(false);
    const marker = react_1.useRef(null);
    /**
     * Animates the marker with a bounce animation
     */
    function bounceMarker() {
        marker.current.bounce(800);
    }
    /**
     * Toggles dialog visibility
     */
    const toggleDialog = () => setDialogVisible(!dialogVisible);
    /**
     * Updates location coordinates, and animates marker
     */
    function updateCoordinates(input) {
        if (input.latitude === location.latitude && input.longitude === location.longitude) {
            return;
        }
        setLocation({
            latitude: input.latitude,
            latitudeDelta: input.latitudeDelta || location.latitudeDelta,
            longitude: input.longitude,
            longitudeDelta: input.longitudeDelta || location.longitudeDelta,
        });
        bounceMarker();
    }
    /**
     * Updates location when the submit button is pressed on the dialog
     */
    function onDialogSubmit(coordinates) {
        updateCoordinates(coordinates);
        toggleDialog();
    }
    /**
     * onPress handler of the "Save" button
     */
    function handleSubmit() {
        onSubmit({
            coordinates: location,
            setSubmitting,
        });
    }
    return (react_1.default.createElement(components_1.View, { style: styles.root },
        react_1.default.createElement(LocationInputDialog, { visible: dialogVisible, onSubmit: onDialogSubmit, latitude: location.latitude, longitude: location.longitude, onDismiss: toggleDialog }),
        react_1.default.createElement(components_1.List.Item, { title: title, description: description, left: react_1.default.createElement(components_1.List.Avatar, { type: "icon", icon: "home-map-marker" }), right: react_1.default.createElement(components_1.IconButton, { name: "dots-vertical", onPress: toggleDialog }) }),
        react_1.default.createElement(components_1.Divider, null),
        react_1.default.createElement(components_1.View, { style: { flex: 1 } },
            react_1.default.createElement(MapView, { region: location, options: mapOptions, provider: "google", style: styles.map, onRegionChangeComplete: updateCoordinates }),
            react_1.default.createElement(components_1.View, { style: styles.pulse },
                react_1.default.createElement(Pulse, { size: 30 })),
            react_1.default.createElement(Animatable.View, { style: styles.marker, ref: marker },
                react_1.default.createElement(AvatarMarker, Object.assign({ type: "image", image: "Placeholder" }, avatarMarkerProps)))),
        react_1.default.createElement(components_1.Divider, null),
        react_1.default.createElement(components_1.Card.Actions, null,
            react_1.default.createElement(components_1.SafeAreaView, { style: styles.actions },
                react_1.default.createElement(LocateMeButton, { onLocationUpdate: updateCoordinates }),
                react_1.default.createElement(components_1.View, { style: styles.spacer }),
                react_1.default.createElement(components_1.Button, { title: __('Cancel'), color: "primary", size: "small", onPress: onCancel, variant: "text", style: styles.cancelButton }),
                react_1.default.createElement(components_1.Button, { title: __('Save'), color: "primary", loading: isSubmitting, disabled: isSubmitting, size: "small", onPress: handleSubmit, variant: "contained" })))));
};
exports.LocationInputView.defaultProps = {
    description: 'You can drag the marker, or click anywhere on the map to adjust the position',
    title: 'Confirm Location',
};
const defaultStyles = () => ({
    root: {
        flex: 1,
    },
    map: {
        flexGrow: 1,
    },
    marker: {
        left: '50%',
        marginLeft: -27,
        marginTop: -70,
        position: 'absolute',
        top: '50%',
    },
    pulse: {
        left: '50%',
        marginLeft: -15,
        marginTop: -37,
        position: 'absolute',
        top: '50%',
    },
    actions: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    spacer: {
        flex: 1,
    },
    cancelButton: {
        marginRight: 8,
    },
});
