"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const path_to_regexp_1 = require("path-to-regexp");
const findRouteByKey_1 = require("./findRouteByKey");
const core_1 = require("@bluebase/core");
exports.executeAction = (configs, fn, routeName, params = {}) => {
    let path;
    if (typeof routeName === 'string') {
        const routeObj = findRouteByKey_1.findRouteByKey(routeName, 'name', configs);
        path = routeObj && routeObj.path;
    }
    else if (routeName &&
        typeof routeName.routeName === 'string') {
        // const routeObj = findRouteByKey(routeName as any, 'routeName', configs);
        const routeObj = findRouteByKey_1.findRouteByKey(routeName.routeName, 'name', configs);
        path = routeObj && routeObj.path;
    }
    else if (routeName && typeof routeName.path === 'string') {
        path = routeName.path;
    }
    if (!path) {
        throw Error('Invalid props provided to navigation action');
    }
    const toPath = path_to_regexp_1.compile(`/${core_1.joinPaths(path)}`);
    fn(toPath(params), params);
};
