"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const BaseNavigator_1 = require("../BaseNavigator");
const core_1 = require("@bluebase/core");
const TabView = core_1.getComponent('TabView');
/**
 */
class TabNavigator extends BaseNavigator_1.BaseNavigator {
}
TabNavigator.defaultProps = {
    RouteView: TabView
};
exports.TabNavigator = TabNavigator;
