"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const rn_placeholder_1 = require("rn-placeholder");
const PlaceholderBody1_1 = require("./PlaceholderBody1");
const PlaceholderBody2_1 = require("./PlaceholderBody2");
const PlaceholderCaption_1 = require("./PlaceholderCaption");
const PlaceholderH1_1 = require("./PlaceholderH1");
const PlaceholderH2_1 = require("./PlaceholderH2");
const PlaceholderH3_1 = require("./PlaceholderH3");
const PlaceholderH4_1 = require("./PlaceholderH4");
const PlaceholderH5_1 = require("./PlaceholderH5");
const PlaceholderH6_1 = require("./PlaceholderH6");
const PlaceholderListItem_1 = require("./PlaceholderListItem");
const PlaceholderOverline_1 = require("./PlaceholderOverline");
const PlaceholderSubtitle1_1 = require("./PlaceholderSubtitle1");
const PlaceholderSubtitle2_1 = require("./PlaceholderSubtitle2");
exports.components = {
    Placeholder: rn_placeholder_1.Placeholder,
    PlaceholderLine: rn_placeholder_1.PlaceholderLine,
    PlaceholderMedia: rn_placeholder_1.PlaceholderMedia,
    PlaceholderBody1: PlaceholderBody1_1.PlaceholderBody1,
    PlaceholderBody2: PlaceholderBody2_1.PlaceholderBody2,
    PlaceholderCaption: PlaceholderCaption_1.PlaceholderCaption,
    PlaceholderH1: PlaceholderH1_1.PlaceholderH1,
    PlaceholderH2: PlaceholderH2_1.PlaceholderH2,
    PlaceholderH3: PlaceholderH3_1.PlaceholderH3,
    PlaceholderH4: PlaceholderH4_1.PlaceholderH4,
    PlaceholderH5: PlaceholderH5_1.PlaceholderH5,
    PlaceholderH6: PlaceholderH6_1.PlaceholderH6,
    PlaceholderListItem: PlaceholderListItem_1.PlaceholderListItem,
    PlaceholderOverline: PlaceholderOverline_1.PlaceholderOverline,
    PlaceholderSubtitle1: PlaceholderSubtitle1_1.PlaceholderSubtitle1,
    PlaceholderSubtitle2: PlaceholderSubtitle2_1.PlaceholderSubtitle2,
};
