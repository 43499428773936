"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const BlueBaseModuleRegistry_1 = require("@bluebase/core/dist/registries/BlueBaseModuleRegistry");
/**
 * 🕹 DeviceControllerRegistry
 */
class DeviceControllerRegistry extends BlueBaseModuleRegistry_1.BlueBaseModuleRegistry {
    /**
     * Returns a Promise that resolves a DeviceController class
     * @param keys
     */
    async resolve(...keys) {
        const item = this.findOne(...keys);
        if (!item) {
            throw Error(`Could not resolve any of the following DeviceControllers: [${keys.join(', ')}].`);
        }
        return item.value;
    }
    /**
     * Typeguard to check a given object is a registry value
     * @param value
     */
    isValue(value) {
        return typeof value === 'function';
    }
    /**
     * Typeguard to check a given object is an input value
     * @param value
     */
    isInputValue(value) {
        return this.isValue(value);
    }
}
exports.DeviceControllerRegistry = DeviceControllerRegistry;
