"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.components = {
    // AppleLoginButton: import('./AppleLoginButton'),
    ChangeNameSetting: Promise.resolve().then(() => __importStar(require('./ChangeNameSetting'))),
    FacebookLoginButton: Promise.resolve().then(() => __importStar(require('./FacebookLoginButton'))),
    ForgotPasswordButton: Promise.resolve().then(() => __importStar(require('./ForgotPasswordButton'))),
    GoogleLoginButton: Promise.resolve().then(() => __importStar(require('./GoogleLoginButton'))),
    LogoutSetting: Promise.resolve().then(() => __importStar(require('./LogoutSetting'))),
    PopularNames: Promise.resolve().then(() => __importStar(require('./PopularNames'))),
    Privacy: Promise.resolve().then(() => __importStar(require('./Privacy'))),
    RequireAuthentication: Promise.resolve().then(() => __importStar(require('./RequireAuthentication'))),
    SocialAccountsList: Promise.resolve().then(() => __importStar(require('./SocialAccountsList'))),
    Support: Promise.resolve().then(() => __importStar(require('./Support'))),
    Terms: Promise.resolve().then(() => __importStar(require('./Terms'))),
    TextDivider: Promise.resolve().then(() => __importStar(require('./TextDivider'))),
    UserManagementDesktopLayout: Promise.resolve().then(() => __importStar(require('./DesktopLayout'))),
    UserManagementFooter: Promise.resolve().then(() => __importStar(require('./UserManagementFooter'))),
    UserManagementMobileLayout: Promise.resolve().then(() => __importStar(require('./MobileLayout'))),
};
