"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const react_native_1 = require("react-native");
const react_1 = tslib_1.__importStar(require("react"));
const components_1 = require("@bluebase/components");
const ScreenSizeObserver_1 = require("../ScreenSizeObserver");
const helpers_1 = require("../../helpers");
const initialSize = 12;
exports.RowContext = react_1.createContext(initialSize);
exports.RowConsumer = exports.RowContext.Consumer;
exports.Row = (props) => (react_1.default.createElement(ScreenSizeObserver_1.ScreenSizeObserver, null, (screenSize) => {
    if (helpers_1.isHidden(screenSize, props)) {
        return null;
    }
    const { alignItems, justifyContent, nowrap, rowSize, style, styles } = props, rest = tslib_1.__rest(props, ["alignItems", "justifyContent", "nowrap", "rowSize", "style", "styles"]);
    const stylesheet = [
        style,
        styles.root,
        {
            alignItems,
            flexWrap: nowrap ? 'nowrap' : 'wrap',
            justifyContent,
        },
        react_native_1.Platform.OS === 'web' ? { overflow: 'visible' } : {}
    ];
    return (react_1.default.createElement(exports.RowContext.Provider, { value: rowSize },
        react_1.default.createElement(components_1.View, Object.assign({}, rest, { style: stylesheet }))));
}));
exports.Row.defaultProps = {
    rowSize: initialSize
};
exports.Row.defaultStyles = (theme) => ({
    root: {
        flexDirection: 'row',
        marginHorizontal: -1 * theme.spacing.unit,
        paddingVertical: theme.spacing.unit,
    }
});
