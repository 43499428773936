Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Feed = exports.FeedManager = exports.FeedContext = undefined;
var _jsxFileName = 'src/Context/Feed.js';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _reactNative = require('react-native');

var _immutable = require('immutable');

var _immutable2 = _interopRequireDefault(_immutable);

var _urlParse = require('url-parse');

var _urlParse2 = _interopRequireDefault(_urlParse);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _utils = require('../utils');

var _isPlainObject = require('lodash/isPlainObject');

var _isPlainObject2 = _interopRequireDefault(_isPlainObject);

var _StreamApp = require('./StreamApp');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var FeedContext = exports.FeedContext = React.createContext({});

var FeedManager = exports.FeedManager = function () {
  function FeedManager(props) {
    var _this = this;

    _classCallCheck(this, FeedManager);

    this.state = {
      activityOrder: [],
      activities: _immutable2.default.Map(),
      activityIdToPath: {},
      activityIdToPaths: {},
      reactionIdToPaths: {},
      reactionActivities: {},
      lastResponse: null,
      lastReverseResponse: null,
      refreshing: false,
      realtimeAdds: [],
      realtimeDeletes: [],
      subscription: null,
      unread: 0,
      unseen: 0,
      numSubscribers: 0,
      reactionsBeingToggled: {},
      childReactionsBeingToggled: {}
    };

    this.setState = function (changed) {
      if (typeof changed === 'function') {
        changed = changed(_this.state);
      }
      _this.state = _extends({}, _this.state, changed);
      _this.triggerUpdate();
    };

    this.trackAnalytics = function (label, activity, track) {
      var analyticsClient = _this.props.analyticsClient;

      if (!track) {
        return;
      }
      if (!analyticsClient) {
        console.warn('trackAnalytics was enabled, but analytics client was not initialized. ' + 'Please set the analyticsToken prop on StreamApp');
        return;
      }

      var feed = _this.props.client.feed(_this.props.feedGroup, _this.props.userId);

      analyticsClient.trackEngagement({
        label: label,
        feed_id: feed.id,
        content: {
          foreign_id: activity.foreign_id
        },
        location: _this.props.analyticsLocation
      });
    };

    this.getActivityPath = function (activity) {
      for (var _len = arguments.length, rest = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        rest[_key - 1] = arguments[_key];
      }

      var activityId = void 0;
      if (typeof activity === 'string') {
        activityId = activity;
      } else {
        activityId = activity.id;
      }

      var activityPath = _this.state.activityIdToPath[activityId];
      if (activityPath === undefined) {
        return [activityId].concat(rest);
      }
      return [].concat(_toConsumableArray(activityPath), rest);
    };

    this.getActivityPaths = function (activity) {
      var activityId = void 0;
      if (typeof activity === 'string') {
        activityId = activity;
      } else {
        activityId = activity.id;
      }

      return _this.state.activityIdToPaths[activityId];
    };

    this.getReactionPaths = function (reaction) {
      var reactionId = void 0;
      if (typeof reaction === 'string') {
        reactionId = reaction;
      } else {
        reactionId = reaction.id;
      }

      return _this.state.reactionIdToPaths[reactionId];
    };

    this.onAddReaction = function _callee(kind, activity, data) {
      var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      var reaction, enrichedReaction;
      return regeneratorRuntime.async(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              reaction = void 0;
              _context.prev = 1;

              if (!_this.props.doReactionAddRequest) {
                _context.next = 8;
                break;
              }

              _context.next = 5;
              return regeneratorRuntime.awrap(_this.props.doReactionAddRequest(kind, activity, data, options));

            case 5:
              reaction = _context.sent;
              _context.next = 11;
              break;

            case 8:
              _context.next = 10;
              return regeneratorRuntime.awrap(_this.props.client.reactions.add(kind, activity, data, options));

            case 10:
              reaction = _context.sent;

            case 11:
              _context.next = 17;
              break;

            case 13:
              _context.prev = 13;
              _context.t0 = _context['catch'](1);

              _this.props.errorHandler(_context.t0, 'add-reaction', {
                kind: kind,
                activity: activity,
                feedGroup: _this.props.feedGroup,
                userId: _this.props.userId
              });
              return _context.abrupt('return');

            case 17:
              _this.trackAnalytics(kind, activity, options.trackAnalytics);
              enrichedReaction = _immutable2.default.fromJS(_extends({}, reaction, {
                user: _this.props.user.full
              }));


              _this.setState(function (prevState) {
                var activities = prevState.activities;
                var reactionIdToPaths = prevState.reactionIdToPaths;

                for (var _iterator = _this.getActivityPaths(activity), _isArray = Array.isArray(_iterator), _i = 0, _iterator = _isArray ? _iterator : _iterator[typeof Symbol === 'function' ? typeof Symbol === 'function' ? typeof Symbol === 'function' ? Symbol.iterator : '@@iterator' : '@@iterator' : '@@iterator']();;) {
                  var _ref;

                  if (_isArray) {
                    if (_i >= _iterator.length) break;
                    _ref = _iterator[_i++];
                  } else {
                    _i = _iterator.next();
                    if (_i.done) break;
                    _ref = _i.value;
                  }

                  var path = _ref;

                  _this.removeFoundReactionIdPaths(activities.getIn(path).toJS(), reactionIdToPaths, path);

                  activities = activities.updateIn([].concat(_toConsumableArray(path), ['reaction_counts', kind]), function () {
                    var v = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
                    return v + 1;
                  }).updateIn([].concat(_toConsumableArray(path), ['own_reactions', kind]), function () {
                    var v = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _immutable2.default.List();
                    return v.unshift(enrichedReaction);
                  }).updateIn([].concat(_toConsumableArray(path), ['latest_reactions', kind]), function () {
                    var v = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _immutable2.default.List();
                    return v.unshift(enrichedReaction);
                  });

                  _this.addFoundReactionIdPaths(activities.getIn(path).toJS(), reactionIdToPaths, path);
                }

                return { activities: activities, reactionIdToPaths: reactionIdToPaths };
              });

            case 20:
            case 'end':
              return _context.stop();
          }
        }
      }, null, _this, [[1, 13]]);
    };

    this.onRemoveReaction = function _callee2(kind, activity, id) {
      var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      return regeneratorRuntime.async(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              _context2.prev = 0;

              if (!_this.props.doReactionDeleteRequest) {
                _context2.next = 6;
                break;
              }

              _context2.next = 4;
              return regeneratorRuntime.awrap(_this.props.doReactionDeleteRequest(id));

            case 4:
              _context2.next = 8;
              break;

            case 6:
              _context2.next = 8;
              return regeneratorRuntime.awrap(_this.props.client.reactions.delete(id));

            case 8:
              _context2.next = 14;
              break;

            case 10:
              _context2.prev = 10;
              _context2.t0 = _context2['catch'](0);

              _this.props.errorHandler(_context2.t0, 'delete-reaction', {
                kind: kind,
                activity: activity,
                feedGroup: _this.props.feedGroup,
                userId: _this.props.userId
              });
              return _context2.abrupt('return');

            case 14:
              _this.trackAnalytics('un' + kind, activity, options.trackAnalytics);
              if (_this.state.reactionActivities[id]) {
                _this._removeActivityFromState(_this.state.reactionActivities[id]);
              }

              return _context2.abrupt('return', _this.setState(function (prevState) {
                var activities = prevState.activities;
                var reactionIdToPaths = prevState.reactionIdToPaths;

                for (var _iterator2 = _this.getActivityPaths(activity), _isArray2 = Array.isArray(_iterator2), _i2 = 0, _iterator2 = _isArray2 ? _iterator2 : _iterator2[typeof Symbol === 'function' ? typeof Symbol === 'function' ? typeof Symbol === 'function' ? Symbol.iterator : '@@iterator' : '@@iterator' : '@@iterator']();;) {
                  var _ref2;

                  if (_isArray2) {
                    if (_i2 >= _iterator2.length) break;
                    _ref2 = _iterator2[_i2++];
                  } else {
                    _i2 = _iterator2.next();
                    if (_i2.done) break;
                    _ref2 = _i2.value;
                  }

                  var path = _ref2;

                  _this.removeFoundReactionIdPaths(activities.getIn(path).toJS(), reactionIdToPaths, path);

                  activities = activities.updateIn([].concat(_toConsumableArray(path), ['reaction_counts', kind]), function () {
                    var v = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
                    return v - 1;
                  }).updateIn([].concat(_toConsumableArray(path), ['own_reactions', kind]), function () {
                    var v = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _immutable2.default.List();
                    return v.remove(v.findIndex(function (r) {
                      return r.get('id') === id;
                    }));
                  }).updateIn([].concat(_toConsumableArray(path), ['latest_reactions', kind]), function () {
                    var v = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _immutable2.default.List();
                    return v.remove(v.findIndex(function (r) {
                      return r.get('id') === id;
                    }));
                  });

                  _this.addFoundReactionIdPaths(activities.getIn(path).toJS(), reactionIdToPaths, path);
                }

                return { activities: activities, reactionIdToPaths: reactionIdToPaths };
              }));

            case 17:
            case 'end':
              return _context2.stop();
          }
        }
      }, null, _this, [[0, 10]]);
    };

    this.onToggleReaction = function _callee3(kind, activity, data) {
      var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      var togglingReactions, currentReactions, last;
      return regeneratorRuntime.async(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              togglingReactions = _this.state.reactionsBeingToggled[kind] || {};

              if (!togglingReactions[activity.id]) {
                _context3.next = 3;
                break;
              }

              return _context3.abrupt('return');

            case 3:
              togglingReactions[activity.id] = true;
              _this.state.reactionsBeingToggled[kind] = togglingReactions;

              currentReactions = _this.state.activities.getIn([].concat(_toConsumableArray(_this.getActivityPaths(activity)[0]), ['own_reactions', kind]), _immutable2.default.List());
              last = currentReactions.last();

              if (!last) {
                _context3.next = 12;
                break;
              }

              _context3.next = 10;
              return regeneratorRuntime.awrap(_this.onRemoveReaction(kind, activity, last.get('id'), options));

            case 10:
              _context3.next = 14;
              break;

            case 12:
              _context3.next = 14;
              return regeneratorRuntime.awrap(_this.onAddReaction(kind, activity, data, options));

            case 14:
              delete togglingReactions[activity.id];

            case 15:
            case 'end':
              return _context3.stop();
          }
        }
      }, null, _this);
    };

    this.onAddChildReaction = function _callee4(kind, reaction, data) {
      var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      var childReaction, enrichedReaction;
      return regeneratorRuntime.async(function _callee4$(_context4) {
        while (1) {
          switch (_context4.prev = _context4.next) {
            case 0:
              childReaction = void 0;
              _context4.prev = 1;

              if (!_this.props.doChildReactionAddRequest) {
                _context4.next = 8;
                break;
              }

              _context4.next = 5;
              return regeneratorRuntime.awrap(_this.props.doChildReactionAddRequest(kind, reaction, data, options));

            case 5:
              childReaction = _context4.sent;
              _context4.next = 11;
              break;

            case 8:
              _context4.next = 10;
              return regeneratorRuntime.awrap(_this.props.client.reactions.addChild(kind, reaction, data, options));

            case 10:
              childReaction = _context4.sent;

            case 11:
              _context4.next = 17;
              break;

            case 13:
              _context4.prev = 13;
              _context4.t0 = _context4['catch'](1);

              _this.props.errorHandler(_context4.t0, 'add-child-reaction', {
                kind: kind,
                reaction: reaction,
                feedGroup: _this.props.feedGroup,
                userId: _this.props.userId
              });
              return _context4.abrupt('return');

            case 17:
              enrichedReaction = _immutable2.default.fromJS(_extends({}, childReaction, {
                user: _this.props.user.full
              }));


              _this.setState(function (prevState) {
                var activities = prevState.activities;

                for (var _iterator3 = _this.getReactionPaths(reaction), _isArray3 = Array.isArray(_iterator3), _i3 = 0, _iterator3 = _isArray3 ? _iterator3 : _iterator3[typeof Symbol === 'function' ? typeof Symbol === 'function' ? typeof Symbol === 'function' ? Symbol.iterator : '@@iterator' : '@@iterator' : '@@iterator']();;) {
                  var _ref3;

                  if (_isArray3) {
                    if (_i3 >= _iterator3.length) break;
                    _ref3 = _iterator3[_i3++];
                  } else {
                    _i3 = _iterator3.next();
                    if (_i3.done) break;
                    _ref3 = _i3.value;
                  }

                  var path = _ref3;

                  activities = activities.updateIn([].concat(_toConsumableArray(path), ['children_counts', kind]), function () {
                    var v = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
                    return v + 1;
                  }).updateIn([].concat(_toConsumableArray(path), ['own_children', kind]), function () {
                    var v = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _immutable2.default.List();
                    return v.unshift(enrichedReaction);
                  }).updateIn([].concat(_toConsumableArray(path), ['latest_children', kind]), function () {
                    var v = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _immutable2.default.List();
                    return v.unshift(enrichedReaction);
                  });
                }

                return { activities: activities };
              });

            case 19:
            case 'end':
              return _context4.stop();
          }
        }
      }, null, _this, [[1, 13]]);
    };

    this.onRemoveChildReaction = function _callee5(kind, reaction, id) {
      var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      return regeneratorRuntime.async(function _callee5$(_context5) {
        while (1) {
          switch (_context5.prev = _context5.next) {
            case 0:
              _context5.prev = 0;

              if (!_this.props.doChildReactionDeleteRequest) {
                _context5.next = 6;
                break;
              }

              _context5.next = 4;
              return regeneratorRuntime.awrap(_this.props.doChildReactionDeleteRequest(id));

            case 4:
              _context5.next = 8;
              break;

            case 6:
              _context5.next = 8;
              return regeneratorRuntime.awrap(_this.props.client.reactions.delete(id));

            case 8:
              _context5.next = 14;
              break;

            case 10:
              _context5.prev = 10;
              _context5.t0 = _context5['catch'](0);

              _this.props.errorHandler(_context5.t0, 'delete-reaction', {
                kind: kind,
                reaction: reaction,
                feedGroup: _this.props.feedGroup,
                userId: _this.props.userId
              });
              return _context5.abrupt('return');

            case 14:
              if (_this.state.reactionActivities[id]) {
                _this._removeActivityFromState(_this.state.reactionActivities[id]);
              }

              return _context5.abrupt('return', _this.setState(function (prevState) {
                var activities = prevState.activities;

                for (var _iterator4 = _this.getReactionPaths(reaction), _isArray4 = Array.isArray(_iterator4), _i4 = 0, _iterator4 = _isArray4 ? _iterator4 : _iterator4[typeof Symbol === 'function' ? typeof Symbol === 'function' ? typeof Symbol === 'function' ? Symbol.iterator : '@@iterator' : '@@iterator' : '@@iterator']();;) {
                  var _ref4;

                  if (_isArray4) {
                    if (_i4 >= _iterator4.length) break;
                    _ref4 = _iterator4[_i4++];
                  } else {
                    _i4 = _iterator4.next();
                    if (_i4.done) break;
                    _ref4 = _i4.value;
                  }

                  var path = _ref4;

                  activities = activities.updateIn([].concat(_toConsumableArray(path), ['children_counts', kind]), function () {
                    var v = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
                    return v - 1;
                  }).updateIn([].concat(_toConsumableArray(path), ['own_children', kind]), function () {
                    var v = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _immutable2.default.List();
                    return v.remove(v.findIndex(function (r) {
                      return r.get('id') === id;
                    }));
                  }).updateIn([].concat(_toConsumableArray(path), ['latest_children', kind]), function () {
                    var v = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _immutable2.default.List();
                    return v.remove(v.findIndex(function (r) {
                      return r.get('id') === id;
                    }));
                  });
                }

                return { activities: activities };
              }));

            case 16:
            case 'end':
              return _context5.stop();
          }
        }
      }, null, _this, [[0, 10]]);
    };

    this.onToggleChildReaction = function _callee6(kind, reaction, data) {
      var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      var togglingReactions, currentReactions, last;
      return regeneratorRuntime.async(function _callee6$(_context6) {
        while (1) {
          switch (_context6.prev = _context6.next) {
            case 0:
              togglingReactions = _this.state.childReactionsBeingToggled[kind] || {};

              if (!togglingReactions[reaction.id]) {
                _context6.next = 3;
                break;
              }

              return _context6.abrupt('return');

            case 3:
              togglingReactions[reaction.id] = true;
              _this.state.childReactionsBeingToggled[kind] = togglingReactions;

              currentReactions = _this.state.activities.getIn([].concat(_toConsumableArray(_this.getReactionPaths(reaction)[0]), ['own_children', kind]), _immutable2.default.List());
              last = currentReactions.last();

              if (!last) {
                _context6.next = 12;
                break;
              }

              _context6.next = 10;
              return regeneratorRuntime.awrap(_this.onRemoveChildReaction(kind, reaction, last.get('id'), options));

            case 10:
              _context6.next = 14;
              break;

            case 12:
              _context6.next = 14;
              return regeneratorRuntime.awrap(_this.onAddChildReaction(kind, reaction, data, options));

            case 14:
              delete togglingReactions[reaction.id];

            case 15:
            case 'end':
              return _context6.stop();
          }
        }
      }, null, _this);
    };

    this._removeActivityFromState = function (activityId) {
      return _this.setState(function (_ref5) {
        var activities = _ref5.activities,
            activityOrder = _ref5.activityOrder,
            activityIdToPath = _ref5.activityIdToPath,
            activityIdToPaths = _ref5.activityIdToPaths,
            reactionIdToPaths = _ref5.reactionIdToPaths;

        var path = _this.getActivityPath(activityId);
        var outerId = activityId;
        if (path.length > 1) {
          var groupArrayPath = path.slice(0, -1);
          activityIdToPath = _this.removeFoundActivityIdPath(activities.getIn(groupArrayPath).toJS(), activityIdToPath, groupArrayPath);
          activityIdToPaths = _this.removeFoundActivityIdPaths(activities.getIn(groupArrayPath).toJS(), activityIdToPaths, groupArrayPath);
          reactionIdToPaths = _this.removeFoundReactionIdPaths(activities.getIn(groupArrayPath).toJS(), reactionIdToPaths, groupArrayPath);
        }

        activities = activities.removeIn(path);
        if (path.length > 1) {
          var _groupArrayPath = path.slice(0, -1);
          if (activities.getIn(_groupArrayPath).size === 0) {
            outerId = path[0];
          } else {
            outerId = null;
          }
          activityIdToPath = _this.addFoundActivityIdPath(activities.getIn(_groupArrayPath).toJS(), activityIdToPath, _groupArrayPath);
          activityIdToPaths = _this.addFoundActivityIdPaths(activities.getIn(_groupArrayPath).toJS(), activityIdToPaths, _groupArrayPath);
          reactionIdToPaths = _this.addFoundReactionIdPaths(activities.getIn(_groupArrayPath).toJS(), reactionIdToPaths, _groupArrayPath);
        }
        if (outerId != null) {
          activityOrder = activityOrder.filter(function (id) {
            return id !== outerId;
          });
        }
        return {
          activities: activities,
          activityOrder: activityOrder,
          activityIdToPaths: activityIdToPaths,
          reactionIdToPaths: reactionIdToPaths,
          activityIdToPath: activityIdToPath
        };
      });
    };

    this.onRemoveActivity = function _callee7(activityId) {
      return regeneratorRuntime.async(function _callee7$(_context7) {
        while (1) {
          switch (_context7.prev = _context7.next) {
            case 0:
              _context7.prev = 0;
              _context7.next = 3;
              return regeneratorRuntime.awrap(_this.feed().removeActivity(activityId));

            case 3:
              _context7.next = 9;
              break;

            case 5:
              _context7.prev = 5;
              _context7.t0 = _context7['catch'](0);

              _this.props.errorHandler(_context7.t0, 'delete-activity', {
                activityId: _this.props.feedGroup,
                feedGroup: _this.props.feedGroup,
                userId: _this.props.userId
              });
              return _context7.abrupt('return');

            case 9:
              return _context7.abrupt('return', _this._removeActivityFromState(activityId));

            case 10:
            case 'end':
              return _context7.stop();
          }
        }
      }, null, _this, [[0, 5]]);
    };

    this.onMarkAsRead = function (group) {
      return _this._onMarkAs('read', group);
    };

    this.onMarkAsSeen = function (group) {
      return _this._onMarkAs('seen', group);
    };

    this._onMarkAs = function _callee8(type, group) {
      var groupArray, markArg;
      return regeneratorRuntime.async(function _callee8$(_context8) {
        while (1) {
          switch (_context8.prev = _context8.next) {
            case 0:
              groupArray = void 0;
              markArg = group;

              if (group === true) {
                groupArray = _this.state.activityOrder;
              } else if (Array.isArray(group)) {
                groupArray = group.map(function (g) {
                  return g.id;
                });
                markArg = groupArray;
              } else {
                markArg = group.id;
                groupArray = [group.id];
              }
              _context8.prev = 3;
              _context8.next = 6;
              return regeneratorRuntime.awrap(_this.doFeedRequest(_defineProperty({
                limit: 1,
                id_lte: _this.state.activityOrder[0]
              }, 'mark_' + type, markArg)));

            case 6:
              _context8.next = 11;
              break;

            case 8:
              _context8.prev = 8;
              _context8.t0 = _context8['catch'](3);

              _this.props.errorHandler(_context8.t0, 'get-notification-counts', {
                feedGroup: _this.props.feedGroup,
                userId: _this.props.userId
              });

            case 11:
              _this.setState(function (prevState) {
                var counterKey = 'un' + type;
                var activities = prevState.activities;
                var counter = prevState[counterKey];
                for (var _iterator5 = groupArray, _isArray5 = Array.isArray(_iterator5), _i5 = 0, _iterator5 = _isArray5 ? _iterator5 : _iterator5[typeof Symbol === 'function' ? typeof Symbol === 'function' ? typeof Symbol === 'function' ? Symbol.iterator : '@@iterator' : '@@iterator' : '@@iterator']();;) {
                  var _ref6;

                  if (_isArray5) {
                    if (_i5 >= _iterator5.length) break;
                    _ref6 = _iterator5[_i5++];
                  } else {
                    _i5 = _iterator5.next();
                    if (_i5.done) break;
                    _ref6 = _i5.value;
                  }

                  var groupId = _ref6;

                  var markerPath = [groupId, 'is_' + type];
                  if (activities.getIn(markerPath) !== false) {
                    continue;
                  }
                  activities = activities.setIn(markerPath, true);
                  counter--;
                }
                return _defineProperty({ activities: activities }, counterKey, counter);
              });

            case 12:
            case 'end':
              return _context8.stop();
          }
        }
      }, null, _this, [[3, 8]]);
    };

    this.getOptions = function () {
      var extraOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var propOpts = _extends({}, _this.props.options);
      var id_gt = extraOptions.id_gt,
          id_gte = extraOptions.id_gte,
          id_lt = extraOptions.id_lt,
          id_lte = extraOptions.id_lte,
          offset = extraOptions.offset;

      if (id_gt || id_gte || id_lt || id_lte || offset != null) {
        delete propOpts.id_gt;
        delete propOpts.id_gte;
        delete propOpts.id_lt;
        delete propOpts.id_lte;
        delete propOpts.offset;
        delete propOpts.refresh;
      }

      return _extends({
        withReactionCounts: true,
        withOwnReactions: true,
        limit: 10
      }, propOpts, extraOptions);
    };

    this.doFeedRequest = function _callee9(options) {
      var requestWasSentAt, response, requestTime, MINIMUM_TIME_BETWEEN_REFRESHING_PROP_UPDATES, waitTime;
      return regeneratorRuntime.async(function _callee9$(_context9) {
        while (1) {
          switch (_context9.prev = _context9.next) {
            case 0:
              requestWasSentAt = Date.now();
              response = void 0;

              if (!_this.props.doFeedRequest) {
                _context9.next = 8;
                break;
              }

              _context9.next = 5;
              return regeneratorRuntime.awrap(_this.props.doFeedRequest(_this.props.client, _this.props.feedGroup, _this.props.userId, options));

            case 5:
              response = _context9.sent;
              _context9.next = 11;
              break;

            case 8:
              _context9.next = 10;
              return regeneratorRuntime.awrap(_this.feed().get(options));

            case 10:
              response = _context9.sent;

            case 11:
              if (!(_reactNative.Platform.OS === 'ios')) {
                _context9.next = 18;
                break;
              }

              requestTime = Date.now() - requestWasSentAt;
              MINIMUM_TIME_BETWEEN_REFRESHING_PROP_UPDATES = 350;
              waitTime = MINIMUM_TIME_BETWEEN_REFRESHING_PROP_UPDATES - requestTime;

              if (!(waitTime > 0)) {
                _context9.next = 18;
                break;
              }

              _context9.next = 18;
              return regeneratorRuntime.awrap((0, _utils.sleep)(waitTime));

            case 18:
              return _context9.abrupt('return', response);

            case 19:
            case 'end':
              return _context9.stop();
          }
        }
      }, null, _this);
    };

    this.feed = function () {
      return _this.props.client.feed(_this.props.feedGroup, _this.props.userId);
    };

    this.responseToActivityMap = function (response) {
      return _immutable2.default.fromJS(response.results.reduce(function (map, a) {
        map[a.id] = a;
        return map;
      }, {}));
    };

    this.responseToActivityIdToPath = function (response) {
      if (response.results.length === 0 || response.results[0].activities === undefined) {
        return {};
      }
      var aggregatedResponse = response;

      var map = {};

      var _loop = function _loop(_group) {
        _group.activities.forEach(function (act, i) {
          map[act.id] = [_group.id, 'activities', i];
        });
      };

      for (var _iterator6 = aggregatedResponse.results, _isArray6 = Array.isArray(_iterator6), _i6 = 0, _iterator6 = _isArray6 ? _iterator6 : _iterator6[typeof Symbol === 'function' ? typeof Symbol === 'function' ? Symbol.iterator : '@@iterator' : '@@iterator']();;) {
        var _ref8;

        if (_isArray6) {
          if (_i6 >= _iterator6.length) break;
          _ref8 = _iterator6[_i6++];
        } else {
          _i6 = _iterator6.next();
          if (_i6.done) break;
          _ref8 = _i6.value;
        }

        var _group = _ref8;

        _loop(_group);
      }
      return map;
    };

    this.responseToActivityIdToPaths = function (response) {
      var previous = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      var map = previous;
      var currentPath = [];
      function addFoundActivities(obj) {
        if (Array.isArray(obj)) {
          obj.forEach(function (v, i) {
            currentPath.push(i);
            addFoundActivities(v);
            currentPath.pop();
          });
        } else if ((0, _isPlainObject2.default)(obj)) {
          if (obj.id && obj.actor && obj.verb && obj.object) {
            if (!map[obj.id]) {
              map[obj.id] = [];
            }
            map[obj.id].push([].concat(currentPath));
          }
          for (var k in obj) {
            currentPath.push(k);
            addFoundActivities(obj[k]);
            currentPath.pop();
          }
        }
      }

      for (var _iterator7 = response.results, _isArray7 = Array.isArray(_iterator7), _i7 = 0, _iterator7 = _isArray7 ? _iterator7 : _iterator7[typeof Symbol === 'function' ? typeof Symbol === 'function' ? Symbol.iterator : '@@iterator' : '@@iterator']();;) {
        var _ref9;

        if (_isArray7) {
          if (_i7 >= _iterator7.length) break;
          _ref9 = _iterator7[_i7++];
        } else {
          _i7 = _iterator7.next();
          if (_i7.done) break;
          _ref9 = _i7.value;
        }

        var a = _ref9;

        currentPath.push(a.id);
        addFoundActivities(a);
        currentPath.pop();
      }
      return map;
    };

    this.feedResponseToReactionIdToPaths = function (response) {
      var previous = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      var map = previous;
      var currentPath = [];
      function addFoundReactions(obj) {
        if (Array.isArray(obj)) {
          obj.forEach(function (v, i) {
            currentPath.push(i);
            addFoundReactions(v);
            currentPath.pop();
          });
        } else if ((0, _isPlainObject2.default)(obj)) {
          if (obj.id && obj.kind && obj.data) {
            if (!map[obj.id]) {
              map[obj.id] = [];
            }
            map[obj.id].push([].concat(currentPath));
          }
          for (var k in obj) {
            currentPath.push(k);
            addFoundReactions(obj[k]);
            currentPath.pop();
          }
        }
      }

      for (var _iterator8 = response.results, _isArray8 = Array.isArray(_iterator8), _i8 = 0, _iterator8 = _isArray8 ? _iterator8 : _iterator8[typeof Symbol === 'function' ? typeof Symbol === 'function' ? Symbol.iterator : '@@iterator' : '@@iterator']();;) {
        var _ref10;

        if (_isArray8) {
          if (_i8 >= _iterator8.length) break;
          _ref10 = _iterator8[_i8++];
        } else {
          _i8 = _iterator8.next();
          if (_i8.done) break;
          _ref10 = _i8.value;
        }

        var a = _ref10;

        currentPath.push(a.id);
        addFoundReactions(a);
        currentPath.pop();
      }
      return map;
    };

    this.reactionResponseToReactionIdToPaths = function (response, previous, basePath, oldLength) {
      var map = previous;
      var currentPath = [].concat(_toConsumableArray(basePath));
      function addFoundReactions(obj) {
        if (Array.isArray(obj)) {
          obj.forEach(function (v, i) {
            currentPath.push(i);
            addFoundReactions(v);
            currentPath.pop();
          });
        } else if ((0, _isPlainObject2.default)(obj)) {
          if (obj.id && obj.kind && obj.data) {
            if (!map[obj.id]) {
              map[obj.id] = [];
            }
            map[obj.id].push([].concat(_toConsumableArray(currentPath)));
          }
          for (var k in obj) {
            currentPath.push(k);
            addFoundReactions(obj[k]);
            currentPath.pop();
          }
        }
      }

      for (var _iterator9 = response.results, _isArray9 = Array.isArray(_iterator9), _i9 = 0, _iterator9 = _isArray9 ? _iterator9 : _iterator9[typeof Symbol === 'function' ? typeof Symbol === 'function' ? Symbol.iterator : '@@iterator' : '@@iterator']();;) {
        var _ref11;

        if (_isArray9) {
          if (_i9 >= _iterator9.length) break;
          _ref11 = _iterator9[_i9++];
        } else {
          _i9 = _iterator9.next();
          if (_i9.done) break;
          _ref11 = _i9.value;
        }

        var a = _ref11;

        currentPath.push(oldLength);
        addFoundReactions(a);
        currentPath.pop();
        oldLength++;
      }
      return map;
    };

    this.removeFoundReactionIdPaths = function (data, previous, basePath) {
      var map = previous;
      var currentPath = [].concat(_toConsumableArray(basePath));
      function removeFoundReactions(obj) {
        if (Array.isArray(obj)) {
          obj.forEach(function (v, i) {
            currentPath.push(i);
            removeFoundReactions(v);
            currentPath.pop();
          });
        } else if ((0, _isPlainObject2.default)(obj)) {
          if (obj.id && obj.kind && obj.data) {
            if (!map[obj.id]) {
              map[obj.id] = [];
            }
            _lodash2.default.remove(map[obj.id], function (path) {
              return _lodash2.default.isEqual(path, currentPath);
            });
          }
          for (var k in obj) {
            currentPath.push(k);
            removeFoundReactions(obj[k]);
            currentPath.pop();
          }
        }
      }

      removeFoundReactions(data);
      return map;
    };

    this.removeFoundActivityIdPaths = function (data, previous, basePath) {
      var map = previous;
      var currentPath = [].concat(_toConsumableArray(basePath));
      function addFoundActivities(obj) {
        if (Array.isArray(obj)) {
          obj.forEach(function (v, i) {
            currentPath.push(i);
            addFoundActivities(v);
            currentPath.pop();
          });
        } else if ((0, _isPlainObject2.default)(obj)) {
          if (obj.id && obj.actor && obj.verb && obj.object) {
            if (!map[obj.id]) {
              map[obj.id] = [];
            }
            _lodash2.default.remove(map[obj.id], function (path) {
              return _lodash2.default.isEqual(path, currentPath);
            });
          }
          for (var k in obj) {
            currentPath.push(k);
            addFoundActivities(obj[k]);
            currentPath.pop();
          }
        }
      }

      addFoundActivities(data);
      return map;
    };

    this.removeFoundActivityIdPath = function (data, previous, basePath) {
      var map = previous;
      var currentPath = [].concat(_toConsumableArray(basePath));
      data.forEach(function (obj, i) {
        currentPath.push(i);
        if (_lodash2.default.isEqual(map[obj.id], currentPath)) {
          delete map[obj.id];
        }
        currentPath.pop();
      });
      return map;
    };

    this.addFoundReactionIdPaths = function (data, previous, basePath) {
      var map = previous;
      var currentPath = [].concat(_toConsumableArray(basePath));
      function addFoundReactions(obj) {
        if (Array.isArray(obj)) {
          obj.forEach(function (v, i) {
            currentPath.push(i);
            addFoundReactions(v);
            currentPath.pop();
          });
        } else if ((0, _isPlainObject2.default)(obj)) {
          if (obj.id && obj.kind && obj.data) {
            if (!map[obj.id]) {
              map[obj.id] = [];
            }
            map[obj.id].push([].concat(_toConsumableArray(currentPath)));
          }
          for (var k in obj) {
            currentPath.push(k);
            addFoundReactions(obj[k]);
            currentPath.pop();
          }
        }
      }

      addFoundReactions(data);
      return map;
    };

    this.addFoundActivityIdPaths = function (data, previous, basePath) {
      var map = previous;
      var currentPath = [].concat(_toConsumableArray(basePath));
      function addFoundActivities(obj) {
        if (Array.isArray(obj)) {
          obj.forEach(function (v, i) {
            currentPath.push(i);
            addFoundActivities(v);
            currentPath.pop();
          });
        } else if ((0, _isPlainObject2.default)(obj)) {
          if (obj.id && obj.actor && obj.verb && obj.object) {
            if (!map[obj.id]) {
              map[obj.id] = [];
            }
            map[obj.id].push([].concat(_toConsumableArray(currentPath)));
          }
          for (var k in obj) {
            currentPath.push(k);
            addFoundActivities(obj[k]);
            currentPath.pop();
          }
        }
      }
      addFoundActivities(data);
      return map;
    };

    this.addFoundActivityIdPath = function (data, previous, basePath) {
      var map = previous;
      data.forEach(function (obj, i) {
        map[obj.id] = [].concat(_toConsumableArray(basePath), [i]);
      });
      return map;
    };

    this.responseToReactionActivities = function (response) {
      if (response.results.length === 0) {
        return {};
      }
      var map = {};
      function setReactionActivities(activities) {
        for (var _iterator10 = activities, _isArray10 = Array.isArray(_iterator10), _i10 = 0, _iterator10 = _isArray10 ? _iterator10 : _iterator10[typeof Symbol === 'function' ? typeof Symbol === 'function' ? Symbol.iterator : '@@iterator' : '@@iterator']();;) {
          var _ref12;

          if (_isArray10) {
            if (_i10 >= _iterator10.length) break;
            _ref12 = _iterator10[_i10++];
          } else {
            _i10 = _iterator10.next();
            if (_i10.done) break;
            _ref12 = _i10.value;
          }

          var a = _ref12;

          if (a.reaction && a.reaction.id) {
            map[a.reaction.id] = a.id;
          }
        }
      }

      if (response.results[0].activities === undefined) {
        setReactionActivities(response.results);
      } else {
        var aggregatedResponse = response;

        for (var _iterator11 = aggregatedResponse.results, _isArray11 = Array.isArray(_iterator11), _i11 = 0, _iterator11 = _isArray11 ? _iterator11 : _iterator11[typeof Symbol === 'function' ? typeof Symbol === 'function' ? Symbol.iterator : '@@iterator' : '@@iterator']();;) {
          var _ref13;

          if (_isArray11) {
            if (_i11 >= _iterator11.length) break;
            _ref13 = _iterator11[_i11++];
          } else {
            _i11 = _iterator11.next();
            if (_i11.done) break;
            _ref13 = _i11.value;
          }

          var _group2 = _ref13;

          setReactionActivities(_group2.activities);
        }
      }
      return map;
    };

    this.refresh = function _callee10(extraOptions) {
      var options, response, newState;
      return regeneratorRuntime.async(function _callee10$(_context10) {
        while (1) {
          switch (_context10.prev = _context10.next) {
            case 0:
              options = _this.getOptions(extraOptions);
              _context10.next = 3;
              return regeneratorRuntime.awrap(_this.setState({ refreshing: true }));

            case 3:
              response = void 0;
              _context10.prev = 4;
              _context10.next = 7;
              return regeneratorRuntime.awrap(_this.doFeedRequest(options));

            case 7:
              response = _context10.sent;
              _context10.next = 15;
              break;

            case 10:
              _context10.prev = 10;
              _context10.t0 = _context10['catch'](4);

              _this.setState({ refreshing: false });
              _this.props.errorHandler(_context10.t0, 'get-feed', {
                feedGroup: _this.props.feedGroup,
                userId: _this.props.userId
              });
              return _context10.abrupt('return');

            case 15:
              newState = _extends({
                activityOrder: response.results.map(function (a) {
                  return a.id;
                }),
                activities: _this.responseToActivityMap(response),
                activityIdToPath: _this.responseToActivityIdToPath(response),
                activityIdToPaths: _this.responseToActivityIdToPaths(response),
                reactionIdToPaths: _this.feedResponseToReactionIdToPaths(response),
                reactionActivities: _this.responseToReactionActivities(response),
                refreshing: false,
                lastResponse: response,
                realtimeAdds: [],
                realtimeDeletes: []
              }, _this.unseenUnreadFromResponse(response));


              if (options.mark_seen === true) {
                newState.unseen = 0;
              }
              if (options.mark_read === true) {
                newState.unread = 0;
              }

              return _context10.abrupt('return', _this.setState(newState));

            case 19:
            case 'end':
              return _context10.stop();
          }
        }
      }, null, _this, [[4, 10]]);
    };

    this.subscribe = function _callee11() {
      var feed;
      return regeneratorRuntime.async(function _callee11$(_context11) {
        while (1) {
          switch (_context11.prev = _context11.next) {
            case 0:
              if (!_this.props.notify) {
                _context11.next = 4;
                break;
              }

              feed = _this.feed();
              _context11.next = 4;
              return regeneratorRuntime.awrap(_this.setState(function (prevState) {
                if (prevState.subscription) {
                  return {};
                }
                var subscription = feed.subscribe(function (data) {
                  _this.setState(function (prevState) {
                    var numActivityDiff = data.new.length - data.deleted.length;
                    return {
                      realtimeAdds: prevState.realtimeAdds.concat(data.new),
                      realtimeDeletes: prevState.realtimeDeletes.concat(data.deleted),
                      unread: prevState.unread + numActivityDiff,
                      unseen: prevState.unseen + numActivityDiff
                    };
                  });
                });

                subscription.then(function () {
                  console.log('now listening to changes in realtime for ' + _this.feed().id);
                }, function (err) {
                  console.error(err);
                });
                return { subscription: subscription };
              }));

            case 4:
            case 'end':
              return _context11.stop();
          }
        }
      }, null, _this);
    };

    this.unsubscribe = function _callee12() {
      var subscription;
      return regeneratorRuntime.async(function _callee12$(_context12) {
        while (1) {
          switch (_context12.prev = _context12.next) {
            case 0:
              subscription = _this.state.subscription;

              if (subscription) {
                _context12.next = 3;
                break;
              }

              return _context12.abrupt('return');

            case 3:
              _context12.next = 5;
              return regeneratorRuntime.awrap(subscription);

            case 5:
              if (!(_this.registeredCallbacks.length === 0)) {
                _context12.next = 15;
                break;
              }

              _context12.prev = 6;
              _context12.next = 9;
              return regeneratorRuntime.awrap(subscription.cancel());

            case 9:
              console.log('stopped listening to changes in realtime for ' + _this.feed().id);
              _context12.next = 15;
              break;

            case 12:
              _context12.prev = 12;
              _context12.t0 = _context12['catch'](6);

              console.error(_context12.t0);

            case 15:
            case 'end':
              return _context12.stop();
          }
        }
      }, null, _this, [[6, 12]]);
    };

    this.hasNextPage = function () {
      var lastResponse = _this.state.lastResponse;
      return Boolean(lastResponse && lastResponse.next);
    };

    this.hasReverseNextPage = function () {
      var lastReverseResponse = _this.state.lastReverseResponse;

      return Boolean(lastReverseResponse && lastReverseResponse.next);
    };

    this.loadNextPage = function _callee13() {
      var lastResponse, cancel, nextURL, options, response;
      return regeneratorRuntime.async(function _callee13$(_context13) {
        while (1) {
          switch (_context13.prev = _context13.next) {
            case 0:
              lastResponse = _this.state.lastResponse;

              if (!(!lastResponse || !lastResponse.next)) {
                _context13.next = 3;
                break;
              }

              return _context13.abrupt('return');

            case 3:
              cancel = false;
              _context13.next = 6;
              return regeneratorRuntime.awrap(_this.setState(function (prevState) {
                if (prevState.refreshing) {
                  cancel = true;
                  return {};
                }
                return { refreshing: true };
              }));

            case 6:
              if (!cancel) {
                _context13.next = 8;
                break;
              }

              return _context13.abrupt('return');

            case 8:
              nextURL = new _urlParse2.default(lastResponse.next, true);
              options = _this.getOptions(nextURL.query);
              response = void 0;
              _context13.prev = 11;
              _context13.next = 14;
              return regeneratorRuntime.awrap(_this.doFeedRequest(options));

            case 14:
              response = _context13.sent;
              _context13.next = 22;
              break;

            case 17:
              _context13.prev = 17;
              _context13.t0 = _context13['catch'](11);

              _this.setState({ refreshing: false });
              _this.props.errorHandler(_context13.t0, 'get-feed-next-page', {
                feedGroup: _this.props.feedGroup,
                userId: _this.props.userId
              });
              return _context13.abrupt('return');

            case 22:
              return _context13.abrupt('return', _this.setState(function (prevState) {
                var activities = prevState.activities.merge(_this.responseToActivityMap(response));
                var activityIdToPath = _extends({}, prevState.activityIdToPath, _this.responseToActivityIdToPath(response));
                return {
                  activityOrder: prevState.activityOrder.concat(response.results.map(function (a) {
                    return a.id;
                  })),
                  activities: activities,
                  activityIdToPath: activityIdToPath,
                  activityIdToPaths: _this.responseToActivityIdToPaths(response, prevState.activityIdToPaths),
                  reactionIdToPaths: _this.feedResponseToReactionIdToPaths(response, prevState.reactionIdToPaths),
                  reactionActivities: _extends({}, prevState.reactionActivities, _this.responseToReactionActivities(response)),
                  refreshing: false,
                  lastResponse: response
                };
              }));

            case 23:
            case 'end':
              return _context13.stop();
          }
        }
      }, null, _this, [[11, 17]]);
    };

    this.loadReverseNextPage = function _callee14() {
      var lastReverseResponse, cancel, nextURL, options, response;
      return regeneratorRuntime.async(function _callee14$(_context14) {
        while (1) {
          switch (_context14.prev = _context14.next) {
            case 0:
              lastReverseResponse = _this.state.lastReverseResponse;

              if (!(!lastReverseResponse || !lastReverseResponse.next)) {
                _context14.next = 3;
                break;
              }

              return _context14.abrupt('return');

            case 3:
              cancel = false;
              _context14.next = 6;
              return regeneratorRuntime.awrap(_this.setState(function (prevState) {
                if (prevState.refreshing) {
                  cancel = true;
                  return {};
                }
                return { refreshing: true };
              }));

            case 6:
              if (!cancel) {
                _context14.next = 8;
                break;
              }

              return _context14.abrupt('return');

            case 8:
              nextURL = new _urlParse2.default(lastReverseResponse.next, true);
              options = _this.getOptions(nextURL.query);
              response = void 0;
              _context14.prev = 11;
              _context14.next = 14;
              return regeneratorRuntime.awrap(_this.doFeedRequest(options));

            case 14:
              response = _context14.sent;
              _context14.next = 22;
              break;

            case 17:
              _context14.prev = 17;
              _context14.t0 = _context14['catch'](11);

              _this.setState({ refreshing: false });
              _this.props.errorHandler(_context14.t0, 'get-feed-next-page', {
                feedGroup: _this.props.feedGroup,
                userId: _this.props.userId
              });
              return _context14.abrupt('return');

            case 22:
              return _context14.abrupt('return', _this.setState(function (prevState) {
                var activities = prevState.activities.merge(_this.responseToActivityMap(response));
                var activityIdToPath = _extends({}, prevState.activityIdToPath, _this.responseToActivityIdToPath(response));
                return {
                  activityOrder: response.results.map(function (a) {
                    return a.id;
                  }).concat(prevState.activityOrder),
                  activities: activities,
                  activityIdToPath: activityIdToPath,
                  activityIdToPaths: _this.responseToActivityIdToPaths(response, prevState.activityIdToPaths),
                  reactionIdToPaths: _this.feedResponseToReactionIdToPaths(response, prevState.reactionIdToPaths),
                  reactionActivities: _extends({}, prevState.reactionActivities, _this.responseToReactionActivities(response)),
                  refreshing: false,
                  lastReverseResponse: response
                };
              }));

            case 23:
            case 'end':
              return _context14.stop();
          }
        }
      }, null, _this, [[11, 17]]);
    };

    this.loadNextReactions = function _callee15(activityId, kind, activityPath, oldestToNewest) {
      var options, orderPrefix, latestReactionsPath, nextUrlPath, refreshingPath, reactions_extra, nextUrl, refreshing, response;
      return regeneratorRuntime.async(function _callee15$(_context15) {
        while (1) {
          switch (_context15.prev = _context15.next) {
            case 0:
              options = {
                activity_id: activityId,
                kind: kind
              };
              orderPrefix = 'latest';

              if (oldestToNewest) {
                orderPrefix = 'oldest';
              }

              if (!activityPath) {
                activityPath = _this.getActivityPath(activityId);
              }
              latestReactionsPath = [].concat(_toConsumableArray(activityPath), [orderPrefix + '_reactions', kind]);
              nextUrlPath = [].concat(_toConsumableArray(activityPath), [orderPrefix + '_reactions_extra', kind, 'next']);
              refreshingPath = [].concat(_toConsumableArray(activityPath), [orderPrefix + '_reactions_extra', kind, 'refreshing']);
              reactions_extra = _this.state.activities.getIn([].concat(_toConsumableArray(activityPath), [orderPrefix + '_reactions_extra']));
              nextUrl = 'https://api.stream-io-api.com/';

              if (reactions_extra) {
                nextUrl = reactions_extra.getIn([kind, 'next'], '');
              } else if (oldestToNewest) {
                options.id_gt = 'non-existant-' + (0, _utils.generateRandomId)();
              }

              refreshing = _this.state.activities.getIn(refreshingPath, false);

              if (!(!nextUrl || refreshing)) {
                _context15.next = 13;
                break;
              }

              return _context15.abrupt('return');

            case 13:

              _this.setState(function (prevState) {
                return {
                  activities: prevState.activities.setIn(refreshingPath, true)
                };
              });

              options = _extends({}, (0, _urlParse2.default)(nextUrl, true).query, options);

              response = void 0;
              _context15.prev = 16;

              if (!_this.props.doReactionsFilterRequest) {
                _context15.next = 23;
                break;
              }

              _context15.next = 20;
              return regeneratorRuntime.awrap(_this.props.doReactionsFilterRequest(options));

            case 20:
              response = _context15.sent;
              _context15.next = 26;
              break;

            case 23:
              _context15.next = 25;
              return regeneratorRuntime.awrap(_this.props.client.reactions.filter(options));

            case 25:
              response = _context15.sent;

            case 26:
              _context15.next = 33;
              break;

            case 28:
              _context15.prev = 28;
              _context15.t0 = _context15['catch'](16);

              _this.setState({ refreshing: false });
              _this.props.errorHandler(_context15.t0, 'get-reactions-next-page', {
                options: options
              });
              return _context15.abrupt('return');

            case 33:
              _this.setState(function (prevState) {
                return {
                  activities: prevState.activities.setIn(refreshingPath, false).setIn(nextUrlPath, response.next).updateIn(latestReactionsPath, function () {
                    var v = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _immutable2.default.List();
                    return v.concat(_immutable2.default.fromJS(response.results));
                  }),
                  reactionIdToPaths: _this.reactionResponseToReactionIdToPaths(response, prevState.reactionIdToPaths, latestReactionsPath, prevState.activities.getIn(latestReactionsPath, _immutable2.default.List()).toJS().length)
                };
              });

            case 34:
            case 'end':
              return _context15.stop();
          }
        }
      }, null, _this, [[16, 28]]);
    };

    this.refreshUnreadUnseen = function _callee16() {
      var response;
      return regeneratorRuntime.async(function _callee16$(_context16) {
        while (1) {
          switch (_context16.prev = _context16.next) {
            case 0:
              response = void 0;
              _context16.prev = 1;
              _context16.next = 4;
              return regeneratorRuntime.awrap(_this.doFeedRequest({ limit: 0 }));

            case 4:
              response = _context16.sent;
              _context16.next = 11;
              break;

            case 7:
              _context16.prev = 7;
              _context16.t0 = _context16['catch'](1);

              _this.props.errorHandler(_context16.t0, 'get-notification-counts', {
                feedGroup: _this.props.feedGroup,
                userId: _this.props.userId
              });
              return _context16.abrupt('return');

            case 11:
              return _context16.abrupt('return', _this.setState(_this.unseenUnreadFromResponse(response)));

            case 12:
            case 'end':
              return _context16.stop();
          }
        }
      }, null, _this, [[1, 7]]);
    };

    this.props = props;
    var initialOptions = this.getOptions();
    this.registeredCallbacks = [];
    var previousUrl = '';
    if (initialOptions.id_gte) {
      previousUrl = '?id_lt=' + initialOptions.id_gte;
    } else if (initialOptions.id_gt) {
      previousUrl = '?id_lte=' + initialOptions.id_gt;
    } else if (initialOptions.id_lte) {
      previousUrl = '?id_gt=' + initialOptions.id_lte;
    } else if (initialOptions.id_lt) {
      previousUrl = '?id_gte=' + initialOptions.id_lt;
    }
    this.state.lastReverseResponse = { next: previousUrl };
  }

  _createClass(FeedManager, [{
    key: 'register',
    value: function register(callback) {
      this.registeredCallbacks.push(callback);
      this.subscribe();
    }
  }, {
    key: 'unregister',
    value: function unregister(callback) {
      this.registeredCallbacks.splice(this.registeredCallbacks.indexOf(callback));
      this.unsubscribe();
    }
  }, {
    key: 'triggerUpdate',
    value: function triggerUpdate() {
      for (var _iterator12 = this.registeredCallbacks, _isArray12 = Array.isArray(_iterator12), _i12 = 0, _iterator12 = _isArray12 ? _iterator12 : _iterator12[typeof Symbol === 'function' ? Symbol.iterator : '@@iterator']();;) {
        var _ref14;

        if (_isArray12) {
          if (_i12 >= _iterator12.length) break;
          _ref14 = _iterator12[_i12++];
        } else {
          _i12 = _iterator12.next();
          if (_i12.done) break;
          _ref14 = _i12.value;
        }

        var callback = _ref14;

        callback();
      }
    }
  }, {
    key: 'unseenUnreadFromResponse',
    value: function unseenUnreadFromResponse(response) {
      var unseen = 0;
      var unread = 0;
      if (typeof response.unseen === 'number') {
        unseen = response.unseen;
      }
      if (typeof response.unread === 'number') {
        unread = response.unread;
      }
      return { unseen: unseen, unread: unread };
    }
  }]);

  return FeedManager;
}();

var Feed = exports.Feed = function (_React$Component) {
  _inherits(Feed, _React$Component);

  function Feed() {
    var _ref15;

    var _temp, _this2, _ret2;

    _classCallCheck(this, Feed);

    for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    return _ret2 = (_temp = (_this2 = _possibleConstructorReturn(this, (_ref15 = Feed.__proto__ || Object.getPrototypeOf(Feed)).call.apply(_ref15, [this].concat(args))), _this2), _this2._appCtxWrapperFunc = function (appCtx) {
      return React.createElement(FeedInner, _extends({}, _this2.props, appCtx, {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 1451
        }
      }));
    }, _temp), _possibleConstructorReturn(_this2, _ret2);
  }

  _createClass(Feed, [{
    key: 'render',
    value: function render() {
      return React.createElement(
        _StreamApp.StreamApp.Consumer,
        {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 1455
          }
        },
        this._appCtxWrapperFunc
      );
    }
  }]);

  return Feed;
}(React.Component);

var FeedInner = function (_React$Component2) {
  _inherits(FeedInner, _React$Component2);

  function FeedInner(props) {
    _classCallCheck(this, FeedInner);

    var _this3 = _possibleConstructorReturn(this, (FeedInner.__proto__ || Object.getPrototypeOf(FeedInner)).call(this, props));

    _initialiseProps.call(_this3);

    var feedId = props.client.feed(props.feedGroup, props.userId).id;
    var manager = props.sharedFeedManagers[feedId];
    if (!manager) {
      manager = new FeedManager(props);
    }

    _this3.state = {
      manager: manager
    };
    return _this3;
  }

  _createClass(FeedInner, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      return this.state.manager.register(this.boundForceUpdate);
    }
  }, {
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps) {
      var clientDifferent = this.props.client !== prevProps.client;
      var notifyDifferent = this.props.notify !== prevProps.notify;
      var feedDifferent = this.props.userId !== prevProps.userId || this.props.feedGroup !== prevProps.feedGroup;
      var optionsDifferent = !_lodash2.default.isEqual(this.props.options, prevProps.options);
      var doFeedRequestDifferent = this.props.doFeedRequest !== prevProps.doFeedRequest;

      if (clientDifferent || feedDifferent || optionsDifferent || doFeedRequestDifferent) {}
      if (clientDifferent || feedDifferent || notifyDifferent) {}
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      return this.state.manager.unregister(this.boundForceUpdate);
    }
  }, {
    key: 'render',
    value: function render() {
      return React.createElement(
        FeedContext.Provider,
        { value: this.getCtx(), __source: {
            fileName: _jsxFileName,
            lineNumber: 1542
          }
        },
        this.props.children
      );
    }
  }]);

  return FeedInner;
}(React.Component);

var _initialiseProps = function _initialiseProps() {
  var _this4 = this;

  this.boundForceUpdate = function () {
    return _this4.forceUpdate();
  };

  this.getCtx = function () {
    var manager = _this4.state.manager;

    var state = manager.state;
    return {
      getActivityPath: manager.getActivityPath,
      onToggleReaction: manager.onToggleReaction,
      onAddReaction: manager.onAddReaction,
      onRemoveReaction: manager.onRemoveReaction,
      onToggleChildReaction: manager.onToggleChildReaction,
      onAddChildReaction: manager.onAddChildReaction,
      onRemoveChildReaction: manager.onRemoveChildReaction,
      onRemoveActivity: manager.onRemoveActivity,
      onMarkAsRead: manager.onMarkAsRead,
      onMarkAsSeen: manager.onMarkAsSeen,
      hasDoneRequest: state.lastResponse != null,
      refresh: manager.refresh,
      refreshUnreadUnseen: manager.refreshUnreadUnseen,
      loadNextReactions: manager.loadNextReactions,
      loadNextPage: manager.loadNextPage,
      hasNextPage: manager.hasNextPage(),
      loadReverseNextPage: manager.loadReverseNextPage,
      hasReverseNextPage: manager.hasReverseNextPage(),
      feedGroup: _this4.props.feedGroup,
      userId: _this4.props.userId,
      activityOrder: state.activityOrder,
      activities: state.activities,
      realtimeAdds: state.realtimeAdds,
      realtimeDeletes: state.realtimeDeletes,
      refreshing: state.refreshing,
      unread: state.unread,
      unseen: state.unseen
    };
  };
};