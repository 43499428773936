"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Registry_1 = require("./Registry");
/**
 * 🎛 ConfigRegistry
 */
class ConfigRegistry extends Registry_1.Registry {
    /**
     * The set() method adds or updates an element with a specified
     * key and item to the registry.
     * @param key
     * @param value
     */
    set(key, item) {
        super.set(key, item);
        const value = this.getValue(key);
        this.BB.Filters.run('bluebase.configs.set', { key, value });
        return this;
    }
    async registerIfNotExists(key, item) {
        const args = this.getKeyAnyItem(key, item);
        if (!this.has(args.key)) {
            return this.register(args.key, args.item);
        }
    }
    /**
     * Registers all collection items that aren't already registered.
     * @param collection
     */
    async registerCollectionIfNotExists(collection = []) {
        const keys = [];
        // If its an array
        if (Array.isArray(collection)) {
            for (const item of collection) {
                const key = await this.registerIfNotExists(item);
                if (key) {
                    keys.push(key);
                }
            }
            return keys;
        }
        // If its an object
        else if (collection === Object(collection)) {
            for (const key of Object.keys(collection)) {
                await this.registerIfNotExists(key, collection[key]);
                keys.push(key);
            }
            return keys;
        }
        throw Error('Could not register collection. Reason: Unknown collection type.');
    }
}
exports.ConfigRegistry = ConfigRegistry;
