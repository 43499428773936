Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _jsxFileName = 'src/components/IconBadge.js';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _reactNative = require('react-native');

var _Context = require('../Context');

var _styles = require('../styles');

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var IconBadge = function (_React$Component) {
  _inherits(IconBadge, _React$Component);

  function IconBadge() {
    _classCallCheck(this, IconBadge);

    return _possibleConstructorReturn(this, (IconBadge.__proto__ || Object.getPrototypeOf(IconBadge)).apply(this, arguments));
  }

  _createClass(IconBadge, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      return React.createElement(
        _Context.Feed,
        { feedGroup: this.props.feedGroup, userId: this.props.userId, notify: true, __source: {
            fileName: _jsxFileName,
            lineNumber: 37
          }
        },
        React.createElement(
          _Context.FeedContext.Consumer,
          {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 38
            }
          },
          function (feedCtx) {
            return React.createElement(IconBadgeInner, _extends({}, _this2.props, feedCtx, {
              __source: {
                fileName: _jsxFileName,
                lineNumber: 39
              }
            }));
          }
        )
      );
    }
  }]);

  return IconBadge;
}(React.Component);

IconBadge.defaultProps = {
  feedGroup: 'notification',
  showNumber: false
};
exports.default = IconBadge;

var IconBadgeInner = function (_React$Component2) {
  _inherits(IconBadgeInner, _React$Component2);

  function IconBadgeInner() {
    _classCallCheck(this, IconBadgeInner);

    return _possibleConstructorReturn(this, (IconBadgeInner.__proto__ || Object.getPrototypeOf(IconBadgeInner)).apply(this, arguments));
  }

  _createClass(IconBadgeInner, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      return regeneratorRuntime.async(function componentDidMount$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return regeneratorRuntime.awrap(this.props.refreshUnreadUnseen());

            case 2:
            case 'end':
              return _context.stop();
          }
        }
      }, null, this);
    }
  }, {
    key: 'render',
    value: function render() {
      var _props = this.props,
          children = _props.children,
          showNumber = _props.showNumber,
          hidden = _props.hidden;


      var styles = (0, _styles.buildStylesheet)('iconBadge', this.props.styles);

      return React.createElement(
        _reactNative.View,
        { style: styles.container, __source: {
            fileName: _jsxFileName,
            lineNumber: 57
          }
        },
        children,
        !hidden && this.props.unseen > 0 && React.createElement(
          _reactNative.View,
          { style: styles.icon, __source: {
              fileName: _jsxFileName,
              lineNumber: 61
            }
          },
          React.createElement(
            _reactNative.View,
            { style: styles.iconInner, __source: {
                fileName: _jsxFileName,
                lineNumber: 62
              }
            },
            showNumber && React.createElement(
              _reactNative.Text,
              { style: styles.text, __source: {
                  fileName: _jsxFileName,
                  lineNumber: 64
                }
              },
              this.props.unseen
            )
          )
        )
      );
    }
  }]);

  return IconBadgeInner;
}(React.Component);