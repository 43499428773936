"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@bluebase/core");
exports.ListAvatarDefaultProps = {
    size: 40,
};
/**
 * 📚 ListAvatar
 *
 * A component to show tiles inside a List.
 */
exports.ListAvatar = core_1.getComponent('ListAvatar');
exports.ListAvatar.defaultProps = exports.ListAvatarDefaultProps;
