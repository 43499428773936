"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Iso8601Date = {
    key: 'Iso8601Date',
    dataType: 'DATE',
    /**
     * Transforms raw state value into an ISO Valid Date String
     *
     * Example: Mon Jul 08 2019 11:45:40 GMT+0500 (Pakistan Standard Time) => 2019-07-08T06:42:09.971Z
     */
    template: '{{ new Date(value).toISOString() }}',
};
