"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const navigators_1 = require("./navigators");
const components_1 = require("./components");
exports.BasePlugin = {
    description: 'Use React Router navigation in BlueBase apps!',
    key: '@bluebase/plugin-react-router',
    name: 'BlueBase React Router Plugin',
    version: '1.0.0',
    defaultConfigs: {
        /**
         * If enabled, navigation.source value is set with router value
         * from react-router's context.
         */
        'plugin.react-router.enableSourceInNavigationActions': true,
    },
    components: {
        DrawerView: navigators_1.DrawerView,
        Header: components_1.Header,
        HeaderBackButton: components_1.HeaderBackButton,
        HeaderTitle: components_1.HeaderTitle,
        ScreenView: navigators_1.ScreenView,
        TabBar: navigators_1.TabBar,
        TabView: navigators_1.TabView,
    },
};
