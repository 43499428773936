Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/SectionHeader.js';
exports.default = SectionHeader;

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _reactNative = require('react-native');

var _styles = require('../styles');

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SectionHeader(props) {
  var styles = (0, _styles.buildStylesheet)('sectionHeader', props.styles);
  return React.createElement(
    _reactNative.View,
    { style: styles.container, __source: {
        fileName: _jsxFileName,
        lineNumber: 19
      }
    },
    React.createElement(
      _reactNative.Text,
      { style: styles.label, __source: {
          fileName: _jsxFileName,
          lineNumber: 20
        }
      },
      props.children
    )
  );
}