"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const BlueBaseModuleRegistry_1 = require("./BlueBaseModuleRegistry");
const themes_1 = require("../themes");
const utils_1 = require("../utils");
/**
 * 🎨 ThemeRegistry
 */
class ThemeRegistry extends BlueBaseModuleRegistry_1.BlueBaseModuleRegistry {
    /**
     * Returns a Promise that resolves a Theme
     * @param keys
     */
    async resolve(...keys) {
        const item = this.findOne(...keys);
        if (!item) {
            throw Error(`Could not resolve any of the following themes: [${keys.join(', ')}].`);
        }
        const theme = Object.assign({}, item, { value: await item.value });
        const overrides = { value: this.BB.Configs.getValue('theme.overrides') || {} };
        // We pass through createTheme to make sure if theme has missed some rules,
        // they're provided
        return themes_1.buildTheme(item.mode)(theme, overrides);
    }
    /**
     * Get alternate version of current theme
     * @param key
     */
    async resolveAlternate(key) {
        const item = this.get(key);
        if (!item || !item.alternate) {
            throw Error(`Could not resolve alternate theme of "${key}".`);
        }
        return this.resolve(item.alternate);
    }
    /**
     * Convert any input value to an item. This is where you transform inputs and add defualts
     * @param key
     * @param partial
     */
    createItem(key, partial) {
        const value = utils_1.getDefiniteBlueBaseModule(partial.value);
        return super.createItem(key, Object.assign({ mode: 'light', name: 'Untitled Theme' }, partial, { value }));
    }
}
exports.ThemeRegistry = ThemeRegistry;
