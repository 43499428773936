"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@bluebase/core");
exports.TabDefaultProps = {
    disabled: false,
};
/**
 * # 🗂 Tab
 * A single tab component.
 */
exports.Tab = core_1.getComponent('Tab');
exports.Tab.defaultProps = exports.TabDefaultProps;
