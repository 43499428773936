"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function getTitle(options = {}) {
    return options.tabBarLabel || options.title || options.headerTitle;
}
exports.getTitle = getTitle;
function getIcon(options = {}) {
    const icon = options.tabBarIcon;
    if (!icon) {
        return;
    }
    if (typeof icon === 'function') {
        return icon();
    }
    if (icon && typeof icon.type === 'string') {
        return icon;
    }
}
exports.getIcon = getIcon;
