Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/UserBar.js';

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactNative = require('react-native');

var _utils = require('../utils');

var _Avatar = require('./Avatar');

var _Avatar2 = _interopRequireDefault(_Avatar);

var _FollowButton = require('./FollowButton');

var _FollowButton2 = _interopRequireDefault(_FollowButton);

var _styles = require('../styles');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var UserBar = function UserBar(_ref) {
  var username = _ref.username,
      subtitle = _ref.subtitle,
      avatar = _ref.avatar,
      follow = _ref.follow,
      onPressAvatar = _ref.onPressAvatar,
      icon = _ref.icon,
      props = _objectWithoutProperties(_ref, ['username', 'subtitle', 'avatar', 'follow', 'onPressAvatar', 'icon']);

  username = username || 'Unknown';
  var time = props.time;
  if (time === undefined && props.timestamp != null) {
    time = (0, _utils.humanizeTimestamp)(props.timestamp);
  }

  var styles = (0, _styles.buildStylesheet)('userBar', props.styles);

  return _react2.default.createElement(
    _reactNative.View,
    { style: styles.container, __source: {
        fileName: _jsxFileName,
        lineNumber: 46
      }
    },
    avatar ? _react2.default.createElement(
      _reactNative.TouchableOpacity,
      { onPress: onPressAvatar, disabled: !onPressAvatar, __source: {
          fileName: _jsxFileName,
          lineNumber: 48
        }
      },
      _react2.default.createElement(_Avatar2.default, {
        source: avatar,
        size: 48,
        noShadow: true,
        styles: styles && styles.avatar || { container: { marginRight: 10 } },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 49
        }
      })
    ) : null,
    _react2.default.createElement(
      _reactNative.View,
      { style: styles.content, __source: {
          fileName: _jsxFileName,
          lineNumber: 60
        }
      },
      _react2.default.createElement(
        _reactNative.Text,
        { style: styles.username, __source: {
            fileName: _jsxFileName,
            lineNumber: 61
          }
        },
        username
      ),
      _react2.default.createElement(
        _reactNative.View,
        { style: { flexDirection: 'row' }, __source: {
            fileName: _jsxFileName,
            lineNumber: 62
          }
        },
        icon !== undefined ? _react2.default.createElement(_reactNative.Image, {
          source: icon,
          style: { width: 24, height: 24, top: -2, marginRight: 5 },
          __source: {
            fileName: _jsxFileName,
            lineNumber: 64
          }
        }) : null,
        subtitle && _react2.default.createElement(
          _reactNative.Text,
          { style: styles.subtitle, __source: {
              fileName: _jsxFileName,
              lineNumber: 69
            }
          },
          subtitle
        )
      )
    ),
    time && _react2.default.createElement(
      _reactNative.View,
      {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 73
        }
      },
      _react2.default.createElement(
        _reactNative.Text,
        { style: styles.time, __source: {
            fileName: _jsxFileName,
            lineNumber: 74
          }
        },
        time
      )
    ),
    follow && _react2.default.createElement(
      _reactNative.View,
      {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 78
        }
      },
      _react2.default.createElement(_FollowButton2.default, { followed: true, __source: {
          fileName: _jsxFileName,
          lineNumber: 79
        }
      })
    )
  );
};

exports.default = UserBar;