"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
// tslint:disable: radix
const components_1 = require("@bluebase/components");
const react_1 = tslib_1.__importStar(require("react"));
const core_1 = require("@bluebase/core");
const isSupportOpen_1 = require("./isSupportOpen");
const LinkingSettingItem = core_1.getComponent('LinkingSettingItem');
exports.CallSupportSetting = ({ styles = {} }) => {
    const { __ } = core_1.useIntl();
    const [days] = core_1.useConfig('plugin.settings-app.support.call.days');
    const [opens] = core_1.useConfig('plugin.settings-app.support.call.opens');
    const [closes] = core_1.useConfig('plugin.settings-app.support.call.closes');
    const [phoneNumber] = core_1.useConfig('plugin.settings-app.support.call.number');
    const checkCanCall = () => isSupportOpen_1.isSupportOpen(new Date(), days, parseInt(opens), parseInt(closes));
    const [canCall, setCanCall] = react_1.useState(checkCanCall());
    // Update every minute
    react_1.useEffect(() => {
        const id = setInterval(() => {
            setCanCall(checkCanCall());
        }, 60000);
        return () => clearInterval(id);
    });
    return (react_1.default.createElement(LinkingSettingItem, { url: `tel:${phoneNumber}`, left: react_1.default.createElement(components_1.List.Icon, { name: "phone" }), right: canCall ? (react_1.default.createElement(components_1.Body2, { style: styles.callNowText, testID: "call-status" }, __('Call Now'))) : (react_1.default.createElement(components_1.Body2, { style: styles.closedText, testID: "call-status" }, __('Closed'))), title: __('Call'), description: phoneNumber, disabled: !canCall }));
};
exports.CallSupportSetting.defaultStyles = (theme) => ({
    callNowText: {
        color: theme.palette.success.main,
        textTransform: 'uppercase',
    },
    closedText: {
        color: theme.palette.error.main,
        textTransform: 'uppercase',
    },
});
