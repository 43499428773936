Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/CommentList.js';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _SectionHeader = require('./SectionHeader');

var _SectionHeader2 = _interopRequireDefault(_SectionHeader);

var _CommentItem = require('./CommentItem');

var _CommentItem2 = _interopRequireDefault(_CommentItem);

var _ReactionList = require('./ReactionList');

var _ReactionList2 = _interopRequireDefault(_ReactionList);

var _LoadMoreButton = require('./LoadMoreButton');

var _LoadMoreButton2 = _interopRequireDefault(_LoadMoreButton);

var _utils = require('../utils');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var CommentList = function (_React$PureComponent) {
  _inherits(CommentList, _React$PureComponent);

  function CommentList() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, CommentList);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = CommentList.__proto__ || Object.getPrototypeOf(CommentList)).call.apply(_ref, [this].concat(args))), _this), _this._Reaction = function (_ref2) {
      var reaction = _ref2.reaction;
      return (0, _utils.smartRender)(_this.props.CommentItem, { comment: reaction });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(CommentList, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          activityId = _props.activityId,
          activityPath = _props.activityPath,
          infiniteScroll = _props.infiniteScroll,
          oldestToNewest = _props.oldestToNewest,
          reverseOrder = _props.reverseOrder,
          flatListProps = _props.flatListProps,
          LoadMoreButton = _props.LoadMoreButton;

      return _react2.default.createElement(
        _ReactionList2.default,
        {
          activityId: activityId,
          reactionKind: 'comment',
          Reaction: this._Reaction,
          activityPath: activityPath,
          infiniteScroll: infiniteScroll,
          oldestToNewest: oldestToNewest,
          flatListProps: flatListProps,
          reverseOrder: reverseOrder,
          LoadMoreButton: LoadMoreButton,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 69
          }
        },
        _react2.default.createElement(
          _SectionHeader2.default,
          {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 80
            }
          },
          'Comments'
        )
      );
    }
  }]);

  return CommentList;
}(_react2.default.PureComponent);

CommentList.defaultProps = {
  CommentItem: _CommentItem2.default,
  LoadMoreButton: _LoadMoreButton2.default,
  infiniteScroll: false,
  oldestToNewest: false,
  reverseOrder: false
};
exports.default = CommentList;