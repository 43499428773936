"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * If an input is an array, returns as is. Otherwise creates a new array with input object at index 0 and returns it.
 * @param input Input object
 */
function getDefiniteArray(input) {
    return Array.isArray(input) ? input : [input];
}
exports.getDefiniteArray = getDefiniteArray;
