"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwitchState = {
    key: 'SwitchState',
    extends: 'Boolean',
    /**
     * Transforms raw state value to human readable string
     *
     * Example:
     * true => On
     * false => Off
     */
    template: '{{ value ? "On" : "Off" }}',
    values: [
        {
            label: 'On',
            value: true,
        },
        {
            label: 'Off',
            value: false,
        },
    ],
};
