"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const elevation_1 = require("./elevation");
function createTheme(palette, typography) {
    return {
        components: {},
        elevation: elevation_1.elevation,
        palette,
        shape: {
            borderRadius: 4,
        },
        spacing: {
            unit: 8,
        },
        typography,
    };
}
exports.createTheme = createTheme;
