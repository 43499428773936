"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const assets_1 = require("./assets");
const boot_1 = require("./boot");
const components_1 = require("./components");
const configs_1 = require("./configs");
const filters_1 = require("./filters");
const fonts_1 = require("./fonts");
const navigation_1 = require("./navigation");
const plugins_1 = require("./plugins");
const themes_1 = require("./themes");
exports.default = Object.assign({}, assets_1.assets, boot_1.boot, components_1.components, configs_1.configs, filters_1.filters, fonts_1.fonts, plugins_1.plugins, navigation_1.navigation, themes_1.themes);
