"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const device_controller_1 = require("@mevris/device-controller");
// tslint:disable: radix
exports.AMBIENT_TEMPERATURE_DIVIDER_V2 = 10;
exports.AMBIENT_TEMPERATURE_DIVIDER_V3 = 1;
exports.CURRENT_DIVIDER_V2 = 100000;
exports.CURRENT_DIVIDER_V3 = 1000;
exports.POWER_DIVIDER_V2 = 100000;
exports.POWER_DIVIDER_V3 = 1;
exports.VOLTAGE_DIVIDER_V2 = 10000;
exports.VOLTAGE_DIVIDER_V3 = 1;
/**
 * Device version
 */
exports.V1 = 1;
exports.V2 = 2;
exports.V3 = 3;
/**
 * Returns dividers based on device version
 * @param version
 */
function getDividers(version) {
    if (version !== exports.V3) {
        return {
            ambientTemperature: exports.AMBIENT_TEMPERATURE_DIVIDER_V2,
            current: exports.CURRENT_DIVIDER_V2,
            power: exports.POWER_DIVIDER_V2,
            voltage: exports.VOLTAGE_DIVIDER_V2,
        };
    }
    return {
        ambientTemperature: exports.AMBIENT_TEMPERATURE_DIVIDER_V3,
        current: exports.CURRENT_DIVIDER_V3,
        power: exports.POWER_DIVIDER_V3,
        voltage: exports.VOLTAGE_DIVIDER_V3,
    };
}
exports.getDividers = getDividers;
/**
 * Converts device response current to number
 * @param current
 * @param version device version
 */
function responseToAmbientTemperature(ambientTemperature, version) {
    const dividers = getDividers(version);
    const num = parseInt(ambientTemperature) / dividers.ambientTemperature;
    return Math.round(num * 100) / 100;
}
/**
 * Converts device response current to number
 * @param current
 * @param version device version
 */
function responseToCurrent(current, version) {
    const dividers = getDividers(version);
    const num = parseInt(current) / dividers.current;
    return Math.round(num * 100) / 100;
}
/**
 * Converts device response voltage to number
 * @param voltage
 * @param version device version
 */
function responseToVoltage(voltage, version) {
    const dividers = getDividers(version);
    const num = parseInt(voltage) / dividers.voltage;
    return Math.round(num);
}
/**
 * Converts device response power to number
 * @param power
 * @param version device version
 */
function responseToPower(power, version) {
    const dividers = getDividers(version);
    const num = parseInt(power) / dividers.power;
    return Math.round(num);
}
/**
 * Converts a device response to state object
 * @param response response from device api
 * @param version device version
 */
function mapResponseToState(response, version = exports.V3) {
    const state = {
        temperature: responseToAmbientTemperature(response.ambientTemperature, version),
        connected: response.connected === '1' || response.connected === 1,
        current: responseToCurrent(response.current, version),
        power: responseToPower(response.power, version),
        switch: !(response.standby === '1'),
        'thermostat-setpoint': parseInt(response.thermostatSetpoint),
        voltage: responseToVoltage(response.voltage, version),
        'fan-speed': response.thermostatFanMode,
        'thermostat-mode': response.thermostatMode,
        'wifi-ssid': response.SSID,
    };
    return state;
}
exports.mapResponseToState = mapResponseToState;
function mapStandardResponse(status, response) {
    const resp = {};
    if (status === device_controller_1.RESPONSE_STATUS_SUCCESS) {
        resp.data = response;
    }
    else if (status === device_controller_1.RESPONSE_STATUS_ERROR) {
        resp.error = new Error(response);
    }
    resp.status = status;
    return resp;
}
exports.mapStandardResponse = mapStandardResponse;
