"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const components_1 = require("./components");
const core_1 = require("@bluebase/core");
exports.default = core_1.createPlugin({
    description: 'Provides Grid components to create Responsive Layouts',
    key: 'plugin-responsive-grid',
    name: 'BlueBase Responsive Grid',
    version: '1.0.0',
    components: {
        Column: components_1.Column,
        Container: components_1.Container,
        OrientationObserver: components_1.OrientationObserver,
        ResponsiveLayout: components_1.ResponsiveLayout,
        Row: components_1.Row,
        ScreenSizeObserver: components_1.ScreenSizeObserver,
    },
});
