"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.components = {
    AvatarMarker: Promise.resolve().then(() => __importStar(require('./AvatarMarker'))),
    LocationDisplayView: Promise.resolve().then(() => __importStar(require('./LocationDisplayView'))),
    LocationInputDialog: Promise.resolve().then(() => __importStar(require('./LocationInputDialog'))),
    LocationInputView: Promise.resolve().then(() => __importStar(require('./LocationInputView'))),
    Pulse: Promise.resolve().then(() => __importStar(require('./Pulse'))),
};
