"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Example 1:
 *
 * mapObject({ foo: 'bar' })
 */
function objectMapper(obj, fields, options) {
    const { defaultProps, ignore, rest } = Object.assign({ defaultProps: {}, ignore: [], rest: false }, options);
    const newObj = {};
    const restObj = {};
    //////////////////////////
    // Create Mapped Object //
    //////////////////////////
    Object.keys(fields).forEach(destKey => {
        // src fields key or thunk
        const src = fields[destKey];
        let value;
        // If src is a string, then it represents a key in the input object.
        // Check if said key does in fact exist in the object
        if (typeof src === 'string' && obj[src] !== undefined) {
            // We have resolved the value here
            value = obj[src];
        }
        // If src is a function, then it is a thunk.
        if (typeof src === 'function' && src) {
            // Resolve the thunk and use the returned data as value
            value = src(Object.assign({}, defaultProps, obj), fields);
        }
        // After everything, if the value is still undefined, then skip this key
        if (value === undefined) {
            return;
        }
        // Set the value in the final object
        newObj[destKey] = value;
    });
    /////////////////////////////
    // Add "rest" of the props //
    /////////////////////////////
    if (rest === true) {
        const inputKeys = Object.values(fields).filter(x => typeof x === 'string');
        Object.keys(obj).forEach(key => {
            if (inputKeys.indexOf(key) < 0 && ignore.indexOf(key) < 0) {
                restObj[key] = obj[key];
            }
        });
    }
    ////////////////////////////
    // Construct final object //
    ////////////////////////////
    const result = Object.assign({}, defaultProps, restObj, newObj);
    //////////////////////////
    // Remove ignored props //
    //////////////////////////
    ignore.forEach(key => {
        delete result[key];
    });
    return result;
}
exports.objectMapper = objectMapper;
