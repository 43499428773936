Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/LikeList.js';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactNative = require('react-native');

var _Avatar = require('./Avatar');

var _Avatar2 = _interopRequireDefault(_Avatar);

var _ReactionList = require('./ReactionList');

var _ReactionList2 = _interopRequireDefault(_ReactionList);

var _SectionHeader = require('./SectionHeader');

var _SectionHeader2 = _interopRequireDefault(_SectionHeader);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var LikeList = function (_React$PureComponent) {
  _inherits(LikeList, _React$PureComponent);

  function LikeList() {
    _classCallCheck(this, LikeList);

    return _possibleConstructorReturn(this, (LikeList.__proto__ || Object.getPrototypeOf(LikeList)).apply(this, arguments));
  }

  _createClass(LikeList, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          activityId = _props.activityId,
          activityPath = _props.activityPath;

      return _react2.default.createElement(
        _ReactionList2.default,
        {
          activityId: activityId,
          reactionKind: this.props.reactionKind,
          activityPath: activityPath,
          styles: {
            container: { padding: 12, paddingLeft: 15, paddingRight: 15 }
          },
          flatListProps: { horizontal: true },
          Reaction: function Reaction(_ref) {
            var reaction = _ref.reaction;
            return _react2.default.createElement(
              _reactNative.TouchableOpacity,
              { style: { marginRight: 10 }, __source: {
                  fileName: _jsxFileName,
                  lineNumber: 38
                }
              },
              _react2.default.createElement(_Avatar2.default, {
                source: reaction.user.data.profileImage,
                size: 25,
                noShadow: true,
                __source: {
                  fileName: _jsxFileName,
                  lineNumber: 39
                }
              })
            );
          },
          noPagination: true,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 29
          }
        },
        _react2.default.createElement(
          _SectionHeader2.default,
          {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 48
            }
          },
          'Likes'
        )
      );
    }
  }]);

  return LikeList;
}(_react2.default.PureComponent);

LikeList.defaultProps = {
  reactionKind: 'like'
};
exports.default = LikeList;