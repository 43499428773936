"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@bluebase/core");
const PickerItem_1 = require("./PickerItem");
/**
 * Picker components are used for collecting user provided information
 * from a list of options.
 */
exports.Picker = core_1.getComponent('Picker');
exports.Picker.Item = PickerItem_1.PickerItem;
/**
 * Default props for Picker component
 */
exports.PickerDefaultProps = {
    mode: 'default',
};
