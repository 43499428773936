"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const device_controller_1 = require("@mevris/device-controller");
const core_1 = require("@bluebase/core");
/**
 * ⌚️ DeviceRegistry
 */
class DeviceRegistry extends core_1.Registry {
    /**
     * Returns a Promise that resolves an UniversalDeviceController object
     * @param keys
     */
    async resolve(...keys) {
        const item = this.findOne(...keys);
        if (!item) {
            throw Error(`Could not resolve any of the following Devices: [${keys.join(', ')}].`);
        }
        return item.value;
    }
    attach(id, callback) {
        const item = this.get(id);
        if (!item) {
            return;
        }
        if (!item.listeners || item.listeners < 0) {
            item.listeners = 0;
        }
        if (item.listeners === 0) {
            item.value.subscribe(callback);
        }
        this.set(id, Object.assign(Object.assign({}, item), { listeners: item.listeners + 1 }));
    }
    detach(id) {
        const _item = this.get(id);
        if (!_item) {
            return;
        }
        const item = Object.assign({}, _item);
        if (!item.listeners) {
            item.listeners = 0;
        }
        if (item.listeners > 0) {
            item.listeners--;
            if (item.listeners === 0) {
                item.value.unsubscribe();
            }
        }
        this.set(id, item);
    }
    async create(id, options, extras = {}, middlewares = []) {
        const { localController, remoteController } = options;
        let localControllerObj;
        let remoteControllerObj;
        if (localController && localController.key) {
            const localControllerOptions = Object.assign({ id }, localController.options);
            const Controller = await this.BB.DeviceControllers.resolve(localController.key);
            localControllerObj = new Controller(Object.assign(Object.assign({}, localControllerOptions), extras));
        }
        if (remoteController && remoteController.key) {
            const remoteControllerOptions = Object.assign({ id }, remoteController.options);
            const Controller = await this.BB.DeviceControllers.resolve(remoteController.key);
            remoteControllerObj = new Controller(Object.assign(Object.assign({}, remoteControllerOptions), extras));
        }
        if (!localControllerObj && !remoteControllerObj) {
            return;
        }
        const udc = new device_controller_1.UniversalDeviceController(id, {
            localController: localControllerObj,
            remoteController: remoteControllerObj,
        });
        udc.use(...middlewares);
        this.register(id, udc);
    }
    /**
     * Convert any input value to an item. This is where you transform inputs and add defualts
     * @param key
     * @param partial
     */
    createItem(key, partial) {
        return super.createItem(key, Object.assign({ listeners: 0 }, partial));
    }
    /**
     * Typeguard to check a given object is a registry value
     * @param value
     */
    isValue(value) {
        return value instanceof device_controller_1.UniversalDeviceController;
    }
    /**
     * Typeguard to check a given object is an input value
     * @param value
     */
    isInputValue(value) {
        return this.isValue(value);
    }
}
exports.DeviceRegistry = DeviceRegistry;
