"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const components_1 = require("@bluebase/components");
exports.Placeholder = components_1.getComponentWithFallback({
    moduleName: '@bluebase/plugin-rn-placeholder',
    name: 'Placeholder',
})('Placeholder');
exports.Placeholder.displayName = 'Placeholder';
exports.PlaceholderBody1 = components_1.getComponentWithFallback({
    moduleName: '@bluebase/plugin-rn-placeholder',
    name: 'PlaceholderBody1',
})('PlaceholderBody1');
exports.PlaceholderBody1.displayName = 'PlaceholderBody1';
exports.ThingCard = components_1.getComponentWithFallback({
    moduleName: '@mevris/client-plugin-ui',
    name: 'ThingCard',
})('ThingCard');
exports.ThingCard.displayName = 'ThingCard';
