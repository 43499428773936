"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// tslint:disable-next-line: variable-name
exports.String = {
    key: 'String',
    dataType: 'STRING',
    /**
     * Returns value as it is
     *
     * Example: 'value' => 'value'
     */
    template: '{{ value }}',
};
