Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/Card.js';

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactNative = require('react-native');

var _styles = require('../styles');

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Card = function Card(props) {
  var title = props.title,
      description = props.description,
      image = props.image,
      url = props.url;

  var styles = (0, _styles.buildStylesheet)('card', props.styles);

  return _react2.default.createElement(
    _reactNative.TouchableOpacity,
    {
      onPress: function onPress() {
        _reactNative.Linking.openURL(url);
      },
      style: styles.container,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 27
      }
    },
    _react2.default.createElement(_reactNative.Image, {
      style: styles.image,
      source: image ? { uri: image } : require('../images/placeholder.png'),
      resizeMethod: 'resize',
      __source: {
        fileName: _jsxFileName,
        lineNumber: 33
      }
    }),
    _react2.default.createElement(
      _reactNative.View,
      { style: styles.content, __source: {
          fileName: _jsxFileName,
          lineNumber: 38
        }
      },
      _react2.default.createElement(
        _reactNative.Text,
        { style: styles.title, __source: {
            fileName: _jsxFileName,
            lineNumber: 39
          }
        },
        _lodash2.default.truncate(title, { length: 60 })
      ),
      _react2.default.createElement(
        _reactNative.Text,
        { style: styles.description, __source: {
            fileName: _jsxFileName,
            lineNumber: 40
          }
        },
        _lodash2.default.truncate(description, { length: 60 })
      )
    )
  );
};

exports.default = Card;