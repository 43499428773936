Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/Activity.js';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _reactNative = require('react-native');

var _styles = require('../styles');

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _UserBar = require('./UserBar');

var _UserBar2 = _interopRequireDefault(_UserBar);

var _Card = require('./Card');

var _Card2 = _interopRequireDefault(_Card);

var _utils = require('../utils');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Activity = function (_React$Component) {
  _inherits(Activity, _React$Component);

  function Activity() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, Activity);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Activity.__proto__ || Object.getPrototypeOf(Activity)).call.apply(_ref, [this].concat(args))), _this), _this._getOnPress = function () {
      if (_this.props.onPress) {
        return _this.props.onPress;
      }
    }, _this._getOnPressAvatar = function () {
      if (_this.props.activity.actor !== 'NotFound' && _this.props.onPressAvatar) {
        return _this.props.onPressAvatar;
      }
    }, _this.renderHeader = function () {
      var _this$props$activity = _this.props.activity,
          time = _this$props$activity.time,
          activityActor = _this$props$activity.actor;

      var notFound = {
        id: '!not-found',
        created_at: '',
        updated_at: '',
        data: { name: 'Unknown', profileImage: '' }
      };
      var actor = void 0;
      if (typeof activityActor === 'string' || typeof activityActor.error === 'string') {
        actor = notFound;
      } else {
        actor = activityActor;
      }

      var styles = (0, _styles.buildStylesheet)('activity', _this.props.styles);

      return React.createElement(
        _reactNative.View,
        { style: styles.header, __source: {
            fileName: _jsxFileName,
            lineNumber: 101
          }
        },
        React.createElement(_UserBar2.default, {
          username: actor.data.name,
          avatar: actor.data.profileImage,
          subtitle: _this.props.sub,
          timestamp: time,
          icon: _this.props.icon,
          onPressAvatar: _this._getOnPressAvatar(),
          __source: {
            fileName: _jsxFileName,
            lineNumber: 102
          }
        })
      );
    }, _this.onPressMention = function (text, activity) {
      if (_this.props.onPressMention !== undefined) {
        _this.props.onPressMention(text, activity);
        return;
      }
      console.log('pressed on ' + text + ' mention of ' + activity.id);
    }, _this.onPressHashtag = function (text, activity) {
      if (_this.props.onPressHashtag !== undefined) {
        _this.props.onPressHashtag(text, activity);
        return;
      }
      console.log('pressed on ' + text + ' hashtag of ' + activity.id);
    }, _this.getAndTrimUrl = function (text, activity) {
      if (activity.attachments && activity.attachments.og && Object.keys(activity.attachments.og).length > 0) {
        var textWithoutUrl = _lodash2.default.replace(text, activity.attachments.og.url, ' ');
        return textWithoutUrl.split(' ');
      } else {
        return text.split(' ');
      }
    }, _this.renderText = function (text, activity) {
      var tokens = text.split(' ');
      var rendered = [];
      var styles = (0, _styles.buildStylesheet)('activity', _this.props.styles);

      var _loop = function _loop(i) {
        if (tokens[i][0] === '@') {
          rendered.push(React.createElement(
            _reactNative.Text,
            {
              style: styles.mention,
              onPress: function onPress() {
                _this.onPressMention(tokens[i], activity);
              },
              key: i,
              __source: {
                fileName: _jsxFileName,
                lineNumber: 151
              }
            },
            tokens[i],
            ' '
          ));
        } else if (tokens[i][0] === '#') {
          rendered.push(React.createElement(
            _reactNative.Text,
            {
              style: styles.hashtag,
              onPress: function onPress() {
                _this.onPressHashtag(tokens[i], activity);
              },
              key: i,
              __source: {
                fileName: _jsxFileName,
                lineNumber: 163
              }
            },
            tokens[i],
            ' '
          ));
        } else if (activity.attachments && activity.attachments.og && Object.keys(activity.attachments.og).length > 0 && tokens[i] === activity.attachments.og.url) {
          var url = activity.attachments.og.url;
          rendered.push(React.createElement(
            _reactNative.Text,
            { key: i, onPress: function onPress() {
                return _reactNative.Linking.openURL(url);
              }, style: styles.url, __source: {
                fileName: _jsxFileName,
                lineNumber: 181
              }
            },
            tokens[i].slice(0, 20),
            tokens[i].length > 20 ? '...' : '',
            ' '
          ));
        } else {
          rendered.push(tokens[i] + ' ');
        }
      };

      for (var i = 0; i < tokens.length; i++) {
        _loop(i);
      }
      return React.createElement(
        _reactNative.Text,
        {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 190
          }
        },
        rendered
      );
    }, _this.renderContent = function () {
      var width = _this.props.imageWidth != null ? _this.props.imageWidth : _reactNative.Dimensions.get('window').width;
      var _this$props$activity2 = _this.props.activity,
          object = _this$props$activity2.object,
          image = _this$props$activity2.image,
          attachments = _this$props$activity2.attachments;
      var text = _this.props.activity.text;

      var styles = (0, _styles.buildStylesheet)('activity', _this.props.styles);
      var Card = _this.props.Card;

      if (text === undefined) {
        if (typeof object === 'string') {
          text = object;
        } else {
          text = '';
        }
      }
      text = text.trim();

      return React.createElement(
        _reactNative.View,
        {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 213
          }
        },
        Boolean(text) && React.createElement(
          _reactNative.View,
          { style: styles.content, __source: {
              fileName: _jsxFileName,
              lineNumber: 215
            }
          },
          React.createElement(
            _reactNative.Text,
            {
              __source: {
                fileName: _jsxFileName,
                lineNumber: 216
              }
            },
            _this.renderText(text, _this.props.activity)
          )
        ),
        Boolean(image) && React.createElement(_reactNative.Image, {
          style: { width: width, height: width },
          source: { uri: image },
          resizeMethod: 'resize',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 221
          }
        }),
        attachments && attachments.images && attachments.images.length > 0 && React.createElement(_reactNative.Image, {
          style: { width: width, height: width },
          source: { uri: attachments.images[0] },
          resizeMethod: 'resize',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 231
          }
        }),
        attachments && attachments.og && Object.keys(attachments.og).length > 0 && (0, _utils.smartRender)(Card, {
          title: attachments.og.title,
          description: attachments.og.description,
          image: attachments.og.images && attachments.og.images.length > 0 ? attachments.og.images[0].image : null,
          url: attachments.og.url,
          og: attachments.og
        })
      );
    }, _this.renderFooter = function () {
      return null;
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(Activity, [{
    key: 'componentDidCatch',
    value: function componentDidCatch(error, info) {
      if (this.props.componentDidCatch) {
        this.props.componentDidCatch(error, info, this.props);
      } else {
        console.error(error);
        console.error('The following activity caused the previous error');
        console.error(this.props.activity);
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _props = this.props,
          Header = _props.Header,
          Content = _props.Content,
          Footer = _props.Footer;


      var styles = (0, _styles.buildStylesheet)('activity', this.props.styles);

      return React.createElement(
        _reactNative.TouchableOpacity,
        {
          style: [styles.container],
          onPress: this._getOnPress(),
          disabled: !this._getOnPress(),
          __source: {
            fileName: _jsxFileName,
            lineNumber: 264
          }
        },
        (0, _utils.smartRender)(Header, {}, this.renderHeader),
        (0, _utils.smartRender)(Content, {}, this.renderContent),
        (0, _utils.smartRender)(Footer, {}, this.renderFooter)
      );
    }
  }]);

  return Activity;
}(React.Component);

Activity.defaultProps = {
  Card: _Card2.default
};
exports.default = Activity;