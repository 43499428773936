"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@bluebase/core");
exports.Radio = core_1.getComponent('Radio');
/**
 * Default props for Radio component
 */
exports.RadioDefaultProps = {
    checked: false,
    color: 'secondary',
    disabled: false,
    labelPlacement: 'end',
};
