"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemperatureValue = {
    key: 'TemperatureValue',
    dataType: 'NUMBER',
    /**
     * Transforms raw state value to user friendly version
     *
     * Example: 26.3245642156 => "26 C"
     */
    template: '{{ convert(value).from(unit.abbr).toBest().val }} {{ unit.abbr }}',
    unit: 'C',
    precision: 0,
};
