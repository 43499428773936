"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const Permissions = tslib_1.__importStar(require("expo-permissions"));
/**
 * expo manages checking permission for both android and ios
 */
exports.checkCameraPermissionAsync = async () => {
    const { status } = await Permissions.getAsync(Permissions.CAMERA);
    return { status };
};
/**
 * expo manages requesting permission for both android and ios
 */
exports.requestCameraPermissionsAsync = async () => Permissions.askAsync(Permissions.CAMERA);
