"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const react_1 = tslib_1.__importStar(require("react"));
const components_1 = require("@bluebase/components");
const hooks_1 = require("../../hooks");
const core_1 = require("@bluebase/core");
exports.RequireAuthentication = ({ children }) => {
    const { userId, accessToken } = hooks_1.useCredentials();
    const { navigate, state } = core_1.useNavigation();
    const isLoggedIn = () => !!userId && !!accessToken;
    react_1.useEffect(() => {
        if (!isLoggedIn()) {
            navigate('Login', Object.assign(Object.assign({}, state.params), { redirect: state }));
        }
    }, []);
    if (isLoggedIn()) {
        return children;
    }
    return react_1.default.createElement(components_1.LoadingState, null);
};
