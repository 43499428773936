Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _jsxFileName = 'src/components/CommentBox.js';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactNative = require('react-native');

var _reactNativeStickyKeyboardAccessory = require('react-native-sticky-keyboard-accessory');

var _reactNativeStickyKeyboardAccessory2 = _interopRequireDefault(_reactNativeStickyKeyboardAccessory);

var _Avatar = require('./Avatar');

var _Avatar2 = _interopRequireDefault(_Avatar);

var _styles = require('../styles');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var CommentBox = function (_React$Component) {
  _inherits(CommentBox, _React$Component);

  function CommentBox() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, CommentBox);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = CommentBox.__proto__ || Object.getPrototypeOf(CommentBox)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      text: ''
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(CommentBox, [{
    key: 'postComment',
    value: function postComment(event) {
      if (this.props.onSubmit !== undefined) {
        this.props.onSubmit(event.nativeEvent.text);
      } else {
        this.props.onAddReaction('comment', this.props.activity, {
          text: event.nativeEvent.text
        });
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          noKeyboardAccessory = _props.noKeyboardAccessory,
          textInputProps = _props.textInputProps;


      var styles = (0, _styles.buildStylesheet)('commentBox', this.props.styles);
      var input = _react2.default.createElement(
        _reactNative.View,
        { style: styles.container, __source: {
            fileName: _jsxFileName,
            lineNumber: 77
          }
        },
        this.props.noAvatar || _react2.default.createElement(_Avatar2.default, _extends({
          size: 48,
          styles: styles.avatar
        }, this.props.avatarProps, {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 79
          }
        })),
        _react2.default.createElement(_reactNative.TextInput, _extends({
          value: this.state.text,
          style: styles.textInput,
          underlineColorAndroid: 'transparent',
          onChangeText: function onChangeText(text) {
            return _this2.setState({ text: text });
          },
          onSubmitEditing: function onSubmitEditing(event) {
            _this2.setState({ text: '' });
            _this2.postComment(event);
          },
          placeholder: 'Your comment...',
          returnKeyType: 'send'
        }, textInputProps, {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 85
          }
        }))
      );
      if (noKeyboardAccessory) {
        return input;
      }

      return _react2.default.createElement(
        _react2.default.Fragment,
        {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 105
          }
        },
        _react2.default.createElement(_reactNative.View, { style: { height: this.props.height }, __source: {
            fileName: _jsxFileName,
            lineNumber: 106
          }
        }),
        _react2.default.createElement(
          _reactNativeStickyKeyboardAccessory2.default,
          { verticalOffset: this.props.verticalOffset, __source: {
              fileName: _jsxFileName,
              lineNumber: 107
            }
          },
          input
        )
      );
    }
  }]);

  return CommentBox;
}(_react2.default.Component);

CommentBox.defaultProps = {
  styles: {},
  height: 80,
  verticalOffset: 0,
  noKeyboardAccessory: false
};
exports.default = CommentBox;