"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const core_1 = require("@bluebase/core");
const exports_1 = require("./components/exports");
const version_1 = require("./version");
const components_1 = require("./components");
const lang_1 = require("./lang");
tslib_1.__exportStar(require("./exports"), exports);
exports.default = core_1.createPlugin({
    description: 'The plugin provides common UI across BlueEast Apps.',
    key: 'blueeast-client-plugin-ui',
    name: 'blueeast client plugin Ui',
    version: version_1.VERSION,
    assets: {
        // Errors
        ForbiddenError: require('../assets/common/forbidden-error.png'),
        NetworkError: require('../assets/common/network-error.png'),
        NotFoundError: require('../assets/common/404-error.png'),
        // Placeholders
        PersonPlaceholder: require('../assets/common/person-placeholder.png'),
        PersonPlaceholder_dark: require('../assets/common/person-placeholder-dark.png'),
        ProductPlaceholder: require('../assets/common/product-placeholder.png'),
        ProductPlaceholder_dark: require('../assets/common/product-placeholder-dark.png'),
        ThingPlaceholder: require('../assets/common/thing-placeholder.png'),
        ThingPlaceholder_dark: require('../assets/common/thing-placeholder-dark.png'),
    },
    components: Object.assign({}, components_1.components),
    filters: Object.assign(Object.assign({}, lang_1.lang), { 'bluebase.boot.end': (bootOptions, _ctx, BB) => {
            BB.Components.addHocs('BlueBaseContent', exports_1.withActionSheetProvider);
            BB.Components.addHocs('BlueBaseContent', exports_1.withAlertUI);
            BB.Components.addHocs('ErrorState', exports_1.withApolloError);
            return bootOptions;
        } }),
});
