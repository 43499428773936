"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const EmptyState_1 = require("./EmptyState");
const LoadingState_1 = require("./LoadingState");
const core_1 = require("@bluebase/core");
/**
 * # 👨‍🎨 StatefulComponent
 *
 * This is a swiss army knife component. Intended to be used as a single source of UI
 * state management. It shows empty, loading, error or data states based on the given props.
 *
 * ## Usage
 * ```jsx
 * <StatefulComponent data={data} loading={true} delay={200} timeout={10000}>
 *  <Text>Content</Text>
 * </StatefulComponent>
 * ```
 */
exports.StatefulComponent = core_1.getComponent('StatefulComponent');
exports.StatefulComponent.defaultProps = {
    emptyComponent: EmptyState_1.EmptyState,
    loadingComponent: LoadingState_1.LoadingState,
    timeout: 10000,
};
