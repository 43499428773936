"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const DataType = tslib_1.__importStar(require("../DataType"));
function format(value, schema) {
    const dataType = schema.dataType;
    if (!dataType) {
        throw new Error('Invalid data type');
    }
    let formattedValue = value;
    // Format against data type
    const dataTypeFormatter = DataType.format[dataType];
    if ('precision' in schema) {
        try {
            formattedValue = parseFloat(formattedValue).toFixed(schema.precision);
        }
        catch (e) {
            formattedValue = value;
        }
    }
    if (dataTypeFormatter) {
        formattedValue = dataTypeFormatter(formattedValue, schema);
    }
    // Format against schema
    if (schema.format) {
        formattedValue = schema.format(formattedValue, schema);
    }
    return formattedValue;
}
exports.format = format;
