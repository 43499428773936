Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _jsxFileName = 'src/components/StatusUpdateForm.js';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactNative = require('react-native');

var _Context = require('../Context');

var _UrlPreview = require('./UrlPreview');

var _UrlPreview2 = _interopRequireDefault(_UrlPreview);

var _native = require('../native');

var _mimeTypes = require('mime-types');

var _mimeTypes2 = _interopRequireDefault(_mimeTypes);

var _styles = require('../styles');

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _es6Symbol = require('es6-symbol');

var _es6Symbol2 = _interopRequireDefault(_es6Symbol);

var _reactNativeStickyKeyboardAccessory = require('react-native-sticky-keyboard-accessory');

var _reactNativeStickyKeyboardAccessory2 = _interopRequireDefault(_reactNativeStickyKeyboardAccessory);

var _reactNativeKeyboardSpacer = require('react-native-keyboard-spacer');

var _reactNativeKeyboardSpacer2 = _interopRequireDefault(_reactNativeKeyboardSpacer);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ImageState = Object.freeze({
  NO_IMAGE: (0, _es6Symbol2.default)('no_image'),
  UPLOADING: (0, _es6Symbol2.default)('uploading'),
  UPLOADED: (0, _es6Symbol2.default)('uploaded'),
  UPLOAD_FAILED: (0, _es6Symbol2.default)('upload_failed')
});

var urlRegex = /(https?:\/\/[^\s]+)/gi;

var StatusUpdateForm = function (_React$Component) {
  _inherits(StatusUpdateForm, _React$Component);

  function StatusUpdateForm() {
    _classCallCheck(this, StatusUpdateForm);

    return _possibleConstructorReturn(this, (StatusUpdateForm.__proto__ || Object.getPrototypeOf(StatusUpdateForm)).apply(this, arguments));
  }

  _createClass(StatusUpdateForm, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      return _react2.default.createElement(
        _Context.StreamApp.Consumer,
        {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 130
          }
        },
        function (appCtx) {
          if (_this2.props.fullscreen) {
            return _react2.default.createElement(
              _reactNative.View,
              { style: { flex: 1 }, __source: {
                  fileName: _jsxFileName,
                  lineNumber: 134
                }
              },
              _react2.default.createElement(StatusUpdateFormInner, _extends({}, _this2.props, appCtx, {
                __source: {
                  fileName: _jsxFileName,
                  lineNumber: 135
                }
              }))
            );
          } else {
            if ((_reactNative.Platform.OS === 'ios' || _native.androidTranslucentStatusBar) && !_this2.props.noKeyboardAccessory) {
              return _react2.default.createElement(
                _react2.default.Fragment,
                {
                  __source: {
                    fileName: _jsxFileName,
                    lineNumber: 144
                  }
                },
                _react2.default.createElement(_reactNative.View, { style: { height: _this2.props.height }, __source: {
                    fileName: _jsxFileName,
                    lineNumber: 145
                  }
                }),
                _react2.default.createElement(
                  _reactNativeStickyKeyboardAccessory2.default,
                  { verticalOffset: _this2.props.verticalOffset, __source: {
                      fileName: _jsxFileName,
                      lineNumber: 146
                    }
                  },
                  _react2.default.createElement(StatusUpdateFormInner, _extends({}, _this2.props, appCtx, {
                    __source: {
                      fileName: _jsxFileName,
                      lineNumber: 147
                    }
                  }))
                )
              );
            } else {
              return _react2.default.createElement(StatusUpdateFormInner, _extends({}, _this2.props, appCtx, {
                __source: {
                  fileName: _jsxFileName,
                  lineNumber: 152
                }
              }));
            }
          }
        }
      );
    }
  }]);

  return StatusUpdateForm;
}(_react2.default.Component);

StatusUpdateForm.defaultProps = {
  feedGroup: 'user',
  activityVerb: 'post',
  fullscreen: false,
  modifyActivityData: function modifyActivityData(d) {
    return d;
  },
  height: 80,
  verticalOffset: 0,
  noKeyboardAccessory: false,
  styles: {
    urlPreview: {
      wrapper: {
        padding: 15,
        paddingTop: 8,
        paddingBottom: 8,
        borderTopColor: '#eee',
        borderTopWidth: 1
      },
      textStyle: { fontSize: 14 }
    }
  }
};
exports.default = StatusUpdateForm;

var StatusUpdateFormInner = function (_React$Component2) {
  _inherits(StatusUpdateFormInner, _React$Component2);

  function StatusUpdateFormInner(props) {
    var _this4 = this;

    _classCallCheck(this, StatusUpdateFormInner);

    var _this3 = _possibleConstructorReturn(this, (StatusUpdateFormInner.__proto__ || Object.getPrototypeOf(StatusUpdateFormInner)).call(this, props));

    _this3.textInputRef = _react2.default.createRef();
    _this3.state = {
      image: null,
      imageUrl: null,
      imageState: ImageState.NO_IMAGE,
      og: null,
      ogScraping: false,
      ogLink: null,
      textFromInput: '',
      clearInput: false,
      focused: false,
      urls: [],
      dismissedUrls: []
    };

    _this3._pickImage = function _callee() {
      var result, response, contentType, filename;
      return regeneratorRuntime.async(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return regeneratorRuntime.awrap((0, _native.pickImage)());

            case 2:
              result = _context.sent;

              if (!result.cancelled) {
                _context.next = 5;
                break;
              }

              return _context.abrupt('return');

            case 5:

              _this3.setState({
                image: result.uri,
                imageState: ImageState.UPLOADING
              });

              response = void 0;
              contentType = void 0;

              if (_reactNative.Platform.OS === 'android') {
                filename = result.uri.replace(/^(file:\/\/|content:\/\/)/, '');

                contentType = _mimeTypes2.default.lookup(filename) || 'application/octet-stream';
              }
              _context.prev = 9;
              _context.next = 12;
              return regeneratorRuntime.awrap(_this3.props.client.images.upload(result.uri, null, contentType));

            case 12:
              response = _context.sent;
              _context.next = 21;
              break;

            case 15:
              _context.prev = 15;
              _context.t0 = _context['catch'](9);

              console.warn(_context.t0);
              _this3.setState({
                imageState: ImageState.UPLOAD_FAILED,
                image: null
              });

              _this3.props.errorHandler(_context.t0, 'upload-image', {
                feedGroup: _this3.props.feedGroup,
                userId: _this3.props.userId
              });
              return _context.abrupt('return');

            case 21:

              _this3.setState({
                imageState: ImageState.UPLOADED,
                imageUrl: response.file
              });

            case 22:
            case 'end':
              return _context.stop();
          }
        }
      }, null, _this4, [[9, 15]]);
    };

    _this3._removeImage = function () {
      _this3.setState({
        imageState: ImageState.NO_IMAGE,
        imageUrl: null,
        image: null
      });
    };

    _this3._text = function () {
      return _this3.state.textFromInput.trim();
    };

    _this3._object = function () {
      if (_this3.state.imageUrl) {
        return _this3.state.imageUrl;
      }
      return _this3._text();
    };

    _this3._canSubmit = function () {
      return Boolean(_this3._object());
    };

    _this3._onPressDismiss = function (url) {
      var oldDismissedUrls = _this3.state.dismissedUrls;
      _this3.setState({
        dismissedUrls: [].concat(_toConsumableArray(oldDismissedUrls), [url]),
        ogScraping: false,
        ogLink: null,
        og: null
      });
    };

    _this3.onSubmitForm = function _callee2() {
      return regeneratorRuntime.async(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              _context2.prev = 0;
              _context2.next = 3;
              return regeneratorRuntime.awrap(_this3.addActivity());

            case 3:
              _context2.next = 9;
              break;

            case 5:
              _context2.prev = 5;
              _context2.t0 = _context2['catch'](0);

              _this3.props.errorHandler(_context2.t0, 'add-activity', {
                feedGroup: _this3.props.feedGroup,
                userId: _this3.props.userId
              });
              return _context2.abrupt('return');

            case 9:
              _reactNative.Keyboard.dismiss();
              _this3.setState({
                image: null,
                imageUrl: null,
                imageState: ImageState.NO_IMAGE,
                og: null,
                ogScraping: false,
                ogLink: null,
                textFromInput: '',
                focused: false,
                urls: [],
                dismissedUrls: []
              });
              if (_this3.props.onSuccess) {
                _this3.props.onSuccess();
              }

            case 12:
            case 'end':
              return _context2.stop();
          }
        }
      }, null, _this4, [[0, 5]]);
    };

    _this3._handleOgDebounced = _lodash2.default.debounce(_this3.handleOG, 250);
    return _this3;
  }

  _createClass(StatusUpdateFormInner, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      var _this5 = this;

      if (this.props.registerSubmit) {
        this.props.registerSubmit(function () {
          return _this5.onSubmitForm();
        });
      }
    }
  }, {
    key: 'addActivity',
    value: function addActivity() {
      var activity, attachments, modifiedActivity;
      return regeneratorRuntime.async(function addActivity$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              activity = {
                actor: this.props.client.currentUser,
                verb: this.props.activityVerb,
                object: this._object()
              };
              attachments = {};


              if (this.state.og && Object.keys(this.state.og).length > 0) {
                attachments.og = this.state.og;
              }

              if (this.state.imageUrl) {
                attachments.images = [this.state.imageUrl];
                activity.text = this._text();
              }

              if (Object.keys(attachments).length > 0) {
                activity.attachments = attachments;
              }

              modifiedActivity = this.props.modifyActivityData(activity);

              if (!this.props.doRequest) {
                _context3.next = 11;
                break;
              }

              _context3.next = 9;
              return regeneratorRuntime.awrap(this.props.doRequest(modifiedActivity));

            case 9:
              _context3.next = 13;
              break;

            case 11:
              _context3.next = 13;
              return regeneratorRuntime.awrap(this.props.client.feed(this.props.feedGroup, this.props.userId).addActivity(modifiedActivity));

            case 13:
            case 'end':
              return _context3.stop();
          }
        }
      }, null, this);
    }
  }, {
    key: 'handleOG',
    value: function handleOG(text) {
      var _this6 = this;

      if (this.state.ogScraping) {
        return;
      }
      var urls = text.match(urlRegex);

      if (!urls) {
        this.setState({
          og: null,
          ogLink: null
        });
        return;
      }

      urls.forEach(function (url) {
        if (url !== _this6.state.ogLink && !(_this6.state.dismissedUrls.indexOf(url) > -1) && !_this6.state.og && urls.indexOf(url) > -1) {
          _this6.setState({
            ogScraping: true,
            ogLink: url,
            og: url === _this6.state.ogLink ? _this6.state.og : null
          });
          _this6.props.client.og(url).then(function (resp) {
            var oldStateUrls = _this6.state.urls;
            _this6.setState({
              og: Object.keys(resp).length > 0 ? _extends({}, resp, { url: url }) : null,
              ogScraping: false,
              urls: [].concat(_toConsumableArray(oldStateUrls), [url])
            }, function () {
              return text.replace(url, '');
            });
          }).catch(function (err) {
            console.log(err);
            _this6.setState({
              ogScraping: false,
              og: null
            });
          });
        }
      });
    }
  }, {
    key: 'render',
    value: function render() {
      var _this7 = this;

      var styles = (0, _styles.buildStylesheet)('statusUpdateForm', this.props.styles);
      return _react2.default.createElement(
        _reactNative.View,
        { style: [this.props.fullscreen ? { flex: 1 } : {}], __source: {
            fileName: _jsxFileName,
            lineNumber: 378
          }
        },
        _react2.default.createElement(
          _reactNative.View,
          {
            style: [styles.container, this.props.height ? { height: this.props.height } : { height: 80 }, this.state.focused ? styles.containerFocused : {}, this.state.og ? styles.containerFocusedOg : {}, this.props.fullscreen ? { flex: 1 } : {}],
            __source: {
              fileName: _jsxFileName,
              lineNumber: 379
            }
          },
          this.state.og && _react2.default.createElement(_UrlPreview2.default, {
            onPressDismiss: this._onPressDismiss,
            og: this.state.og,
            styles: this.props.styles.urlPreview,
            __source: {
              fileName: _jsxFileName,
              lineNumber: 389
            }
          }),
          _react2.default.createElement(
            _reactNative.View,
            { style: styles.newPostContainer, __source: {
                fileName: _jsxFileName,
                lineNumber: 399
              }
            },
            _react2.default.createElement(
              _reactNative.View,
              { style: [styles.textInput], __source: {
                  fileName: _jsxFileName,
                  lineNumber: 400
                }
              },
              _react2.default.createElement(_reactNative.TextInput, _extends({
                ref: this.textInputRef,
                style: this.props.fullscreen ? { flex: 1 } : {},
                multiline: true,
                onChangeText: function onChangeText(text) {
                  _this7.setState({ textFromInput: text });
                  _this7._handleOgDebounced(text);
                },
                value: this.state.textFromInput,
                autocorrect: false,
                placeholder: 'Share something...',
                underlineColorAndroid: 'transparent',
                onBlur: function onBlur() {
                  return _this7.setState({ focused: false });
                },
                onFocus: function onFocus() {
                  return _this7.setState({ focused: true });
                }
              }, this.props.textInputProps, {
                __source: {
                  fileName: _jsxFileName,
                  lineNumber: 401
                }
              }))
            ),
            _react2.default.createElement(
              _reactNative.View,
              {
                style: [styles.actionPanel, this.state.focused ? {} : styles.actionPanelBlur],
                __source: {
                  fileName: _jsxFileName,
                  lineNumber: 419
                }
              },
              _react2.default.createElement(
                _reactNative.View,
                {
                  style: [styles.imageContainer, this.state.focused ? {} : styles.imageContainerBlur],
                  __source: {
                    fileName: _jsxFileName,
                    lineNumber: 425
                  }
                },
                this.state.image ? _react2.default.createElement(
                  _react2.default.Fragment,
                  {
                    __source: {
                      fileName: _jsxFileName,
                      lineNumber: 432
                    }
                  },
                  _react2.default.createElement(_reactNative.Image, {
                    source: { uri: this.state.image },
                    style: this.state.imageState === ImageState.UPLOADING ? styles.image_loading : styles.image,
                    resizeMethod: 'resize',
                    __source: {
                      fileName: _jsxFileName,
                      lineNumber: 433
                    }
                  }),
                  _react2.default.createElement(
                    _reactNative.View,
                    { style: styles.imageOverlay, __source: {
                        fileName: _jsxFileName,
                        lineNumber: 442
                      }
                    },
                    this.state.imageState === ImageState.UPLOADING ? _react2.default.createElement(_reactNative.ActivityIndicator, { color: '#ffffff', __source: {
                        fileName: _jsxFileName,
                        lineNumber: 444
                      }
                    }) : _react2.default.createElement(
                      _reactNative.TouchableOpacity,
                      { onPress: this._removeImage, __source: {
                          fileName: _jsxFileName,
                          lineNumber: 446
                        }
                      },
                      _react2.default.createElement(_reactNative.Image, {
                        source: require('../images/icons/close-white.png'),
                        style: [{ width: 24, height: 24 }],
                        __source: {
                          fileName: _jsxFileName,
                          lineNumber: 447
                        }
                      })
                    )
                  )
                ) : _react2.default.createElement(
                  _reactNative.TouchableOpacity,
                  {
                    title: 'Pick an image from camera roll',
                    onPress: this._pickImage,
                    __source: {
                      fileName: _jsxFileName,
                      lineNumber: 456
                    }
                  },
                  _react2.default.createElement(_reactNative.Image, {
                    source: require('../images/icons/gallery.png'),
                    style: { width: 24, height: 24 },
                    __source: {
                      fileName: _jsxFileName,
                      lineNumber: 460
                    }
                  })
                )
              ),
              _react2.default.createElement(
                _reactNative.TouchableOpacity,
                {
                  title: 'Pick an image from camera roll',
                  onPress: this.onSubmitForm,
                  disabled: !this._canSubmit(),
                  __source: {
                    fileName: _jsxFileName,
                    lineNumber: 467
                  }
                },
                _react2.default.createElement(_reactNative.Image, {
                  source: this._canSubmit() ? require('../images/icons/send.png') : require('../images/icons/send-disabled.png'),
                  style: styles.submitImage,
                  __source: {
                    fileName: _jsxFileName,
                    lineNumber: 472
                  }
                })
              )
            )
          )
        ),
        this.props.fullscreen ? _react2.default.createElement(_reactNativeKeyboardSpacer2.default, {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 484
          }
        }) : null
      );
    }
  }]);

  return StatusUpdateFormInner;
}(_react2.default.Component);