Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _jsxFileName = 'src/components/Avatar.js';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactNative = require('react-native');

var _UploadImage = require('./UploadImage');

var _UploadImage2 = _interopRequireDefault(_UploadImage);

var _Context = require('../Context');

var _styles = require('../styles');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Avatar = function (_React$Component) {
  _inherits(Avatar, _React$Component);

  function Avatar() {
    _classCallCheck(this, Avatar);

    return _possibleConstructorReturn(this, (Avatar.__proto__ || Object.getPrototypeOf(Avatar)).apply(this, arguments));
  }

  _createClass(Avatar, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          source = _props.source,
          props = _objectWithoutProperties(_props, ['source']);

      if (typeof source === 'function') {
        var funcSource = source;
        return _react2.default.createElement(
          _Context.StreamApp.Consumer,
          {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 34
            }
          },
          function (appCtx) {
            var newSource = void 0;
            if (appCtx.user.full) {
              newSource = funcSource(appCtx.user.full);
            }
            return _react2.default.createElement(AvatarInner, _extends({}, props, { source: newSource, __source: {
                fileName: _jsxFileName,
                lineNumber: 40
              }
            }));
          }
        );
      } else {
        return _react2.default.createElement(AvatarInner, _extends({}, props, { source: source, __source: {
            fileName: _jsxFileName,
            lineNumber: 45
          }
        }));
      }
    }
  }]);

  return Avatar;
}(_react2.default.Component);

exports.default = Avatar;


var AvatarInner = function AvatarInner(props) {
  var source = props.source,
      _props$size = props.size,
      size = _props$size === undefined ? 200 : _props$size,
      noShadow = props.noShadow,
      notRound = props.notRound,
      editButton = props.editButton,
      onUploadButtonPress = props.onUploadButtonPress;

  var styles = (0, _styles.buildStylesheet)('avatar', props.styles || {});
  var borderRadius = notRound ? undefined : size / 2;
  return _react2.default.createElement(
    _reactNative.View,
    {
      style: [styles.container, noShadow ? styles.noShadow : null, {
        width: size,
        height: size
      }],
      __source: {
        fileName: _jsxFileName,
        lineNumber: 64
      }
    },
    _react2.default.createElement(_reactNative.Image, {
      style: [styles.image, {
        width: size,
        height: size,
        borderRadius: borderRadius
      }],
      source: source ? { uri: source } : require('../images/placeholder.png'),
      resizeMethod: 'resize',
      __source: {
        fileName: _jsxFileName,
        lineNumber: 74
      }
    }),
    editButton ? _react2.default.createElement(_UploadImage2.default, { onUploadButtonPress: onUploadButtonPress, __source: {
        fileName: _jsxFileName,
        lineNumber: 87
      }
    }) : null
  );
};