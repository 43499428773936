"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lib_1 = require("../lib");
const pages_1 = require("./pages");
exports.routes = async (BB) => {
    return [
        ...lib_1.createSettingsRoutes({
            filter: 'bluebase.plugin.setting-app',
            pages: await BB.Filters.run('bluebase.plugin.setting-app.pages', pages_1.pages),
            mainRoute: {
                name: 'SettingsApp',
                path: '',
                navigationOptions: (opts) => {
                    const { __ } = opts.screenProps.intl;
                    return {
                        title: __('Settings'),
                    };
                },
            },
        }),
    ];
};
