"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@bluebase/core");
// tslint:disable: jsdoc-format
/**
 * # ⏰ WaitObserver
 *
 * This component is used to do the following:
 *
 * - WaitObserver a certain period of time before rendering a component
 * - Show timeout state, if the component is visible for a certain time period
 *
 * A use case for this can be to show a loading state after waiting a certain period
 * of time for data to load, and if the loading takes too long, show a timeout state.
 *
 * ## Usage
 * ```jsx
<WaitObserver
    delay={1000}
    timeout={3000}
    onTimeout={onTimeout}
    onRetry={onRetry}
>
{(props: WaitObserverChildrenProps) => <LoadingState {...props} />}
</WaitObserver>```
 */
exports.WaitObserver = core_1.getComponent('WaitObserver');
