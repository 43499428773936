Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/ReactionIcon.js';
exports.default = ReactionIcon;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactNative = require('react-native');

var _styles = require('../styles');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function defaultLabelFunction(count, props) {
  var labelSingle = props.labelSingle,
      labelPlural = props.labelPlural,
      labelFunction = props.labelFunction;

  if (labelFunction) {
    return labelFunction({
      count: count,
      labelSingle: labelSingle,
      labelPlural: labelPlural
    });
  }
  var label = count === 1 ? labelSingle : labelPlural;
  if (!label) {
    return '' + count;
  }
  return count + ' ' + label;
}

function ReactionIcon(props) {
  var count = null;
  if (props.counts && props.kind) {
    count = props.counts[props.kind] || 0;
  }
  var styles = (0, _styles.buildStylesheet)('reactionIcon', props.styles);

  var dimensions = {};
  if (props.height !== undefined) {
    dimensions.height = props.height;
  }
  if (props.width !== undefined) {
    dimensions.width = props.width;
  }

  return _react2.default.createElement(
    _reactNative.TouchableOpacity,
    { style: styles.container, onPress: props.onPress, __source: {
        fileName: _jsxFileName,
        lineNumber: 70
      }
    },
    _react2.default.createElement(_reactNative.Image, { source: props.icon, style: [styles.image, dimensions], __source: {
        fileName: _jsxFileName,
        lineNumber: 71
      }
    }),
    count != null ? _react2.default.createElement(
      _reactNative.Text,
      { style: styles.text, __source: {
          fileName: _jsxFileName,
          lineNumber: 73
        }
      },
      defaultLabelFunction(count, props)
    ) : null
  );
}