"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@bluebase/core");
// tslint:disable: jsdoc-format
/**
 * 🈯️ FormattedMessage
 *
 * Converts text message based on the current locale.
 *
 * ## Usage
 ```jsx
<FormattedMessage component={H5}>Hello! 👋</FormattedMessage>
```
 */
exports.FormattedMessage = core_1.getComponent('FormattedMessage');
