Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _jsxFileName = 'src/components/ReactionList.js';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _reactNative = require('react-native');

var _styles = require('../styles');

var _Context = require('../Context');

var _utils = require('../utils');

var _immutable = require('immutable');

var _immutable2 = _interopRequireDefault(_immutable);

var _LoadMoreButton = require('./LoadMoreButton');

var _LoadMoreButton2 = _interopRequireDefault(_LoadMoreButton);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ReactionList = function (_React$PureComponent) {
  _inherits(ReactionList, _React$PureComponent);

  function ReactionList() {
    _classCallCheck(this, ReactionList);

    return _possibleConstructorReturn(this, (ReactionList.__proto__ || Object.getPrototypeOf(ReactionList)).apply(this, arguments));
  }

  _createClass(ReactionList, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      return React.createElement(
        _Context.FeedContext.Consumer,
        {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 59
          }
        },
        function (appCtx) {
          return React.createElement(ReactionListInner, _extends({}, _this2.props, appCtx, {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 60
            }
          }));
        }
      );
    }
  }]);

  return ReactionList;
}(React.PureComponent);

ReactionList.defaultProps = {
  LoadMoreButton: _LoadMoreButton2.default,
  infiniteScroll: false,
  noPagination: false,
  oldestToNewest: false,
  reverseOrder: false
};
exports.default = ReactionList;

var ReactionListInner = function (_React$Component) {
  _inherits(ReactionListInner, _React$Component);

  function ReactionListInner() {
    var _ref;

    var _temp, _this3, _ret;

    _classCallCheck(this, ReactionListInner);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this3 = _possibleConstructorReturn(this, (_ref = ReactionListInner.__proto__ || Object.getPrototypeOf(ReactionListInner)).call.apply(_ref, [this].concat(args))), _this3), _this3._renderReaction = function (reaction) {
      var Reaction = _this3.props.Reaction;

      return (0, _utils.smartRender)(Reaction, { reaction: reaction });
    }, _this3._renderWrappedReaction = function (_ref2) {
      var item = _ref2.item;
      return React.createElement(ImmutableItemWrapper, { renderItem: _this3._renderReaction, item: item, __source: {
          fileName: _jsxFileName,
          lineNumber: 214
        }
      });
    }, _temp), _possibleConstructorReturn(_this3, _ret);
  }

  _createClass(ReactionListInner, [{
    key: 'initReactions',
    value: function initReactions() {
      var _props = this.props,
          activityId = _props.activityId,
          activities = _props.activities,
          reactionKind = _props.reactionKind,
          getActivityPath = _props.getActivityPath,
          oldestToNewest = _props.oldestToNewest;

      if (!oldestToNewest) {
        return;
      }

      var activityPath = this.props.activityPath || getActivityPath(activityId);
      var orderPrefix = 'oldest';
      var reactions_extra = activities.getIn([].concat(_toConsumableArray(activityPath), [orderPrefix + '_reactions_extra']));
      if (reactions_extra) {
        return;
      }
      return this.props.loadNextReactions(activityId, reactionKind, activityPath, oldestToNewest);
    }
  }, {
    key: 'componentDidMount',
    value: function componentDidMount() {
      this.initReactions();
    }
  }, {
    key: 'componentDidUpdate',
    value: function componentDidUpdate() {
      this.initReactions();
    }
  }, {
    key: 'render',
    value: function render() {
      var _this4 = this;

      var _props2 = this.props,
          activityId = _props2.activityId,
          activities = _props2.activities,
          reactionKind = _props2.reactionKind,
          getActivityPath = _props2.getActivityPath,
          oldestToNewest = _props2.oldestToNewest,
          reverseOrder = _props2.reverseOrder;

      var activityPath = this.props.activityPath || getActivityPath(activityId);
      var orderPrefix = 'latest';
      if (oldestToNewest) {
        orderPrefix = 'oldest';
      }

      var reactionsOfKind = activities.getIn([].concat(_toConsumableArray(activityPath), [orderPrefix + '_reactions', reactionKind]), _immutable2.default.List());

      var reactions_extra = activities.getIn([].concat(_toConsumableArray(activityPath), [orderPrefix + '_reactions_extra']));
      var nextUrl = 'https://api.stream-io-api.com/';
      if (reactions_extra) {
        nextUrl = reactions_extra.getIn([reactionKind, 'next'], '');
      }

      var refreshing = activities.getIn([].concat(_toConsumableArray(activityPath), [orderPrefix + '_reactions_extra', reactionKind, 'refreshing']), false);

      var LoadMoreButton = this.props.LoadMoreButton;

      var styles = (0, _styles.buildStylesheet)('reactionList', this.props.styles);

      if (!reactionsOfKind.size) {
        return null;
      }
      var loadMoreButton = this.props.noPagination || !nextUrl || this.props.infiniteScroll ? null : (0, _utils.smartRender)(LoadMoreButton, {
        refreshing: refreshing,
        styles: styles,
        onPress: function onPress() {
          return _this4.props.loadNextReactions(activityId, reactionKind, activityPath, oldestToNewest);
        }
      });

      console.log();
      return React.createElement(
        React.Fragment,
        {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 179
          }
        },
        this.props.children,
        reverseOrder && loadMoreButton,
        React.createElement(_reactNative.FlatList, _extends({
          listKey: reactionKind + '-' + activityId,
          style: styles.container,
          refreshing: refreshing,
          data: reactionsOfKind.toArray(),
          keyExtractor: function keyExtractor(item) {
            return item.get('id') + activityPath;
          },
          renderItem: this._renderWrappedReaction,
          inverted: reverseOrder,
          onEndReached: this.props.noPagination || !this.props.infiniteScroll ? undefined : function () {
            return _this4.props.loadNextReactions(activityId, reactionKind, activityPath, oldestToNewest);
          }
        }, this.props.flatListProps, {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 182
          }
        })),
        !reverseOrder && loadMoreButton
      );
    }
  }]);

  return ReactionListInner;
}(React.Component);

var ImmutableItemWrapper = function (_React$PureComponent2) {
  _inherits(ImmutableItemWrapper, _React$PureComponent2);

  function ImmutableItemWrapper() {
    _classCallCheck(this, ImmutableItemWrapper);

    return _possibleConstructorReturn(this, (ImmutableItemWrapper.__proto__ || Object.getPrototypeOf(ImmutableItemWrapper)).apply(this, arguments));
  }

  _createClass(ImmutableItemWrapper, [{
    key: 'render',
    value: function render() {
      return this.props.renderItem(this.props.item.toJS());
    }
  }]);

  return ImmutableItemWrapper;
}(React.PureComponent);