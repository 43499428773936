"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const components_1 = require("./components");
const core_1 = require("@bluebase/core");
const configs_1 = require("./configs");
const filters_1 = require("./filters");
const forms_1 = require("./forms");
const lang_1 = require("./lang");
const screens_1 = require("./screens");
const settings_1 = require("./settings");
exports.default = core_1.createPlugin({
    description: 'User authentication flow in Mevris Client',
    key: '@mevris/client-plugin-user-management',
    name: 'Mevris User Management',
    version: '1.0.0',
    defaultConfigs: configs_1.defaultConfigs,
    assets: {
        UserManagementWallpaper_desktop_dark: require('../assets/common/login-signup-forgot-password-dark-deskop.png'),
        UserManagementWallpaper_desktop_light: require('../assets/common/login-signup-forgot-password-light-desktop.jpg'),
        UserManagementWallpaper_mobile_dark: require('../assets/common/login-signup-forgot-password-dark-mobile.png'),
        UserManagementWallpaper_mobile_light: require('../assets/common/login-signup-forgot-password-light-mobile.png'),
    },
    components: Object.assign(Object.assign(Object.assign(Object.assign({}, components_1.components), forms_1.forms), screens_1.screens), settings_1.settings),
    filters: Object.assign(Object.assign({}, lang_1.lang), filters_1.filters),
});
