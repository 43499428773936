"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const withActionSheetProvider_1 = require("./withActionSheetProvider");
const core_1 = require("@bluebase/core");
const react_native_action_sheet_1 = require("@expo/react-native-action-sheet");
const react_1 = require("react");
function useActionSheet() {
    const { showActionSheetWithOptions } = react_native_action_sheet_1.useActionSheet();
    const { showActionSheetOrDialogWithOptions: _showActionSheetOrDialogWithOptions } = react_1.useContext(withActionSheetProvider_1.ActionSheetDesktopContext);
    const showActionSheetOrDialogWithOptions = core_1.isMobile()
        ? showActionSheetWithOptions
        : _showActionSheetOrDialogWithOptions;
    return { showActionSheetOrDialogWithOptions, showActionSheetWithOptions };
}
exports.useActionSheet = useActionSheet;
