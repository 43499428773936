"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Currency = {
    key: 'Currency',
    dataType: 'STRING',
    /**
     * Transforms raw state value into percentage
     *
     * Example: 26 => Rs. 26
     */
    template: 'Rs. {{ value }}',
    precision: 0,
    format: (value, _schema) => {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
};
