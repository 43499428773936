"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
// Backwards compat
const components_1 = require("@bluebase/components");
exports.default = components_1.ErrorState;
var components_2 = require("@bluebase/components");
exports.ApolloError = components_2.ErrorState;
// End Backwards compat
tslib_1.__exportStar(require("./ApolloErrorComponent"), exports);
tslib_1.__exportStar(require("./withApolloError"), exports);
