"use strict";
// tslint:disable: object-literal-sort-keys
Object.defineProperty(exports, "__esModule", { value: true });
exports.BREAKPOINTS = {
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200,
};
exports.MAX_CONTAINER_WIDTH = {
    xs: '100%',
    sm: 540,
    md: 720,
    lg: 960,
    xl: 1140,
};
