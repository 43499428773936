"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const TableBody_1 = require("./TableBody");
const TableCell_1 = require("./TableCell");
const TableFooter_1 = require("./TableFooter");
const TableHead_1 = require("./TableHead");
const TablePagination_1 = require("./TablePagination");
const TableRow_1 = require("./TableRow");
const TableTitle_1 = require("./TableTitle");
const core_1 = require("@bluebase/core");
exports.TableDefaultProps = {};
// tslint:disable: jsdoc-format
/**
 * # 🏓 Table
 *
 * Data tables allow displaying sets of data.
 *
 */
exports.Table = core_1.getComponent('Table');
exports.Table.defaultProps = exports.TableDefaultProps;
exports.Table.Body = TableBody_1.TableBody;
exports.Table.Cell = TableCell_1.TableCell;
exports.Table.Footer = TableFooter_1.TableFooter;
exports.Table.Head = TableHead_1.TableHead;
exports.Table.Pagination = TablePagination_1.TablePagination;
exports.Table.Row = TableRow_1.TableRow;
exports.Table.Title = TableTitle_1.TableTitle;
