"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const RequireAuthentication_1 = require("../components/RequireAuthentication");
exports.boot = {
    'bluebase.boot.end': [
        {
            // key: 'bluebase-navigator-root-user-management',
            // priority: 20,
            value: async (bootOptions, _ctx, BB) => {
                const blacklist = BB.Configs.getValue('mevris.plugin.user-management.blacklist') || [];
                blacklist.forEach(component => BB.Components.addHocs(component, RequireAuthentication_1.withRequireAuthentication));
                return bootOptions;
            },
        },
    ],
};
