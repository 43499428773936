"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const react_1 = tslib_1.__importStar(require("react"));
const Context_1 = require("../../Context");
const react_native_1 = require("react-native");
const components_1 = require("../../components");
const hooks_1 = require("../../hooks");
exports.BlueBaseImageBackground = (props) => {
    const { source: _source } = props, rest = tslib_1.__rest(props, ["source"]);
    const BB = react_1.useContext(Context_1.BlueBaseContext);
    const Theme = hooks_1.useTheme();
    const source = components_1.resolveImageSource(props, BB, Theme);
    if (!source) {
        return props.children;
    }
    return react_1.default.createElement(react_native_1.ImageBackground, Object.assign({}, rest, { source }));
};
