"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const format_1 = require("./format");
const resolve_1 = require("./resolve");
const validate_1 = require("./validate");
/**
 * Format and validate a value
 * @param value
 * @param schema
 */
function process(value, schema) {
    const resolvedSchema = resolve_1.resolve(schema);
    const formattedValue = format_1.format(value, resolvedSchema);
    const isValid = validate_1.validate(formattedValue, resolvedSchema);
    if (!isValid) {
        throw Error('Invalid value against DataSchema.');
    }
    else {
        return formattedValue;
    }
}
exports.process = process;
