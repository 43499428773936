"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ListAvatar_1 = require("./ListAvatar");
const ListIcon_1 = require("./ListIcon");
const ListItem_1 = require("./ListItem");
const ListSubheader_1 = require("./ListSubheader");
const core_1 = require("@bluebase/core");
/**
 * # 📚 List
 *
 * A component used to group list items.
 */
exports.List = core_1.getComponent('List');
exports.List.Avatar = ListAvatar_1.ListAvatar;
exports.List.Icon = ListIcon_1.ListIcon;
exports.List.Item = ListItem_1.ListItem;
exports.List.Subheader = ListSubheader_1.ListSubheader;
