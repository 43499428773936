"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@bluebase/core");
/**
 * Converts paths from react-navigation pattern to react-router pattern
 * @param navigator
 * @param parentPath
 */
function preparePaths(navigator, parentPath = '') {
    // If routes prop is a thunk, resolve it.
    // Then map it to have new paths
    const routes = core_1.resolveThunk(navigator.routes || []).map(r => {
        // Create new path by joing current path with parent path
        const path = `/${core_1.joinPaths(parentPath, r.path)}`;
        // Do we have a navigator here, if yes then recurcively prepare its paths as well
        const resolvedNavigator = r.navigator ? preparePaths(r.navigator, path) : undefined;
        // Return the final object
        return Object.assign({}, r, { navigator: resolvedNavigator, path });
    });
    // Merge and return incoming navigator with newer routes
    return Object.assign({}, navigator, { routes });
}
exports.preparePaths = preparePaths;
