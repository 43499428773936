"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function getStyles(theme) {
    return {
        activity: {
            container: Object.assign({ backgroundColor: theme.palette.background.card, borderBottomWidth: 0, borderRadius: theme.shape.borderRadius, color: theme.palette.text.primary, margin: theme.spacing.unit }, theme.elevation(1)),
            text: {
                color: theme.palette.text.primary,
            },
            url: {
                color: theme.palette.text.secondary,
            },
        },
        userBar: {
            username: {
                color: theme.palette.text.primary,
            },
            subtitle: {
                color: theme.palette.text.secondary,
            },
            time: {
                color: theme.palette.text.secondary,
            },
        },
        flatFeed: {
            container: {
                backgroundColor: 'transparent',
                padding: theme.spacing.unit,
            },
        },
    };
}
exports.getStyles = getStyles;
