"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// tslint:disable: object-literal-sort-keys
const native_1 = require("../native");
const components_1 = require("../components/");
exports.components = {
    'bluebase.components.register': [
        /**
         * Registers all components that ship with BlueBase
         */
        {
            key: 'bluebase-components-register-internal-default',
            priority: 2,
            value: async (bootOptions, _ctx, BB) => {
                const keys = await BB.Components.registerCollection({
                    // BlueBase Components
                    BlueBaseContent: components_1.BlueBaseContent,
                    BlueBaseFilter: { applyStyles: false, value: components_1.BlueBaseFilter },
                    BlueBaseImage: components_1.BlueBaseImage,
                    BlueBaseImageBackground: components_1.BlueBaseImageBackground,
                    ComponentState: components_1.ComponentState,
                    DataObserver: components_1.DataObserver,
                    DynamicIcon: components_1.DynamicIcon,
                    EmptyState: components_1.EmptyState,
                    ErrorObserver: components_1.ErrorObserver,
                    ErrorState: components_1.ErrorState,
                    FormattedMessage: components_1.FormattedMessage,
                    HomeScreen: components_1.HomeScreen,
                    HoverObserver: components_1.HoverObserver,
                    Icon: components_1.Noop,
                    Link: components_1.Link,
                    LoadingState: components_1.LoadingState,
                    Navigation: components_1.Navigation,
                    NavigationActions: components_1.NavigationActions,
                    Noop: { applyStyles: false, value: components_1.Noop },
                    PluginIcon: components_1.PluginIcon,
                    Redirect: { applyStyles: false, value: components_1.Redirect },
                    StatefulComponent: components_1.StatefulComponent,
                    SystemLayout: components_1.SystemLayout,
                    TouchableItem: components_1.TouchableItem,
                    WaitObserver: components_1.WaitObserver,
                    // Native
                    ActivityIndicator: native_1.ActivityIndicator,
                    Button: native_1.Button,
                    FlatList: native_1.FlatList,
                    Image: native_1.Image,
                    ImageBackground: native_1.ImageBackground,
                    Picker: native_1.Picker,
                    SafeAreaView: native_1.SafeAreaView,
                    ScrollView: native_1.ScrollView,
                    Switch: native_1.Switch,
                    Text: native_1.Text,
                    TextInput: native_1.TextInput,
                    View: { applyStyles: false, value: native_1.View },
                    // Typography
                    H1: {
                        value: native_1.Text,
                        styles: (theme) => ({ root: theme.typography.h1 }),
                    },
                    H2: {
                        value: native_1.Text,
                        styles: (theme) => ({ root: theme.typography.h2 }),
                    },
                    H3: {
                        value: native_1.Text,
                        styles: (theme) => ({ root: theme.typography.h3 }),
                    },
                    H4: {
                        value: native_1.Text,
                        styles: (theme) => ({ root: theme.typography.h4 }),
                    },
                    H5: {
                        value: native_1.Text,
                        styles: (theme) => ({ root: theme.typography.h5 }),
                    },
                    H6: {
                        value: native_1.Text,
                        styles: (theme) => ({ root: theme.typography.h6 }),
                    },
                    Subtitle1: {
                        value: native_1.Text,
                        styles: (theme) => ({ root: theme.typography.subtitle1 }),
                    },
                    Subtitle2: {
                        value: native_1.Text,
                        styles: (theme) => ({ root: theme.typography.subtitle2 }),
                    },
                    Body1: {
                        value: native_1.Text,
                        styles: (theme) => ({ root: theme.typography.body1 }),
                    },
                    Body2: {
                        value: native_1.Text,
                        styles: (theme) => ({ root: theme.typography.body2 }),
                    },
                    Caption: {
                        value: native_1.Text,
                        styles: (theme) => ({ root: theme.typography.caption }),
                    },
                    Overline: {
                        value: native_1.Text,
                        styles: (theme) => ({ root: theme.typography.overline }),
                    },
                });
                keys.forEach(key => BB.Components.setMeta(key, 'source', { type: 'system' }));
                return bootOptions;
            },
        },
        /**
         * This filter registers components from bootOptions.components property.
         * These are the components typically set in the bluebase.js file.
         */
        {
            key: 'bluebase-components-register-default',
            priority: 3,
            value: async (bootOptions, _ctx, BB) => {
                const keys = await BB.Components.registerCollection(bootOptions.components);
                keys.forEach(key => BB.Components.setMeta(key, 'source', { type: 'boot' }));
                return bootOptions;
            },
        },
        /**
         * Registers all components in the plugins
         */
        {
            key: 'bluebase-components-register-from-plugins',
            priority: 4,
            value: async (bootOptions, _ctx, BB) => {
                const plugins = await BB.Plugins.getAllEnabled();
                for (const plugin of plugins) {
                    const keys = await BB.Components.registerCollection(plugin.components);
                    keys.forEach(key => BB.Components.setMeta(key, 'source', { type: 'plugin', key: plugin.key }));
                }
                return bootOptions;
            },
        },
    ],
};
