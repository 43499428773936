"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const themes_1 = require("../themes");
exports.themes = {
    'bluebase.themes.register': [
        /**
         * Registers all themes that ship with BlueBase
         */
        {
            key: 'bluebase-themes-register-internal-default',
            priority: 2,
            value: async (bootOptions, _ctx, BB) => {
                const keys = await BB.Themes.registerCollection([themes_1.BlueBaseLightTheme, themes_1.BlueBaseDarkTheme]);
                keys.forEach(key => BB.Themes.setMeta(key, 'source', { type: 'system' }));
                return bootOptions;
            },
        },
        /**
         * Registers all themes in the bluebase.ts
         */
        {
            key: 'bluebase-themes-register-default',
            priority: 3,
            value: async (bootOptions, _ctx, BB) => {
                const keys = await BB.Themes.registerCollection(bootOptions.themes);
                keys.forEach(key => BB.Themes.setMeta(key, 'source', { type: 'boot' }));
                return bootOptions;
            },
        },
        /**
         * Registers all themes in the plugins
         */
        {
            key: 'bluebase-themes-register-from-plugins',
            priority: 4,
            value: async (bootOptions, _ctx, BB) => {
                const plugins = await BB.Plugins.getAllEnabled();
                for (const plugin of plugins) {
                    const keys = await BB.Themes.registerCollection(plugin.themes);
                    keys.forEach(key => BB.Themes.setMeta(key, 'source', { type: 'plugin', key: plugin.key }));
                }
                return bootOptions;
            },
        },
    ],
};
