"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Integer = {
    key: 'Integer',
    dataType: 'NUMBER',
    /**
     * Returns value as it is
     *
     * Example: 50 => 50
     */
    template: '{{ value }}',
    precision: 0,
};
