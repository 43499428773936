"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@bluebase/core");
exports.BottomNavigationActionDefaultProps = {};
/**
 * # 🗂 BottomNavigationAction
 * A single tab component in the Bottom Navigation.
 */
exports.BottomNavigationAction = core_1.getComponent('BottomNavigationAction');
exports.BottomNavigationAction.defaultProps = exports.BottomNavigationActionDefaultProps;
