"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useBlueBase_1 = require("./useBlueBase");
function useFilter(name, initialValue, args, deps = []) {
    const BB = useBlueBase_1.useBlueBase();
    const [value, setValue] = react_1.useState(initialValue);
    const [loading, setLoading] = react_1.useState(true);
    const [error, setError] = react_1.useState();
    let cancelled = false;
    react_1.useEffect(() => {
        setLoading(true);
        (async () => {
            try {
                const result = await BB.Filters.run(name, initialValue, args);
                if (!cancelled) {
                    setValue(result);
                    setLoading(false);
                }
            }
            catch (error) {
                if (!cancelled) {
                    setError(error);
                    setLoading(false);
                }
            }
        })();
        // Unsubscribe from config updates
        return () => {
            cancelled = true;
        };
    }, [name, ...deps]);
    return { value, loading, error };
}
exports.useFilter = useFilter;
