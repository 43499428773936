"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Registry_1 = require("./Registry");
const helpers_1 = require("./helpers");
/**
 * 🔠 FontRegistry
 */
class FontRegistry extends Registry_1.Registry {
    async load() {
        const items = this.filterValues(value => !!value);
        await helpers_1.loadAllFonts(items);
    }
    /**
     * Convert any input value to an item. This is where you transform inputs and add defualts
     * @param key
     * @param partial
     */
    createItem(key, partial) {
        return super.createItem(key, Object.assign({ preload: false }, partial));
    }
    /**
     * Typeguard to check a given object is a registry value
     * @param value
     */
    isValue(value) {
        return typeof value === 'number' || typeof value === 'string';
    }
    /**
     * Typeguard to check a given object is an input value
     * @param value
     */
    isInputValue(value) {
        return this.isValue(value);
    }
}
exports.FontRegistry = FontRegistry;
