Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setAndroidTranslucentStatusBar = exports.registerNativeHandlers = exports.humanizeTimestamp = exports.buildStylesheet = exports.getStyle = exports.updateStyle = exports.IconBadge = exports.NewActivitiesNotification = exports.LikeButton = exports.Activity = exports.BackButton = exports.LikeList = exports.CommentList = exports.CommentItem = exports.CommentBox = exports.SectionHeader = exports.ReactionList = exports.Card = exports.CommentsContainer = exports.ReactionIconBar = exports.ReactionToggleIcon = exports.ReactionIcon = exports.UserCard = exports.UserBar = exports.UploadImage = exports.StatusUpdateForm = exports.UrlPreview = exports.FollowButton = exports.Avatar = exports.SinglePost = exports.NotificationFeed = exports.FlatFeed = exports.StreamApp = undefined;

var _Context = require('./Context');

Object.defineProperty(exports, 'StreamApp', {
  enumerable: true,
  get: function get() {
    return _Context.StreamApp;
  }
});

var _types = require('./types');

Object.keys(_types).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _types[key];
    }
  });
});

var _FlatFeed = require('./components/FlatFeed');

Object.defineProperty(exports, 'FlatFeed', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_FlatFeed).default;
  }
});

var _NotificationFeed = require('./components/NotificationFeed');

Object.defineProperty(exports, 'NotificationFeed', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_NotificationFeed).default;
  }
});

var _SinglePost = require('./components/SinglePost');

Object.defineProperty(exports, 'SinglePost', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_SinglePost).default;
  }
});

var _Avatar = require('./components/Avatar');

Object.defineProperty(exports, 'Avatar', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_Avatar).default;
  }
});

var _FollowButton = require('./components/FollowButton');

Object.defineProperty(exports, 'FollowButton', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_FollowButton).default;
  }
});

var _UrlPreview = require('./components/UrlPreview');

Object.defineProperty(exports, 'UrlPreview', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_UrlPreview).default;
  }
});

var _StatusUpdateForm = require('./components/StatusUpdateForm');

Object.defineProperty(exports, 'StatusUpdateForm', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_StatusUpdateForm).default;
  }
});

var _UploadImage = require('./components/UploadImage');

Object.defineProperty(exports, 'UploadImage', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_UploadImage).default;
  }
});

var _UserBar = require('./components/UserBar');

Object.defineProperty(exports, 'UserBar', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_UserBar).default;
  }
});

var _UserCard = require('./components/UserCard');

Object.defineProperty(exports, 'UserCard', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_UserCard).default;
  }
});

var _ReactionIcon = require('./components/ReactionIcon');

Object.defineProperty(exports, 'ReactionIcon', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_ReactionIcon).default;
  }
});

var _ReactionToggleIcon = require('./components/ReactionToggleIcon');

Object.defineProperty(exports, 'ReactionToggleIcon', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_ReactionToggleIcon).default;
  }
});

var _ReactionIconBar = require('./components/ReactionIconBar');

Object.defineProperty(exports, 'ReactionIconBar', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_ReactionIconBar).default;
  }
});

var _CommentsContainer = require('./components/CommentsContainer');

Object.defineProperty(exports, 'CommentsContainer', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_CommentsContainer).default;
  }
});

var _Card = require('./components/Card');

Object.defineProperty(exports, 'Card', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_Card).default;
  }
});

var _ReactionList = require('./components/ReactionList');

Object.defineProperty(exports, 'ReactionList', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_ReactionList).default;
  }
});

var _SectionHeader = require('./components/SectionHeader');

Object.defineProperty(exports, 'SectionHeader', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_SectionHeader).default;
  }
});

var _CommentBox = require('./components/CommentBox');

Object.defineProperty(exports, 'CommentBox', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_CommentBox).default;
  }
});

var _CommentItem = require('./components/CommentItem');

Object.defineProperty(exports, 'CommentItem', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_CommentItem).default;
  }
});

var _CommentList = require('./components/CommentList');

Object.defineProperty(exports, 'CommentList', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_CommentList).default;
  }
});

var _LikeList = require('./components/LikeList');

Object.defineProperty(exports, 'LikeList', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_LikeList).default;
  }
});

var _BackButton = require('./components/BackButton');

Object.defineProperty(exports, 'BackButton', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_BackButton).default;
  }
});

var _Activity = require('./components/Activity');

Object.defineProperty(exports, 'Activity', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_Activity).default;
  }
});

var _LikeButton = require('./components/LikeButton');

Object.defineProperty(exports, 'LikeButton', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_LikeButton).default;
  }
});

var _NewActivitiesNotification = require('./components/NewActivitiesNotification');

Object.defineProperty(exports, 'NewActivitiesNotification', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_NewActivitiesNotification).default;
  }
});

var _IconBadge = require('./components/IconBadge');

Object.defineProperty(exports, 'IconBadge', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_IconBadge).default;
  }
});

var _styles = require('./styles');

Object.defineProperty(exports, 'updateStyle', {
  enumerable: true,
  get: function get() {
    return _styles.updateStyle;
  }
});
Object.defineProperty(exports, 'getStyle', {
  enumerable: true,
  get: function get() {
    return _styles.getStyle;
  }
});
Object.defineProperty(exports, 'buildStylesheet', {
  enumerable: true,
  get: function get() {
    return _styles.buildStylesheet;
  }
});

var _utils = require('./utils');

Object.defineProperty(exports, 'humanizeTimestamp', {
  enumerable: true,
  get: function get() {
    return _utils.humanizeTimestamp;
  }
});

var _native = require('./native');

Object.defineProperty(exports, 'registerNativeHandlers', {
  enumerable: true,
  get: function get() {
    return _native.registerNativeHandlers;
  }
});
Object.defineProperty(exports, 'setAndroidTranslucentStatusBar', {
  enumerable: true,
  get: function get() {
    return _native.setAndroidTranslucentStatusBar;
  }
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

if (global.Expo) {
  (0, _native.setAndroidTranslucentStatusBar)(true);
} else {
  (0, _native.setAndroidTranslucentStatusBar)(false);
}