"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Connection = {
    key: 'Connection',
    extends: 'Boolean',
    /**
     * Transforms raw state value to human readable string
     *
     * Example:
     *
     *
     */
    template: '{{ value }}',
    values: [
        {
            label: 'Online',
            value: true,
        },
        {
            label: 'Offline',
            value: false,
        },
    ],
};
