"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.components = {
    CameraAskPermissionView: Promise.resolve().then(() => __importStar(require('./CameraAskPermissionView'))),
    CameraPermission: Promise.resolve().then(() => __importStar(require('./CameraPermission'))),
    CameraPermissionDeniedView: Promise.resolve().then(() => __importStar(require('./CameraPermissionDeniedView'))),
    CameraRollPermission: Promise.resolve().then(() => __importStar(require('./CameraRollPermission'))),
    ConfirmationDialog: Promise.resolve().then(() => __importStar(require('./ConfirmationDialog'))),
    LocateMeButton: Promise.resolve().then(() => __importStar(require('./LocateMeButton'))),
    LocationAskPermissionView: Promise.resolve().then(() => __importStar(require('./LocationAskPermissionView'))),
    LocationPermission: Promise.resolve().then(() => __importStar(require('./LocationPermission'))),
    LocationPermissionDeniedView: Promise.resolve().then(() => __importStar(require('./LocationPermissionDeniedView'))),
    Permission: Promise.resolve().then(() => __importStar(require('./Permission'))),
};
