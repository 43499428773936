"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const validate_1 = require("./validate");
function validateDataType(value, schema) {
    const dataType = schema.dataType;
    if (!dataType) {
        throw new Error('Invalid data type');
    }
    const validationFn = validate_1.validate[dataType];
    if (!validationFn) {
        throw new Error('Invalid data type');
    }
    return validationFn(value, schema);
}
exports.validateDataType = validateDataType;
