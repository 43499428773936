"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const createDesktopNavigator_1 = require("./createDesktopNavigator");
const createMobileNavigator_1 = require("./createMobileNavigator");
const core_1 = require("@bluebase/core");
exports.createSettingsRoutes = ({ mainRoute, pages, filter = 'bluebase.plugin.setting-app', }) => {
    return core_1.isMobile()
        ? createMobileNavigator_1.createMobileRoutes({ mainRoute, pages, filter })
        : [Object.assign(Object.assign({}, mainRoute), { navigator: createDesktopNavigator_1.createDesktopNavigator({ mainRoute, pages, filter }) })];
};
