"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
class UniversalDeviceController {
    constructor(key, options = {}) {
        // super(options);
        this.key = key;
        /**
         * An array of middleware
         */
        this.middlewareFns = [];
        this.defaultControllerPreference = 'local-first';
        if (options.localController) {
            this.localController = options.localController;
        }
        if (options.remoteController) {
            this.remoteController = options.remoteController;
        }
    }
    /**
     * Initialize Device Controller
     */
    async init() {
        // const promises = [];
        // if (this.localController) {
        // 	promises.push(this.localController.init());
        // }
        // if (this.remoteController) {
        // 	promises.push(this.remoteController.init());
        // }
        // await Promise.all(promises);
        const promises = [
            this.localController && this.localController.init(),
            this.remoteController && this.remoteController.init(),
        ].filter(x => !!x);
        await Promise.all(promises);
        return { status: 'success' };
    }
    /**
     * Kill all process and services
     */
    async kill() {
        if (this.localController) {
            this.localController.kill();
        }
        if (this.remoteController) {
            this.remoteController.kill();
        }
        return { status: 'success' };
    }
    /**
     * Does this universal device controller have a local controller?
     *
     * @returns {boolean}
     */
    hasLocalController() {
        return !!this.localController;
    }
    /**
     * Does this universal device controller have a remote controller?
     *
     * @returns {boolean}
     */
    hasRemoteController() {
        return !!this.remoteController;
    }
    /**
     * Checks if a device is connected in the given mode.
     * @param options
     */
    async isConnected(options = {}) {
        return this.executeWithControllerPreference('isConnected', Object.assign({ controllerPreference: this.defaultControllerPreference }, options));
    }
    /**
     * Fetches the device's state
     * @param options
     */
    async getState(options = {}) {
        return this.executeWithControllerPreference('getState', Object.assign({ controllerPreference: this.defaultControllerPreference }, options));
    }
    /**
     * Tells the device to apply new state
     * @param options
     */
    async setState(options) {
        if (!options || !options.state) {
            throw Error('state property is required in options object.');
        }
        return this.executeWithControllerPreference('setState', Object.assign({ controllerPreference: this.defaultControllerPreference }, options));
    }
    /**
     * Sends a command to a device
     * @param options
     */
    async sendCommand(options) {
        if (!options || !options.name) {
            throw Error('Command name is required!');
        }
        return this.executeWithControllerPreference('sendCommand', Object.assign({ controllerPreference: this.defaultControllerPreference }, options));
    }
    /**
     * Sends configurations object to a device
     * @param options
     */
    async setConfigs(options) {
        if (!options || !options.configs) {
            throw Error('configs property is required in options object.');
        }
        return this.executeWithControllerPreference('setConfigs', Object.assign({ controllerPreference: this.defaultControllerPreference }, options));
    }
    /**
     * Subscribe to state updates of this device
     */
    subscribe(callback, options) {
        if (this.localController) {
            this.localController.subscribe(callback, options);
        }
        if (this.remoteController) {
            this.remoteController.subscribe(callback, options);
        }
        return { status: 'success' };
    }
    /**
     * Unsubscribe from state updates of this device
     */
    unsubscribe() {
        if (this.localController) {
            this.localController.unsubscribe();
        }
        if (this.remoteController) {
            this.remoteController.unsubscribe();
        }
        return { status: 'success' };
    }
    /**
     * Adds middleware functions to this universal device controller
     * @param middlewareFns
     */
    use(...middlewareFns) {
        this.middlewareFns = this.middlewareFns.concat(middlewareFns);
    }
    /**
     * Run all middleware functions in a waterfall.
     * Each middleware function is expected to return a value.
     *
     * @param event
     */
    async applyMiddlewares(event) {
        let value = event;
        // If there are no filter items registered for this filter
        if (this.middlewareFns.length === 0) {
            return value;
        }
        try {
            // Run waterfall
            for (const middleware of this.middlewareFns) {
                // Check if handler is a valid function
                if (typeof middleware !== 'function') {
                    continue;
                    // throw Error('Universal Device Controller Middleware must be a function!');
                }
                // Execute filter function
                const result = await middleware(value);
                // If the filter didn't return any value, return previous accumulator
                if (typeof result !== 'undefined') {
                    value = result;
                }
            }
        }
        catch (error) {
            //
        }
        return value;
    }
    async executeWithControllerPreference(action, options, ...params) {
        if (options.controllerPreference === 'local-only') {
            return this.executeLocalOnly(action, options, ...params);
        }
        else if (options.controllerPreference === 'remote-only') {
            return this.executeRemoteOnly(action, options, ...params);
        }
        else if (options.controllerPreference === 'local-first') {
            return this.executeLocalFirst(action, options, ...params);
        }
        else if (options.controllerPreference === 'remote-first') {
            return this.executeRemoteFirst(action, options, ...params);
        }
        else if (options.controllerPreference === 'local-and-remote') {
            return this.executeLocalAndRemote(action, options, ...params);
        }
        throw Error(`Unknown controller preference: ${options.controllerPreference}`);
    }
    async executeLocalOnly(action, options, ...params) {
        // If we don't have a controller, return
        if (!this.localController) {
            return { status: 'error', error: Error('No Local controller found.') };
        }
        // Find action function
        const fn = this.localController[action];
        // If action function doesn't exist, throw error
        if (!fn) {
            return {
                error: Error(`UDC could not execute ${action} function. Reason: Unknown function`),
                status: 'error',
            };
        }
        // Prepare middleware props
        const req = { action, options };
        const res = { controllerType: 'local', controller: this };
        const { controllerPreference } = options, opts = tslib_1.__rest(options, ["controllerPreference"]);
        try {
            // Execute action
            const data = await fn(opts, ...params);
            // Apply Middleware
            const event = await this.applyMiddlewares({ req, res: Object.assign(Object.assign({}, res), data) });
            // Return response data
            return event.res;
        }
        catch (error) {
            // Apply middleware on error
            const event = await this.applyMiddlewares({ req, res: Object.assign(Object.assign({}, res), { error, status: 'error' }) });
            // Throw error
            return event.res;
        }
    }
    async executeRemoteOnly(action, options, ...params) {
        // If we don't have a controller, return
        if (!this.remoteController) {
            return { status: 'error', error: Error('No Remote controller found.') };
        }
        // Find action function
        const fn = this.remoteController[action];
        // If action function doesn't exist, throw error
        if (!fn) {
            return {
                error: Error(`UDC could not execute ${action} function. Reason: Unknown function`),
                status: 'error',
            };
        }
        // Prepare middleware props
        const req = { action, options };
        const res = { controllerType: 'remote', controller: this };
        const { controllerPreference } = options, opts = tslib_1.__rest(options, ["controllerPreference"]);
        try {
            // Execute action
            const data = await fn(opts, ...params);
            // Apply Middleware
            const event = await this.applyMiddlewares({ req, res: Object.assign(Object.assign({}, res), data) });
            // Return response data
            return event.res;
        }
        catch (error) {
            // Apply middleware on error
            const event = await this.applyMiddlewares({ req, res: Object.assign(Object.assign({}, res), { error, status: 'error' }) });
            // Throw error
            return event.res;
        }
    }
    async executeLocalFirst(action, options, ...params) {
        let response = await this.executeLocalOnly(action, options, ...params);
        // If success, then return
        // If not success, and also, there is no remote controller, return this error
        if (response.status === 'success' || !this.remoteController) {
            return response;
        }
        response = await this.executeRemoteOnly(action, options, ...params);
        return response;
    }
    async executeRemoteFirst(action, options, ...params) {
        let response = await this.executeRemoteOnly(action, options, ...params);
        // If success, then return
        // If not success, and also, there is no local controller, return this error
        if (response.status === 'success' || !this.localController) {
            return response;
        }
        response = await this.executeLocalOnly(action, options, ...params);
        return response;
    }
    async executeLocalAndRemote(action, options, ...params) {
        this.executeLocalOnly(action, options, ...params);
        this.executeRemoteOnly(action, options, ...params);
        return { status: 'unknown' };
    }
}
exports.UniversalDeviceController = UniversalDeviceController;
