"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const react_1 = require("react");
/**
 * Updates device state whenever app becomes active from inactive or background state
 * @param device
 */
function useDeviceSyncOnAppStateUpdates(device) {
    const [appState, setAppState] = react_1.useState(react_native_1.AppState.currentState);
    react_1.useEffect(() => {
        function handleAppStateChange(nextAppState) {
            if (!device) {
                return;
            }
            if (appState.match(/inactive|background/) && nextAppState === 'active') {
                device.getState();
            }
            setAppState(nextAppState);
        }
        react_native_1.AppState.addEventListener('change', handleAppStateChange);
        return () => react_native_1.AppState.removeEventListener('change', handleAppStateChange);
    }, [appState, device]);
}
exports.useDeviceSyncOnAppStateUpdates = useDeviceSyncOnAppStateUpdates;
