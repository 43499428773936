"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@bluebase/core");
exports.LinkDefaultProps = {
    method: 'navigate',
};
/**
 * 🔗 Link
 */
exports.Link = core_1.getComponent('Link');
exports.Link.defaultProps = exports.LinkDefaultProps;
