"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const version_1 = require("./version");
const components_1 = require("./components");
const core_1 = require("@bluebase/core");
const lang_1 = require("./lang");
tslib_1.__exportStar(require("./exports"), exports);
exports.default = core_1.createPlugin({
    description: 'The plugin provides common UI across Mevris Apps.',
    key: 'client-plugin-ui',
    name: 'Mevris UI',
    version: version_1.VERSION,
    assets: {
        PlaceListEmptyImage_desktop_dark: require('../assets/common/places-empty-state_desktop_dark.png'),
        PlaceListEmptyImage_desktop_light: require('../assets/common/places-empty-state_desktop_light.png'),
        PlaceListEmptyImage_mobile_dark: require('../assets/common/places-empty-state_desktop_dark.png'),
        PlaceListEmptyImage_mobile_light: require('../assets/common/places-empty-state_desktop_light.png'),
        PlaceTypeListEmptyImage: require('../assets/common/site-not-found.png'),
        ProductListEmptyImage: require('../assets/common/site-not-found.png'),
        ThingListEmptyImage_desktop_dark: require('../assets/common/device-not-found_desktop_dark.png'),
        ThingListEmptyImage_desktop_light: require('../assets/common/device-not-found_desktop_light.png'),
        ThingListEmptyImage_mobile_dark: require('../assets/common/device-not-found_desktop_dark.png'),
        ThingListEmptyImage_mobile_light: require('../assets/common/device-not-found_desktop_light.png'),
        // Placeholders
        PersonPlaceholder: require('../assets/common/person-placeholder.png'),
        PersonPlaceholder_dark: require('../assets/common/person-placeholder-dark.png'),
        PlacePlaceholder: require('../assets/common/place-placeholder.png'),
        PlacePlaceholder_dark: require('../assets/common/place-placeholder-dark.png'),
        ProductPlaceholder: require('../assets/common/product-placeholder.png'),
        ProductPlaceholder_dark: require('../assets/common/product-placeholder-dark.png'),
        ThingPlaceholder: require('../assets/common/thing-placeholder.png'),
        ThingPlaceholder_dark: require('../assets/common/thing-placeholder-dark.png'),
    },
    components: Object.assign({}, components_1.components),
    filters: Object.assign({}, lang_1.lang),
});
