"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultConfigs = {
    'mevris.plugin.taskbar.mobile.bottom-navigation.items': ['home', 'products', 'settings'],
    'mevris.plugin.taskbar.mobile.bottom-navigation.configs': {
        home: {
            path: 'places',
            navigationOptions: {
                tabBarIcon: 'home',
                title: 'Home',
            },
        },
        products: {
            navigationOptions: {
                tabBarIcon: 'store',
                title: 'Marketplace',
            },
        },
        settings: {
            navigationOptions: {
                tabBarIcon: 'settings',
                title: 'Settings',
            },
        },
    },
};
