"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@bluebase/core");
exports.RadioGroup = core_1.getComponent('RadioGroup');
/**
 * Default props for RadioGroup component
 */
exports.RadioGroupDefaultProps = {
    onValueChange: () => {
        return;
    },
};
