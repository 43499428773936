"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadFont = (fontName, font) => {
    const iconFontStyles = `@font-face {
		src: url(${font});
		font-family: ${fontName};
	}`;
    // Create stylesheet
    const style = document.createElement('style');
    style.type = 'text/css';
    if (style.styleSheet) {
        style.styleSheet.cssText = iconFontStyles;
    }
    else {
        style.appendChild(document.createTextNode(iconFontStyles));
    }
    // Inject stylesheet
    document.head.appendChild(style);
};
exports.loadAllFonts = async (fonts) => {
    Object.keys(fonts).forEach(fontName => {
        exports.loadFont(fontName, fonts[fontName]);
    });
};
