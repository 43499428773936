"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const react_1 = tslib_1.__importStar(require("react"));
const core_1 = require("@bluebase/core");
exports.ThemeSelectionSetting = () => {
    const BB = core_1.useBlueBase();
    const { __ } = core_1.useIntl();
    const { theme, changeTheme } = core_1.useTheme();
    const themes = [...BB.Themes.entries()];
    const [visible, setVisible] = react_1.useState(false);
    const toggle = () => setVisible(!visible);
    const select = (slug) => () => {
        changeTheme(slug);
        toggle();
    };
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(components_1.List.Item, { left: react_1.default.createElement(components_1.List.Icon, { name: "brush" }), title: __('Themes'), description: __(theme.name), onPress: toggle }),
        react_1.default.createElement(components_1.Dialog, { visible: visible, onDismiss: toggle },
            react_1.default.createElement(components_1.List.Subheader, null, __('Available Themes')),
            react_1.default.createElement(components_1.Divider, null),
            themes.map(item => {
                return (react_1.default.createElement(components_1.List.Item, { title: __(item[1].name), onPress: select(item[0]), key: item[0], selected: theme.name === item[0] }));
            }))));
};
