"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const components_1 = require("@bluebase/components");
exports.NotFoundError = components_1.getComponentWithFallback({
    moduleName: '@blueeast/client-plugin-ui',
    name: 'NotFoundError',
})('NotFoundError');
exports.Placeholder = components_1.getComponentWithFallback({
    moduleName: '@bluebase/plugin-rn-placeholder',
    name: 'Placeholder',
})('Placeholder');
exports.PlaceholderH6 = components_1.getComponentWithFallback({
    moduleName: '@bluebase/plugin-rn-placeholder',
    name: 'PlaceholderH6',
})('PlaceholderH6');
exports.PlaceholderSubtitle2 = components_1.getComponentWithFallback({
    moduleName: '@bluebase/plugin-rn-placeholder',
    name: 'PlaceholderSubtitle2',
})('PlaceholderSubtitle2');
exports.SidebarLayout = components_1.getComponentWithFallback({
    moduleName: '@blueeast/client-plugin-ui',
    name: 'SidebarLayout',
})('SidebarLayout');
