"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@bluebase/core");
exports.Slider = core_1.getComponent('Slider');
/**
 * Default props for Slider component
 */
exports.SliderDefaultProps = {
    disabled: false,
    maximumValue: 1,
    minimumValue: 0,
    step: 0,
    value: 0,
};
