Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/NewActivitiesNotification.js';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _reactNative = require('react-native');

var _styles = require('../styles');

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var NewActivitiesNotification = function (_React$Component) {
  _inherits(NewActivitiesNotification, _React$Component);

  function NewActivitiesNotification() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, NewActivitiesNotification);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = NewActivitiesNotification.__proto__ || Object.getPrototypeOf(NewActivitiesNotification)).call.apply(_ref, [this].concat(args))), _this), _this._labelFunction = function () {
      var _this$props = _this.props,
          adds = _this$props.adds,
          deletes = _this$props.deletes,
          labelSingular = _this$props.labelSingular,
          labelPlural = _this$props.labelPlural,
          labelFunction = _this$props.labelFunction;

      var addCount = (adds || []).length;
      var deleteCount = (deletes || []).length;
      var count = addCount + deleteCount;
      if (labelFunction) {
        return labelFunction({
          count: count,
          addCount: addCount,
          deleteCount: deleteCount,
          labelSingular: labelSingular,
          labelPlural: labelPlural
        });
      }
      if (addCount === 0) {
        return null;
      }
      return 'You have ' + addCount + ' new ' + (addCount > 1 ? labelPlural : labelSingular);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(NewActivitiesNotification, [{
    key: 'render',
    value: function render() {
      var styles = (0, _styles.buildStylesheet)('pagerBlock', this.props.styles);
      var label = this._labelFunction();
      return label != null ? React.createElement(
        _reactNative.TouchableOpacity,
        { style: [styles.container], onPress: this.props.onPress, __source: {
            fileName: _jsxFileName,
            lineNumber: 68
          }
        },
        React.createElement(
          _reactNative.Text,
          { style: [styles.text], __source: {
              fileName: _jsxFileName,
              lineNumber: 69
            }
          },
          label
        )
      ) : null;
    }
  }]);

  return NewActivitiesNotification;
}(React.Component);

NewActivitiesNotification.defaultProps = {
  labelSingular: 'activity',
  labelPlural: 'activities'
};
exports.default = NewActivitiesNotification;