"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const utils_1 = require("../../utils");
const stubAction = () => {
    return;
};
exports.StubNavigationActionsObject = {
    getParam: stubAction,
    goBack: stubAction,
    navigate: stubAction,
    pop: stubAction,
    push: stubAction,
    replace: stubAction,
    setParams: stubAction,
    source: null,
    state: {
        key: '',
        params: {},
        routeName: '',
        url: '',
    },
};
exports.NavigationContext = react_1.createContext(exports.StubNavigationActionsObject);
function useNavigation() {
    return react_1.useContext(exports.NavigationContext);
}
exports.useNavigation = useNavigation;
exports.NavigationActions = ({ children }) => {
    const actions = useNavigation();
    return utils_1.renderChildrenWithProps(children, actions);
};
