"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const react_native_action_sheet_1 = require("@expo/react-native-action-sheet");
const react_1 = tslib_1.__importStar(require("react"));
const ActionSheetDesktop_1 = require("./ActionSheetDesktop");
const StubFn = () => null;
const DEFAULT_ACTIONSHEET_PARAMS = {
    callback: StubFn,
    options: [],
};
exports.ActionSheetDesktopContext = react_1.createContext({ showActionSheetOrDialogWithOptions: StubFn });
exports.withActionSheetProvider = (Component) => {
    const ActionSheetUniversalProvider = (props) => {
        const [dialogVisible, setDialogVisible] = react_1.useState(false);
        const [actionSheetParams, setActionSheetParams] = react_1.useState(DEFAULT_ACTIONSHEET_PARAMS);
        const showActionSheetOrDialogWithOptions = (options, callback) => {
            setActionSheetParams(Object.assign(Object.assign({}, options), { callback: index => {
                    callback(index);
                    setDialogVisible(false);
                } }));
            setDialogVisible(true);
        };
        const dismiss = () => {
            setDialogVisible(false);
        };
        return (react_1.default.createElement(exports.ActionSheetDesktopContext.Provider, { value: { showActionSheetOrDialogWithOptions } },
            react_1.default.createElement(ActionSheetDesktop_1.ActionSheetDesktop, Object.assign({ dismissable: true, onDismiss: dismiss, visible: dialogVisible }, actionSheetParams)),
            react_1.default.createElement(react_native_action_sheet_1.ActionSheetProvider, null,
                react_1.default.createElement(Component, Object.assign({}, props)))));
    };
    ActionSheetUniversalProvider.displayName = 'ActionSheetUniversalProvider';
    return ActionSheetUniversalProvider;
};
