"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.components = {
    AppearanceSettingList: Promise.resolve().then(() => __importStar(require('./AppearanceSettingList'))),
    InformationSettingList: Promise.resolve().then(() => __importStar(require('./InformationSettingList'))),
    LanguageSettingList: Promise.resolve().then(() => __importStar(require('./LanguageSettingList'))),
    LegalSettingList: Promise.resolve().then(() => __importStar(require('./LegalSettingList'))),
    SupportSettingList: Promise.resolve().then(() => __importStar(require('./SupportSettingList'))),
    LinkingSettingItem: Promise.resolve().then(() => __importStar(require('./LinkingSettingItem'))),
    PrivacyPolicy: Promise.resolve().then(() => __importStar(require('./PrivacyPolicy'))),
    SettingList: Promise.resolve().then(() => __importStar(require('./SettingList'))),
    SettingsAppIcon: Promise.resolve().then(() => __importStar(require('./SettingsAppIcon'))),
    TermsOfService: Promise.resolve().then(() => __importStar(require('./TermsOfService'))),
};
