"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThermostatSetpointValue = {
    key: 'ThermostatSetpointValue',
    dataType: 'NUMBER',
    /**
     * Returns value as it is
     *
     * Example: 22 => 22
     */
    template: '{{ value }}',
    min: 16,
    max: 32,
    step: 1,
    precision: 0,
};
