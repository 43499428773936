"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const React = tslib_1.__importStar(require("react"));
const formik_1 = require("formik");
class FormikEffect extends React.Component {
    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.formik.values) !== JSON.stringify(this.props.formik.values)) {
            // if (prevProps.formik !== this.props.formik) {
            this.props.onChange(this.props.formik, prevProps.formik);
        }
    }
    render() {
        return null;
    }
}
exports.default = formik_1.connect(FormikEffect);
