"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const utils_1 = require("./utils");
exports.BlueBaseDefaultConfigs = {
    debug: !utils_1.isProduction(),
    development: !utils_1.isProduction(),
    direction: 'auto',
    locale: 'en',
    'locale.options': {
        en: 'English',
        ur: 'اُردُو',
    },
    pluginRoutePathPrefix: 'p',
    statusBarStyle: 'light-content',
    'theme.mode': 'light',
    'theme.name': 'bluebase-light',
    'theme.overrides': {},
    title: 'BlueBase',
};
