"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const BlueBase_1 = require("../../BlueBase");
const __1 = require("..");
const react_1 = tslib_1.__importStar(require("react"));
const Context_1 = require("../../Context");
const components_1 = require("../../components");
const intl_1 = require("../../intl");
const themes_1 = require("../../themes");
const getComponent_1 = require("../../getComponent");
const useExceptionHandlingOnProduction_1 = require("./useExceptionHandlingOnProduction");
const BlueBaseContent = getComponent_1.getComponent('BlueBaseContent');
/**
 * # 🚀 BlueBaseApp
 *
 * The main BlueBase app. This is the top level component in BlueBase. Takes care
 * of initialisation, and renders either children, or app with routing.
 *
 * ## Usage
 * ```jsx
 * <BlueBaseApp BB={BB} />
 * ```
 */
exports.BlueBaseApp = (props) => {
    const { ErrorComponent = __1.BlueBaseAppError, LoadingComponent = __1.BlueBaseAppLoading, children, } = props;
    const [BB] = react_1.useState(props.BB || new BlueBase_1.BlueBase());
    const [bootCount, setBootCount] = react_1.useState(0);
    const [booting, setBooting] = react_1.useState(true);
    const [bootTrigger, setBootTrigger] = react_1.useState(true); // Setting to true to start boot
    const [progress, setProgress] = react_1.useState({});
    const { onError } = useExceptionHandlingOnProduction_1.useExceptionHandlingOnProduction(BB);
    async function boot(reset) {
        if (!booting) {
            setBooting(true);
        }
        await BB.boot(Object.assign({}, props, { reset, onProgress: (p) => {
                setProgress(p);
                if (p.error) {
                    setBooting(false);
                }
            } }));
        setBootCount(bootCount + 1);
        setBooting(false);
    }
    BB.reboot = async (reset = false) => {
        boot(reset);
    };
    react_1.useEffect(() => {
        if (bootTrigger) {
            setBootTrigger(false);
        }
        else {
            return;
        }
        boot();
    }, [bootTrigger]);
    if (booting) {
        return react_1.default.createElement(LoadingComponent, { BB: BB, progress: progress, bootCount: bootCount });
    }
    if (progress.error) {
        return react_1.default.createElement(ErrorComponent, { BB: BB, progress: progress, bootCount: bootCount });
    }
    return (react_1.default.createElement(Context_1.BlueBaseProvider, { key: `boot-${bootCount}`, value: BB },
        react_1.default.createElement(components_1.ErrorObserver, { errorComponent: ErrorComponent, BB: BB, progress: progress, bootCount: bootCount, onError: onError },
            react_1.default.createElement(themes_1.ThemeProvider, null,
                react_1.default.createElement(intl_1.IntlProvider, null,
                    react_1.default.createElement(BlueBaseContent, null, children))))));
};
const defaultProps = {
    ErrorComponent: __1.BlueBaseAppError,
    LoadingComponent: __1.BlueBaseAppLoading,
};
exports.BlueBaseApp.defaultProps = defaultProps;
exports.BlueBaseApp.displayName = 'BlueBaseApp';
