"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@bluebase/core");
exports.ListIconDefaultProps = {
    size: 24,
};
/**
 * 📚 ListIcon
 *
 * A component to show tiles inside a List.
 */
exports.ListIcon = core_1.getComponent('ListIcon');
exports.ListIcon.defaultProps = exports.ListIconDefaultProps;
