"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IPAddress = {
    key: 'IPAddress',
    dataType: 'STRING',
    /**
     * Returns value as it is
     *
     * Example: '192.168.0.1' => '192.168.0.1'
     */
    template: '{{ value }}',
    validate: (value, _schema) => {
        if (
        // tslint:disable-next-line: max-line-length
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(value)) {
            return true;
        }
        return false;
    },
};
