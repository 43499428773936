Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _jsxFileName = 'src/components/NotificationFeed.js';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _reactNative = require('react-native');

var _Context = require('../Context');

var _styles = require('../styles');

var _NewActivitiesNotification = require('./NewActivitiesNotification');

var _NewActivitiesNotification2 = _interopRequireDefault(_NewActivitiesNotification);

var _utils = require('../utils');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var NotificationFeed = function (_React$Component) {
  _inherits(NotificationFeed, _React$Component);

  function NotificationFeed() {
    _classCallCheck(this, NotificationFeed);

    return _possibleConstructorReturn(this, (NotificationFeed.__proto__ || Object.getPrototypeOf(NotificationFeed)).apply(this, arguments));
  }

  _createClass(NotificationFeed, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      return React.createElement(
        _Context.Feed,
        {
          feedGroup: this.props.feedGroup,
          userId: this.props.userId,
          options: makeDefaultOptions(this.props.options),
          notify: this.props.notify,
          doFeedRequest: this.props.doFeedRequest,
          doReactionAddRequest: this.props.doReactionAddRequest,
          doReactionDeleteRequest: this.props.doReactionDeleteRequest,
          doChildReactionAddRequest: this.props.doChildReactionAddRequest,
          doChildReactionDeleteRequest: this.props.doChildReactionDeleteRequest,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 73
          }
        },
        React.createElement(
          _Context.FeedContext.Consumer,
          {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 84
            }
          },
          function (feedCtx) {
            return React.createElement(NotificationFeedInner, _extends({}, _this2.props, feedCtx, {
              __source: {
                fileName: _jsxFileName,
                lineNumber: 85
              }
            }));
          }
        )
      );
    }
  }]);

  return NotificationFeed;
}(React.Component);

NotificationFeed.defaultProps = {
  feedGroup: 'notification',
  styles: {},
  Notifier: _NewActivitiesNotification2.default
};
exports.default = NotificationFeed;


var makeDefaultOptions = function makeDefaultOptions(options) {
  var copy = _extends({}, options);
  if (copy.mark_seen === undefined) {
    copy.mark_seen = true;
  }
  return copy;
};

var NotificationFeedInner = function (_React$Component2) {
  _inherits(NotificationFeedInner, _React$Component2);

  function NotificationFeedInner() {
    var _ref;

    var _temp, _this3, _ret;

    _classCallCheck(this, NotificationFeedInner);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this3 = _possibleConstructorReturn(this, (_ref = NotificationFeedInner.__proto__ || Object.getPrototypeOf(NotificationFeedInner)).call.apply(_ref, [this].concat(args))), _this3), _initialiseProps.call(_this3), _temp), _possibleConstructorReturn(_this3, _ret);
  }

  _createClass(NotificationFeedInner, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      return regeneratorRuntime.async(function componentDidMount$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return regeneratorRuntime.awrap(this._refresh());

            case 2:
            case 'end':
              return _context.stop();
          }
        }
      }, null, this);
    }
  }, {
    key: 'render',
    value: function render() {
      var _this4 = this;

      var styles = (0, _styles.buildStylesheet)('notificationFeed', this.props.styles);
      var notifierProps = {
        adds: this.props.realtimeAdds,
        deletes: this.props.realtimeDeletes,
        onPress: this._refresh
      };
      return React.createElement(
        React.Fragment,
        {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 156
          }
        },
        (0, _utils.smartRender)(this.props.Notifier, notifierProps),
        React.createElement(_reactNative.FlatList, _extends({
          ListHeaderComponent: this.props.children,
          style: styles.container,
          refreshControl: React.createElement(_reactNative.RefreshControl, {
            refreshing: this.props.refreshing,
            onRefresh: this._refresh,
            __source: {
              fileName: _jsxFileName,
              lineNumber: 162
            }
          }),
          data: this.props.activityOrder.map(function (id) {
            return _this4.props.activities.get(id);
          }),
          keyExtractor: function keyExtractor(item) {
            return item.get('id');
          },
          renderItem: this._renderWrappedGroup,
          onEndReached: this.props.noPagination ? undefined : this.props.loadNextPage,
          ref: function (_ref2) {
            function ref(_x) {
              return _ref2.apply(this, arguments);
            }

            ref.toString = function () {
              return _ref2.toString();
            };

            return ref;
          }(function (ref) {
            _this4.props.setListRef === undefined ? null : _this4.props.setListRef(ref);

            _this4.listRef = ref;
          })
        }, this.props.flatListProps, {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 158
          }
        }))
      );
    }
  }]);

  return NotificationFeedInner;
}(React.Component);

var _initialiseProps = function _initialiseProps() {
  var _this6 = this;

  this._refresh = function _callee() {
    var ref;
    return regeneratorRuntime.async(function _callee$(_context2) {
      while (1) {
        switch (_context2.prev = _context2.next) {
          case 0:
            _context2.next = 2;
            return regeneratorRuntime.awrap(_this6.props.refresh(makeDefaultOptions(_this6.props.options)));

          case 2:
            ref = _this6.listRef;

            if (ref) {
              ref.scrollToOffset({ offset: 0 });
            }

          case 4:
          case 'end':
            return _context2.stop();
        }
      }
    }, null, _this6);
  };

  this._renderWrappedGroup = function (_ref3) {
    var item = _ref3.item;
    return React.createElement(ImmutableItemWrapper, {
      renderItem: _this6._renderGroup,
      item: item,
      navigation: _this6.props.navigation,
      feedGroup: _this6.props.feedGroup,
      userId: _this6.props.userId,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 115
      }
    });
  };

  this._childProps = function () {
    return {
      onRemoveActivity: _this6.props.onRemoveActivity,
      onToggleReaction: _this6.props.onToggleReaction,
      onAddReaction: _this6.props.onAddReaction,
      onRemoveReaction: _this6.props.onRemoveReaction,
      onToggleChildReaction: _this6.props.onToggleChildReaction,
      onAddChildReaction: _this6.props.onAddChildReaction,
      onRemoveChildReaction: _this6.props.onRemoveChildReaction,
      onMarkAsRead: _this6.props.onMarkAsRead,
      onMarkAsSeen: _this6.props.onMarkAsSeen,
      navigation: _this6.props.navigation,
      feedGroup: _this6.props.feedGroup,
      userId: _this6.props.userId
    };
  };

  this._renderGroup = function (item) {
    var args = _extends({
      activityGroup: item,
      styles: _this6.props.styles.activity
    }, _this6._childProps());
    return (0, _utils.smartRender)(_this6.props.Group, args);
  };
};

var ImmutableItemWrapper = function (_React$PureComponent) {
  _inherits(ImmutableItemWrapper, _React$PureComponent);

  function ImmutableItemWrapper() {
    _classCallCheck(this, ImmutableItemWrapper);

    return _possibleConstructorReturn(this, (ImmutableItemWrapper.__proto__ || Object.getPrototypeOf(ImmutableItemWrapper)).apply(this, arguments));
  }

  _createClass(ImmutableItemWrapper, [{
    key: 'render',
    value: function render() {
      return this.props.renderItem(this.props.item.toJS());
    }
  }]);

  return ImmutableItemWrapper;
}(React.PureComponent);