"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.screens = {
    ChangePasswordScreen: Promise.resolve().then(() => __importStar(require('./ChangePasswordScreen'))),
    LoginScreen: Promise.resolve().then(() => __importStar(require('./LoginScreen'))),
    LogoutScreen: Promise.resolve().then(() => __importStar(require('./LogoutScreen'))),
    ResetPasswordCodeScreen: Promise.resolve().then(() => __importStar(require('./ResetPasswordCodeScreen'))),
    ResetPasswordScreen: Promise.resolve().then(() => __importStar(require('./ResetPasswordScreen'))),
    SignupScreen: Promise.resolve().then(() => __importStar(require('./SignupScreen'))),
    VerifyEmailScreen: Promise.resolve().then(() => __importStar(require('./VerifyEmailScreen'))),
};
