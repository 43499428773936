"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@bluebase/core");
exports.ListItemDefaultProps = {
    disabled: false,
    inset: false,
    selected: false,
};
/**
 * 📚 ListItem
 *
 * A component to show tiles inside a List.
 */
exports.ListItem = core_1.getComponent('ListItem');
exports.ListItem.defaultProps = exports.ListItemDefaultProps;
