Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _jsxFileName = 'src/components/SinglePost.js';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _FlatFeed = require('./FlatFeed');

var _FlatFeed2 = _interopRequireDefault(_FlatFeed);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var SinglePost = function (_React$Component) {
  _inherits(SinglePost, _React$Component);

  function SinglePost() {
    _classCallCheck(this, SinglePost);

    return _possibleConstructorReturn(this, (SinglePost.__proto__ || Object.getPrototypeOf(SinglePost)).apply(this, arguments));
  }

  _createClass(SinglePost, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      return _react2.default.createElement(
        _react2.default.Fragment,
        {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 54
          }
        },
        _react2.default.createElement(_FlatFeed2.default, {
          feedGroup: this.props.feedGroup,
          userId: this.props.userId,
          options: _extends({
            withRecentReactions: true
          }, this.props.options),
          Activity: this.props.Activity,
          styles: this.props.styles,
          navigation: this.props.navigation,
          doFeedRequest: function doFeedRequest(client, feedGroup, userId, options) {
            return client.feed(feedGroup, userId).getActivityDetail(_this2.props.activity.id, options);
          },
          doReactionAddRequest: this.props.doReactionAddRequest,
          doReactionDeleteRequest: this.props.doReactionDeleteRequest,
          doChildReactionAddRequest: this.props.doChildReactionAddRequest,
          doChildReactionDeleteRequest: this.props.doChildReactionDeleteRequest,
          doReactionsFilterRequest: this.props.doReactionsFilterRequest,
          Footer: this.props.Footer,
          noPagination: true,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 55
          }
        })
      );
    }
  }]);

  return SinglePost;
}(_react2.default.Component);

exports.default = SinglePost;