"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * A list of helperTexts shown when certain names are entered in the name
 * fields. This is intended to be used as a fun easter egg in the app.
 *
 * Please keep this humourous, non-offensive and non-political.
 * This list will be ONLY be updated strictly after approval of the CEO.
 * Violating these policies will cost you your job.
 *
 * Upon every entry, add reasoning and description of the person.
 *
 */
exports.names = {
    /**
     * Wife of BlueEast's founder & CEO
     */
    'Afrah Abdul Rehman': '❤️️️️️❤️❤️',
    /**
     * Former leader of MQM.
     * Ok, this is a little offensive and political. Probably the only exception
     * we're going to make here.
     */
    'Altaf Hussain': '🤫🔫⚰',
    /**
     * Leader of PTI, and cricket legend
     */
    'Imran Khan': '🏏Aye Aye, Captain!',
    /**
     * Nobel laureate
     */
    'Malala Yousafzai': '🎓 One child, one teacher, one book, one pen can change the world!',
    /**
     * Leader of PML-N
     */
    'Nawaz Sharif': '🐅 Shaaiiirrrr',
    /**
     * Daughter of BlueEast's founder & CEO
     */
    'Zaynab Abdul Rehman': '👸',
};
