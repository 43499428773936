"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const createPalette_1 = require("../helpers/createPalette");
const createTheme_1 = require("../helpers/createTheme");
const createTypography_1 = require("../helpers/createTypography");
const palette = createPalette_1.createPalette('light');
const typography = createTypography_1.createTypography(palette);
const theme = createTheme_1.createTheme(palette, typography);
exports.BlueBaseLightTheme = {
    alternate: 'bluebase-dark',
    key: 'bluebase-light',
    mode: 'light',
    name: 'BlueBase Light',
    value: theme,
};
