"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("../constants");
exports.getScreenSizeFromWidth = (width) => {
    if (width < constants_1.BREAKPOINTS.xs) {
        return 'xs';
    }
    else if (width < constants_1.BREAKPOINTS.sm) {
        return 'sm';
    }
    else if (width < constants_1.BREAKPOINTS.md) {
        return 'md';
    }
    else if (width < constants_1.BREAKPOINTS.lg) {
        return 'lg';
    }
    return 'xl';
};
