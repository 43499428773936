"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@bluebase/core");
exports.TableTitleDefaultProps = {
    direction: 'dsc',
};
// tslint:disable: jsdoc-format
/**
 * # 🏓 TableTitle
 *
 * Data tables allow displaying sets of data.
 *
 */
exports.TableTitle = core_1.getComponent('TableTitle');
exports.TableTitle.defaultProps = exports.TableTitleDefaultProps;
