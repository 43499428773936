"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const react_1 = tslib_1.__importStar(require("react"));
const core_1 = require("@bluebase/core");
exports.LogoutScreen = () => {
    const BB = core_1.useBlueBase();
    const { navigate } = core_1.useNavigation();
    react_1.useEffect(() => {
        (async () => {
            BB.reboot(true);
            // We navigate to Login, otherwise browser gets stuck in an infinite loop
            navigate('Login');
        })();
    }, []);
    return (react_1.default.createElement(components_1.WaitObserver, null,
        react_1.default.createElement(components_1.LoadingState, null)));
};
