"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@bluebase/core");
exports.CardActionsDefaultProps = {};
// tslint:disable: jsdoc-format
/**
 * # 🃏 CardActions
 *
 * A component to show a list of actions inside a Card.
 *
 * ## Usage
 ```jsx
import * as React from 'react';
import { Button, Card } from '@bluebase/components';

const MyComponent = () => (
  <Card>
    <Card.Actions>
      <Button>Cancel</Button>
      <Button>Ok</Button>
    </Card.Actions>
  </Card>
);

export default MyComponent;
```
 */
exports.CardActions = core_1.getComponent('CardActions');
exports.CardActions.defaultProps = exports.CardActionsDefaultProps;
