Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/LikeButton.js';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _styles = require('../styles');

var _ReactionToggleIcon = require('./ReactionToggleIcon');

var _ReactionToggleIcon2 = _interopRequireDefault(_ReactionToggleIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var LikeButton = function (_React$Component) {
  _inherits(LikeButton, _React$Component);

  function LikeButton() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, LikeButton);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = LikeButton.__proto__ || Object.getPrototypeOf(LikeButton)).call.apply(_ref, [this].concat(args))), _this), _this._onPress = function () {
      var _this$props = _this.props,
          activity = _this$props.activity,
          reaction = _this$props.reaction,
          reactionKind = _this$props.reactionKind,
          onToggleReaction = _this$props.onToggleReaction,
          onToggleChildReaction = _this$props.onToggleChildReaction;


      if (reaction && onToggleChildReaction) {
        return onToggleChildReaction(reactionKind, reaction, {}, {});
      }
      return onToggleReaction(reactionKind, activity, {}, {});
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(LikeButton, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          activity = _props.activity,
          reaction = _props.reaction,
          reactionKind = _props.reactionKind;

      var styles = (0, _styles.buildStylesheet)('likeButton', this.props.styles);
      var counts = void 0,
          own_reactions = void 0;
      if (reaction && this.props.onToggleChildReaction) {
        counts = reaction.children_counts;
        own_reactions = reaction.own_children;
      } else {
        counts = activity.reaction_counts;
        own_reactions = activity.own_reactions;
      }

      return React.createElement(_ReactionToggleIcon2.default, {
        styles: styles,
        counts: counts,
        own_reactions: own_reactions,
        kind: reactionKind,
        onPress: this._onPress,
        activeIcon: require('../images/icons/heart.png'),
        inactiveIcon: require('../images/icons/heart-outline.png'),
        labelSingle: 'like',
        labelPlural: 'likes',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 68
        }
      });
    }
  }]);

  return LikeButton;
}(React.Component);

LikeButton.defaultProps = {
  reactionKind: 'like'
};
exports.default = LikeButton;