"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@bluebase/core");
exports.DialogTitleDefaultProps = {};
// tslint:disable: jsdoc-format
/**
 * # 🔲 DialogTitle
 *
 * A component to show a title in a Dialog.
 *
 * ## Usage
 ```jsx
import * as React from 'react';
import { Body1, Dialog } from '@bluebase/components';

export default class MyComponent extends React.Component {
  state = {
    visible: false,
  };

  _hideDialog = () => this.setState({ visible: false });

  render() {
    return (
            <Dialog
                visible={this.state.visible}
                onDismiss={this._hideDialog}>
                <Dialog.Title>This is a title</Dialog.Title>
                <Dialog.Content>
                    <Body1>This is simple dialog</Body1>
                </Dialog.Content>
            </Dialog>
    );
  }
}

export default MyComponent;
```
 */
exports.DialogTitle = core_1.getComponent('DialogTitle');
exports.DialogTitle.defaultProps = exports.DialogTitleDefaultProps;
