Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/CommentItem.js';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactNative = require('react-native');

var _utils = require('../utils');

var _Avatar = require('./Avatar');

var _Avatar2 = _interopRequireDefault(_Avatar);

var _styles = require('../styles');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var CommentItem = function (_React$Component) {
  _inherits(CommentItem, _React$Component);

  function CommentItem() {
    _classCallCheck(this, CommentItem);

    return _possibleConstructorReturn(this, (CommentItem.__proto__ || Object.getPrototypeOf(CommentItem)).apply(this, arguments));
  }

  _createClass(CommentItem, [{
    key: 'componentDidCatch',
    value: function componentDidCatch(error, info) {
      if (this.props.componentDidCatch) {
        this.props.componentDidCatch(error, info, this.props);
      } else {
        console.error(error);
        console.error('The following comment caused the previous error');
        console.error(this.props.comment);
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var comment = this.props.comment;

      var styles = (0, _styles.buildStylesheet)('commentItem', this.props.styles || {});
      return _react2.default.createElement(
        _reactNative.View,
        { style: styles.container, __source: {
            fileName: _jsxFileName,
            lineNumber: 42
          }
        },
        _react2.default.createElement(_Avatar2.default, { source: comment.user.data.profileImage, size: 25, noShadow: true, __source: {
            fileName: _jsxFileName,
            lineNumber: 43
          }
        }),
        _react2.default.createElement(
          _reactNative.View,
          { style: styles.commentText, __source: {
              fileName: _jsxFileName,
              lineNumber: 44
            }
          },
          _react2.default.createElement(
            _reactNative.Text,
            {
              __source: {
                fileName: _jsxFileName,
                lineNumber: 45
              }
            },
            _react2.default.createElement(
              _reactNative.Text,
              { style: styles.commentAuthor, __source: {
                  fileName: _jsxFileName,
                  lineNumber: 46
                }
              },
              comment.user.data.name,
              ' '
            ),
            _react2.default.createElement(
              _reactNative.Text,
              { style: styles.commentContent, __source: {
                  fileName: _jsxFileName,
                  lineNumber: 47
                }
              },
              comment.data.text,
              ' '
            ),
            _react2.default.createElement(
              _reactNative.Text,
              { style: styles.commentTime, __source: {
                  fileName: _jsxFileName,
                  lineNumber: 48
                }
              },
              (0, _utils.humanizeTimestamp)(comment.created_at)
            )
          )
        ),
        (0, _utils.smartRender)(this.props.Footer)
      );
    }
  }]);

  return CommentItem;
}(_react2.default.Component);

exports.default = CommentItem;