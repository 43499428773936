"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Boolean_1 = require("./Boolean");
const Connection_1 = require("./Connection");
const Currency_1 = require("./Currency");
const Date_1 = require("./Date");
const IPAddress_1 = require("./IPAddress");
const Integer_1 = require("./Integer");
const IntegerPercent_1 = require("./IntegerPercent");
const Iso8601Date_1 = require("./Iso8601Date");
const JsonObject_1 = require("./JsonObject");
const Number_1 = require("./Number");
const Percent_1 = require("./Percent");
const PositiveInteger_1 = require("./PositiveInteger");
const String_1 = require("./String");
const SwitchState_1 = require("./SwitchState");
const TemperatureValue_1 = require("./TemperatureValue");
const ThermostatSetpointValue_1 = require("./ThermostatSetpointValue");
const Url_1 = require("./Url");
const presets = {
    Boolean: Boolean_1.Boolean,
    Connection: Connection_1.Connection,
    Currency: Currency_1.Currency,
    Date: Date_1.Date,
    IPAddress: IPAddress_1.IPAddress,
    Integer: Integer_1.Integer,
    IntegerPercent: IntegerPercent_1.IntegerPercent,
    Iso8601Date: Iso8601Date_1.Iso8601Date,
    JsonObject: JsonObject_1.JsonObject,
    Number: Number_1.Number,
    Percent: Percent_1.Percent,
    PositiveInteger: PositiveInteger_1.PositiveInteger,
    String: String_1.String,
    SwitchState: SwitchState_1.SwitchState,
    TemperatureValue: TemperatureValue_1.TemperatureValue,
    ThermostatSetpointValue: ThermostatSetpointValue_1.ThermostatSetpointValue,
    Url: Url_1.Url,
};
exports.default = presets;
