"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.styles = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _withStyles = _interopRequireDefault(require("../styles/withStyles"));

var _ButtonBase = _interopRequireDefault(require("../ButtonBase"));

// @inheritedComponent ButtonBase
var styles = function styles(theme) {
  return {
    /* Styles applied to the root element. */
    root: {
      display: 'block',
      textAlign: 'inherit',
      width: '100%',
      '&:hover $focusHighlight': {
        opacity: theme.palette.action.hoverOpacity
      },
      '&$focusVisible $focusHighlight': {
        opacity: 0.12
      }
    },

    /* Styles applied to the ButtonBase root element if the action area is keyboard focused. */
    focusVisible: {},

    /* Styles applied to the overlay that covers the action area when it is keyboard focused. */
    focusHighlight: {
      pointerEvents: 'none',
      position: 'absolute',
      backgroundColor: 'currentcolor',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      opacity: 0,
      transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.short
      })
    }
  };
};

exports.styles = styles;

function CardActionArea(props) {
  var children = props.children,
      classes = props.classes,
      className = props.className,
      focusVisibleClassName = props.focusVisibleClassName,
      other = (0, _objectWithoutProperties2.default)(props, ["children", "classes", "className", "focusVisibleClassName"]);
  return _react.default.createElement(_ButtonBase.default, (0, _extends2.default)({
    className: (0, _classnames.default)(classes.root, className),
    focusVisibleClassName: (0, _classnames.default)(focusVisibleClassName, classes.focusVisible)
  }, other), children, _react.default.createElement("span", {
    className: classes.focusHighlight
  }));
}

process.env.NODE_ENV !== "production" ? CardActionArea.propTypes = {
  /**
   * The content of the component.
   */
  children: _propTypes.default.node,

  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css-api) below for more details.
   */
  classes: _propTypes.default.object.isRequired,

  /**
   * @ignore
   */
  className: _propTypes.default.string,

  /**
   * @ignore
   */
  focusVisibleClassName: _propTypes.default.string
} : void 0;

var _default = (0, _withStyles.default)(styles, {
  name: 'MuiCardActionArea'
})(CardActionArea);

exports.default = _default;