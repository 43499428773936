"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PositiveInteger = {
    key: 'PositiveInteger',
    dataType: 'NUMBER',
    /**
     * Returns value as it is
     *
     * Example: 50 => 50
     */
    template: '{{ value }}',
    validate: (value, _schema) => {
        if (value < 0) {
            return false;
        }
        return true;
    },
    precision: 0,
};
