"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const react_1 = tslib_1.__importStar(require("react"));
const Context_1 = require("./Context");
/**
 * Resolves a component from BlueBase context, and returns it.
 * It is possible to pass multiple keys as backup. So if one component
 * is not found, the function will fallback and try to find next component.
 *
 * @param keys
 */
function getComponent(...keys) {
    if (keys.length === 0) {
        throw Error('getComponent method needs at least one key');
    }
    const displayName = keys.join('_');
    let Component;
    const BlueBaseComponent = (props) => {
        const BB = react_1.useContext(Context_1.BlueBaseContext);
        // If there is no BlueBase context, throw an Error
        if (!BB) {
            throw Error(`Could not resolve component "${displayName}" in "getComponent" command. Reason: BlueBase context not found.`);
        }
        // We don't want to resolve the component on every render.
        // If we don't do this, a new component is created on every
        // render, causing various set of problems.
        if (!Component) {
            Component = BB.Components.resolve(...keys);
        }
        return react_1.default.createElement(Component, props);
    };
    BlueBaseComponent.displayName = displayName;
    return BlueBaseComponent;
}
exports.getComponent = getComponent;
// System Components
exports.BlueBaseContent = getComponent('BlueBaseContent');
exports.BlueBaseImage = getComponent('BlueBaseImage');
exports.BlueBaseImageBackground = getComponent('BlueBaseImageBackground');
exports.BlueBaseFilter = getComponent('BlueBaseFilter');
exports.ComponentState = getComponent('ComponentState');
exports.DataObserver = getComponent('DataObserver');
exports.DynamicIcon = getComponent('DynamicIcon');
exports.EmptyState = getComponent('EmptyState');
exports.ErrorObserver = getComponent('ErrorObserver');
exports.ErrorState = getComponent('ErrorState');
exports.FormattedMessage = getComponent('FormattedMessage');
exports.HoverObserver = getComponent('HoverObserver');
exports.Link = getComponent('Link');
exports.LoadingState = getComponent('LoadingState');
exports.Noop = getComponent('Noop');
exports.PluginIcon = getComponent('PluginIcon');
exports.Navigation = getComponent('Navigation');
exports.NavigationActions = getComponent('NavigationActions');
exports.Redirect = getComponent('Redirect');
exports.StatefulComponent = getComponent('StatefulComponent');
exports.TouchableItem = getComponent('TouchableItem');
exports.WaitObserver = getComponent('WaitObserver');
// Native
exports.ActivityIndicator = getComponent('ActivityIndicator');
exports.Button = getComponent('Button');
exports.FlatList = getComponent('FlatList');
exports.Image = getComponent('Image');
exports.Text = getComponent('Text');
exports.View = getComponent('View');
exports.ImageBackground = getComponent('ImageBackground');
// Typography
exports.H1 = getComponent('H1');
exports.H2 = getComponent('H2');
exports.H3 = getComponent('H3');
exports.H4 = getComponent('H4');
exports.H5 = getComponent('H5');
exports.H6 = getComponent('H6');
exports.Subtitle1 = getComponent('Subtitle1');
exports.Subtitle2 = getComponent('Subtitle2');
exports.Body1 = getComponent('Body1');
exports.Body2 = getComponent('Body2');
exports.Caption = getComponent('Caption');
exports.Overline = getComponent('Overline');
