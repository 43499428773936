"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
exports.FontWeight = {
    light: '300',
    regular: '400',
    medium: '500',
};
const caseAllCaps = {
    textTransform: 'uppercase',
};
function createTypography(palette) {
    const buildVariant = (fontWeight, size, _lineHeight, _letterSpacing, casing = {}) => (Object.assign({ color: palette.text.primary, fontFamily: react_native_1.Platform.OS === 'web' ? '"Roboto", "Helvetica", "Arial", sans-serif' : undefined, fontWeight, fontSize: size }, casing));
    return {
        h1: buildVariant(exports.FontWeight.light, 96, 1, -1.5),
        h2: buildVariant(exports.FontWeight.light, 60, 1, -0.5),
        h3: buildVariant(exports.FontWeight.regular, 48, 1.04, 0),
        h4: buildVariant(exports.FontWeight.regular, 34, 1.17, 0.25),
        h5: buildVariant(exports.FontWeight.regular, 24, 1.33, 0),
        h6: buildVariant(exports.FontWeight.medium, 20, 1.6, 0.15),
        subtitle1: buildVariant(exports.FontWeight.regular, 16, 1.75, 0.15),
        subtitle2: buildVariant(exports.FontWeight.medium, 14, 1.57, 0.1),
        body1: buildVariant(exports.FontWeight.regular, 16, 1.5, 0.15),
        body2: buildVariant(exports.FontWeight.regular, 14, 1.5, 0.15),
        button: buildVariant(exports.FontWeight.medium, 14, 1.5, 0.4, caseAllCaps),
        caption: buildVariant(exports.FontWeight.regular, 12, 1.66, 0.4),
        overline: buildVariant(exports.FontWeight.regular, 12, 2.66, 1, caseAllCaps),
    };
}
exports.createTypography = createTypography;
