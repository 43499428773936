"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Adds QuickCharts on Things Profile Overview page
 */
exports.quickCharts = {
    'mevris.app.things.layout.thing-profile-overview.desktop.main': [
        {
            key: 'inject-reports-in-thing-profile-desktop',
            priority: 25,
            value: (schema) => (Object.assign(Object.assign({}, schema), { children: [
                    ...schema.children,
                    {
                        component: 'ThingProfileQuickCharts',
                    },
                ] })),
        },
    ],
};
