"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@bluebase/core");
/**
 * # 🛸 HoverObserver
 *
 * A React component that notifies its children of hover interactions.
 *
 * Initial code taken from: https://github.com/ethanselzer/react-hover-observer.
 *
 * ## Usage
 * ```jsx
 * <HoverObserver>
 *  {({ isHovering }) => (
 * 		 <YourChildComponent isActive={isHovering} />
 *  )}
 * </HoverObserver>
 * ```
 */
exports.HoverObserver = core_1.getComponent('HoverObserver');
