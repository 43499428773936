"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const BaseNavigator_1 = require("../BaseNavigator");
const core_1 = require("@bluebase/core");
const ScreenView = core_1.getComponent('ScreenView');
/**
 * Provides a way for your app to transition between screens where each new
 * screen is placed on top of a stack.
 */
class StackNavigator extends BaseNavigator_1.BaseNavigator {
}
StackNavigator.defaultProps = {
    RouteView: ScreenView
};
exports.StackNavigator = StackNavigator;
