"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const React = tslib_1.__importStar(require("react"));
const components_1 = require("@bluebase/components");
const react_native_1 = require("react-native");
exports.Support = (props) => {
    const styles = props.styles;
    const width = react_native_1.Dimensions.get('window').width;
    return (React.createElement(components_1.ScrollView, { style: { width } },
        React.createElement(components_1.Card, null,
            React.createElement(components_1.Container, { style: styles.container },
                React.createElement(components_1.View, { style: styles.pageContainer },
                    React.createElement(components_1.View, { style: styles.rowStyles },
                        React.createElement(components_1.H5, { style: styles.headerStyles }, "Contact Us / Support")),
                    React.createElement(components_1.Body1, { style: styles.p1 }, "We are here to provide you with more information, answer any questions you may have and create an effective solution for your instructional needs."),
                    React.createElement(components_1.Body1, { style: styles.p1 }, "You can contact our Customer Support at:"),
                    React.createElement(components_1.Link, null,
                        React.createElement(components_1.Body1, { style: { color: '#0275d8' } },
                            React.createElement(components_1.Body1, { style: { color: '#0275d8' } }, "(+92) 042-111-258-378"))),
                    React.createElement(components_1.Body1, { style: styles.p1 }, "Email:"),
                    React.createElement(components_1.Link, null,
                        React.createElement(components_1.Body1, { style: { color: '#0275d8' } }, "humans@blueeast.com")),
                    React.createElement(components_1.Body1, { style: styles.container }, "Monday-Friday 9:00 AM - 6:00 PM PKT"),
                    React.createElement(components_1.Body1, { style: styles.container }, "Monday-Friday 9:00 AM - 6:00 PM PKT"))))));
};
exports.Support.defaultStyles = (_theme) => ({
    closeButton: {
        alignSelf: 'center',
        display: 'flex',
        maxWidth: 0,
    },
    container: {
        padding: 10,
    },
    h2: {
        paddingBottom: 10,
        paddingTop: 11,
    },
    p1: {
        paddingTop: 20,
    },
    headerStyles: {
        display: 'flex',
        flex: 1,
    },
    pageContainer: {
        marginBottom: 50,
    },
    rowStyles: {
        display: 'flex',
        flexDirection: 'row',
    },
});
