"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@bluebase/core");
/**
 * # 🚨 ErrorState
 *
 * Display an error message. Used by UIs when an exception is caught, and an error message
 * needs to be displayed. If in development mode, the actual error is displayed, otherwise
 * displays a generic message in production mode.
 *
 * ## Usage
 * ```jsx
 * <ErrorState retry={retryCallback} error={Error('Bang!')} />
 * ```
 */
exports.ErrorState = core_1.getComponent('ErrorState');
