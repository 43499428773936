"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const hooks_1 = require("../../hooks");
const react_1 = tslib_1.__importStar(require("react"));
exports.DeviceContext = react_1.createContext(undefined);
exports.DeviceProvider = (props) => {
    const { children, id } = props;
    const ctx = hooks_1.useDeviceBuilder(id);
    return react_1.default.createElement(exports.DeviceContext.Provider, { value: ctx }, children);
};
exports.DeviceProvider.defaultProps = {};
