"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const createPalette_1 = require("../helpers/createPalette");
const createTheme_1 = require("../helpers/createTheme");
const createTypography_1 = require("../helpers/createTypography");
const palette = createPalette_1.createPalette('dark');
const typography = createTypography_1.createTypography(palette);
const theme = createTheme_1.createTheme(palette, typography);
exports.BlueBaseDarkTheme = {
    alternate: 'bluebase-light',
    key: 'bluebase-dark',
    mode: 'dark',
    name: 'BlueBase Dark',
    value: theme,
};
