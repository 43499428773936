"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@bluebase/core");
// tslint:disable: jsdoc-format
/**
 * # 🗿 DynamicIcon
 *
 * An enhanced Icon that can render any of the following:
 * - BB.Components.Icon
 * - BB.Components.Image
 * - A custom component
 *
 * ## Usage
```jsx
<DynamicIcon
    type="image"
    size={250}
    source={{ uri: 'https://picsum.photos/200' }}
/>
```
 */
exports.DynamicIcon = core_1.getComponent('DynamicIcon');
