"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.screens = {
    ReportListScreen: Promise.resolve().then(() => __importStar(require('./ReportListScreen'))),
    ReportScreen: Promise.resolve().then(() => __importStar(require('./ReportScreen'))),
    ThingProfileReportsScreen: Promise.resolve().then(() => __importStar(require('./ThingProfileReportsScreen'))),
};
