"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function injectDividersInJsonNodes(items) {
    const result = [];
    if (!items) {
        return result;
    }
    items.forEach((node, index) => {
        result.push(node);
        if (index < items.length - 1) {
            result.push({ component: 'Divider', props: { key: `${index}-divider` } });
        }
    });
    return result;
}
exports.injectDividersInJsonNodes = injectDividersInJsonNodes;
