Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/UploadImage.js';

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactNative = require('react-native');

var _pickphoto = require('../images/icons/pickphoto.png');

var _pickphoto2 = _interopRequireDefault(_pickphoto);

var _styles = require('../styles');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var UploadImage = function UploadImage(_ref) {
  var onUploadButtonPress = _ref.onUploadButtonPress,
      props = _objectWithoutProperties(_ref, ['onUploadButtonPress']);

  var styles = (0, _styles.buildStylesheet)('uploadImage', props.styles || {});

  return _react2.default.createElement(
    _reactNative.View,
    { style: styles.container, __source: {
        fileName: _jsxFileName,
        lineNumber: 17
      }
    },
    _react2.default.createElement(
      _reactNative.TouchableOpacity,
      { onPress: onUploadButtonPress, __source: {
          fileName: _jsxFileName,
          lineNumber: 18
        }
      },
      _react2.default.createElement(_reactNative.Image, { source: _pickphoto2.default, style: styles.image, __source: {
          fileName: _jsxFileName,
          lineNumber: 19
        }
      })
    )
  );
};

exports.default = UploadImage;