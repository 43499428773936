"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.settings = {
    ProfileAvatarSetting: Promise.resolve().then(() => __importStar(require('./AvatarSetting'))),
    ProfileNameSettings: Promise.resolve().then(() => __importStar(require('./ProfileNameSettings'))),
    ProfilePasswordSettings: Promise.resolve().then(() => __importStar(require('./ProfilePasswordSettings'))),
};
